import React, { useContext, useEffect, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button } from '@kajabi/sage-react';

import { SaveThemeContext } from '../EditorWatcher';

const SAVE = 'Save';
const SAVING = 'Saving';

export default function SaveButton() {
  const {
    ui: { savingTheme },
  } = useSelector(state => state, shallowEqual);

  const { saveDisabled, saveTheme } = useContext(SaveThemeContext);

  const disableSave = useMemo(() => savingTheme || saveDisabled, [
    saveDisabled,
    savingTheme,
  ]);

  useEffect(() => {
    const beforeunload = (e) => {
      if (!saveDisabled) {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    window.addEventListener('beforeunload', beforeunload);

    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };
  }, [saveDisabled]);

  return (
    <Button disabled={disableSave} onClick={saveTheme} small>
      {savingTheme ? SAVING : SAVE}
    </Button>
  );
}
