import { Node, mergeAttributes } from '@tiptap/core';

/**
 * ListItemContent extension
 *
 * Prosemirror has a known limitation that prevents marks and nodes from existing as siblings in
 * the document hierarchy. Because of this limitation Tiptap defaults to wrapping the contents of
 * a list item (<li>) with a paragraph (<p>). A node within an <li> is necessary to allow the
 * addition of further nested lists which are also nodes. Thi necessary handling by Tiptap causes
 * in issue within Kajabi themes because paragraph tags often have a bottom margin and are
 * block-level elements in HTML. This causes unexpected rendering. We remedy this by creating a
 * Tiptap/Prosemirror extension which is considered a node internally but its output is
 * rendered as a <span> tag in place of the <p> tag. This alleviates the style inconsistency.
 *
 */

export default Node.create({
  name: 'listItemContent',
  group: 'block',
  content: 'inline*',

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (element) => {
          if (element.parentNode.tagName === 'LI') {
            return null;
          }
          return false;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});
