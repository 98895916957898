import React, { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import useContentKey from '../../../../helpers/useContentKey';
import useEncore from '../../../../helpers/useEncore';
import { addSection } from '../../../../redux/theme/actions';
import { selectFile } from '../../../../redux/file/selectors';

import useSelectPreset from './hooks/useSelectPreset';
import { PresetButton } from './PresetButton';

export const AddSectionPanel = () => {
  const contentKey = useContentKey();
  const { search } = useSelector(state => state.ui);
  const isEncore = useEncore();

  const [
    selectedPreset,
    formattedPreset,
    setSelectedPreset,
  ] = useSelectPreset();

  const {
    attributes: { categories },
  } = useSelector(state => selectFile(state), shallowEqual);

  const filteredCategories = useMemo(
    () =>
      categories
        .map(({ name, presets }) => ({
          name,
          presets: presets
            .filter(p => !!p.name.toLowerCase().match(search.toLowerCase()))
            .map(p => ({ ...p, id: String(Date.now()) })),
        }))
        .filter(p => p.presets.length),
    [search, categories]
  );

  const dispatch = useDispatch();

  const onAddPreset = useCallback(() => {
    dispatch(
      addSection({
        contentKey,
        id: formattedPreset.id,
        item: formattedPreset,
      })
    );
  }, [contentKey, dispatch, formattedPreset]);

  return (
    <div className="form-section">
      <div className={`presets ${isEncore ? 'encore-theme' : ''}`}>
        {filteredCategories.length ? (
          filteredCategories.map(({ name, presets }, index) =>
            (isEncore ? (
              <div className="presets-container" key={name}>
                <div className="presets-heading">
                  <span>{name}</span>
                </div>
                {presets.map(preset => (
                  <PresetButton
                    handleAdd={onAddPreset}
                    handleClick={setSelectedPreset}
                    isEncore={isEncore}
                    key={`${name}-${preset.name}`}
                    preset={preset}
                    selected={preset === selectedPreset}
                  />
                ))}
              </div>
            ) : (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={`${name}-${index}`}>
                {presets.map(preset => (
                  <PresetButton
                    handleAdd={onAddPreset}
                    handleClick={setSelectedPreset}
                    isEncore={isEncore}
                    key={`${name}-${preset.name}`}
                    preset={preset}
                    selected={preset === selectedPreset}
                  />
                ))}
              </React.Fragment>
            ))
          )
        ) : (
          <div className="no-results-container">No Results</div>
        )}
      </div>
    </div>
  );
};
