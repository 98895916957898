import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FilePicker from 'common/components/FilePicker';

import {
  Input,
  Modal,
  Button,
  SageTokens,
} from '@kajabi/sage-react';

import {
  useSettingsContext,
  useTiptapEditorContext,
} from 'common/components/ContentEditor/contexts';

const DialogImage = ({
  closeFn
}) => {
  const { uploaderConfig } = useSettingsContext();
  const { editor } = useTiptapEditorContext();
  const {
    src: initialSrcValue,
    title: initialTitleValue,
    alt: initialAltValue,
    ...restInitialValues
  } = editor.getAttributes('image');

  const [srcValue, setSrcValue] = useState(initialSrcValue);
  const [titleValue, setTitleValue] = useState(initialTitleValue);
  const [altValue, setAltValue] = useState(initialAltValue);

  const deleteFn = () => editor.commands.deleteSelection() && closeFn();
  const createFn = () => editor.commands.setImage({
    src: srcValue,
    title: titleValue,
    alt: altValue,
    ...restInitialValues,
  }) && closeFn();

  const renderDeleteButton = () => {
    if (!initialSrcValue) return false;

    return (
      <Button
        color={Button.COLORS.DANGER}
        subtle={true}
        onClick={deleteFn}
      >
        Delete image
      </Button>
    );
  };

  const renderSaveButton = () => {
    const text = initialSrcValue ? 'Update image' : 'Add image';
    const isDisabled = !srcValue;

    return (
      <Button
        color={Button.COLORS.PRIMARY}
        raised={false}
        onClick={createFn}
        disabled={isDisabled}
      >
        {text}
      </Button>
    );
  };

  const filepickerOnChange = (value) => {
    const newSrcValue = value ? `${uploaderConfig.s3Url}/${value}` : null;
    setSrcValue(newSrcValue);
  };

  return (
    <Modal active={true} onExit={closeFn}>
      <Modal.Header
        title="Image"
        aside={(
          <Button
            color={Button.COLORS.SECONDARY}
            iconOnly={true}
            icon={SageTokens.ICONS.REMOVE}
            subtle={true}
            onClick={closeFn}
          >
            Close image modal
          </Button>
        )}
      />
      <Modal.Body>
        <FilePicker
          inputName="contentEditorImage"
          value={srcValue}
          config={uploaderConfig}
          onChange={filepickerOnChange}
        />
        <hr />
        <Input
          label="Title"
          value={titleValue}
          onChange={({ target }) => setTitleValue(target.value)}
        />
        <Input
          label="Description"
          value={altValue}
          onChange={({ target }) => setAltValue(target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterAside>
          {renderDeleteButton()}
        </Modal.FooterAside>
        <Button
          color={Button.COLORS.SECONDARY}
          onClick={closeFn}
        >
          Cancel
        </Button>
        {renderSaveButton()}
      </Modal.Footer>
    </Modal>
  );
};

DialogImage.propTypes = {
  closeFn: PropTypes.func.isRequired
};

export default DialogImage;
