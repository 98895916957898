import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  SageTokens,
} from '@kajabi/sage-react';
import { EXTENSION_NAMES } from 'common/components/ContentEditor/extensions';

const ToolSubscript = ({ editor }) => (
  <Button
    iconOnly={true}
    color={Button.COLORS.SECONDARY}
    icon={SageTokens.ICONS.SUBSCRIPT}
    onClick={() => editor
      .chain()
      .focus()
      .extendMarkRange(EXTENSION_NAMES.SUBSCRIPT)
      .toggleSubscript()
      .run()}
    disabled={!editor.can().toggleSubscript()}
    className={editor.isActive(EXTENSION_NAMES.SUBSCRIPT) && 'sage-btn--active'}
  >
      Subscript
  </Button>
);

ToolSubscript.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolSubscript;
