import { Node, nodeInputRule, nodePasteRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import LiquidTagView from './LiquidTagView';

const EXTENSION_NAME = 'liquidTag';
const INPUT_REGEX = /(?:^|\s)((?:{{ )((?:[^{}]+))(?: }}))$/;
const PASTE_REGEX = /(?:^|\s)((?:{{ )((?:[^{}]+))(?: }}))/g;

export default Node.create({
  name: EXTENSION_NAME,
  group: 'inline',
  inline: true,
  atom: true,


  addInputRules() {
    return [nodeInputRule({
      find: INPUT_REGEX,
      type: this.type,
      getAttributes: match => ({ handle: match[2] }),
    })];
  },

  addPasteRules() {
    return [nodePasteRule({
      find: PASTE_REGEX,
      type: this.type,
      getAttributes: match => ({ handle: match[2] }),
    })];
  },

  addCommands() {
    return ({
      insertLiquidTag: (extensionAttributes = {}) => ({ chain }) => (
        chain()
          .focus()
          .insertContent({
            type: EXTENSION_NAME,
            attrs: {
              handle: extensionAttributes.handle
            }
          })
          .insertContent(' ') // Insert space following node
          .run()
      ),
      deleteVariableDataField: () => ({ chain }) => (
        chain()
          .focus()
          .deleteSelection()
          .run()
      )
    });
  },

  addAttributes() {
    return ({
      handle: {
        default: null,
        parseHTML: element => (
          element
            .innerText
            .replace(/[{}]/g, '')
            .trim()
        ),
      },
    });
  },

  parseHTML() {
    return [{ tag: '[variable-data-field]' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      { 'variable-data-field': '' },
      `{{ ${HTMLAttributes.handle} }}`,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(LiquidTagView);
  }
});
