import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  SageTokens,
} from '@kajabi/sage-react';
import { EXTENSION_NAMES } from 'common/components/ContentEditor/extensions';

const ToolBlockquote = ({ editor }) => (
  <Button
    iconOnly={true}
    color={Button.COLORS.SECONDARY}
    icon={SageTokens.ICONS.QUOTE}
    onClick={() => editor
      .chain()
      .focus()
      .toggleBlockquote()
      .run()}
    disabled={!editor.can().toggleBlockquote()}
    className={editor.isActive(EXTENSION_NAMES.BLOCKQUOTE) && 'sage-btn--active'}
  >
      Blockquote
  </Button>
);

ToolBlockquote.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolBlockquote;
