import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

const EVENT_KEY = '0';

function CustomToggle({ eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey);

  return (
    <div
      className="btn btn-primary btn-link btn-sm"
      onClick={decoratedOnClick}
      onKeyUp={decoratedOnClick}
      role="button"
      tabIndex="0"
    >
      View Details
    </div>
  );
}

CustomToggle.propTypes = {
  eventKey: PropTypes.string.isRequired,
};

export function StatDetails({ children, title, value }) {
  return (
    <Accordion>
      <div className="list-group-item accordion">
        <div className="accordion-group">
          <div className="media">
            <div className="media-body media-middle">
              <h5 className="title">{title}</h5>
              <h1 className="value">{value}</h1>
            </div>
            <div className="media-right media-middle">
              <CustomToggle eventKey={EVENT_KEY} />
            </div>
          </div>
          <Accordion.Collapse eventKey={EVENT_KEY}>
            <div className="stat-details">{children}</div>
          </Accordion.Collapse>
        </div>
      </div>
    </Accordion>
  );
}

StatDetails.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
