import React from 'react';
import { Alert, SageTokens } from '@kajabi/sage-react';
import ErrorBoundary from 'common/components/ErrorBoundary';

const withErrorBoundary = Component => props => (
  <ErrorBoundary
    fallbackRender={() => (
      <Alert
        title="Content editor error"
        description="Refresh the page to try again."
        icon={SageTokens.ICONS.WARNING}
      />
    )}
  >
    <Component {...props} />
  </ErrorBoundary>
);

export default withErrorBoundary;
