import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import moment from 'moment';

import LabelTooltip from './LabelTooltip';


const DateTime = ({ element, name, value, onChange }) => {
  const dateFormat = 'yyyy-MM-DD hh:mm A';

  return (
    <div className="theme-input">
      <LabelTooltip element={element} name={name} />
      <DatePicker
        dateFormat="yyyy-MM-dd hh:mm a"
        onChange={date => onChange(moment(date).format(dateFormat))}
        selected={new Date(value)}
        showTimeSelect
        timeFormat="hh:mm a"
        timeIntervals={5}
        timeCaption="time"
      />

      // eslint-disable-next-line react/no-danger
      <div className="date-time-html" dangerouslySetInnerHTML={{ __html: element.info_html }} />
    </div>
  );
};

DateTime.propTypes = {
  element: PropTypes.exact({
    id: PropTypes.string.isRequired,
    info_html: PropTypes.string,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['date_time']).isRequired
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default DateTime;
