import React from 'react';
import PropTypes from 'prop-types';

import noop from 'lodash/noop';

import { DynamicSelect } from './DynamicSelect';

export const Form = ({
  element,
  name,
  onChange,
  value,
  theme: {
    attributes: { siteId },
  },
}) => (
  <DynamicSelect
    classNamePrefix="list"
    element={element}
    helpUrl={`/admin/sites/${siteId}/forms`}
    name={name}
    onChange={onChange}
    placeholder="Select your form..."
    resourceName="form"
    siteId={siteId}
    value={value}
  />
);

Form.defaultProps = {
  onChange: noop,
  value: '',
};

Form.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  theme: PropTypes.shape({
    attributes: PropTypes.shape({
      siteId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
