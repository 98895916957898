import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const PATHS = [
  'add_section$',
  'add_block$',
  'blocks/',
  'global/',
  'sections/'
];

export function useNavigate() {
  const [navigateTo, setNavigateTo] = useState('');
  const history = useHistory();

  const goToPath = useCallback(
    (path) => {
      history.push(path);
    },
    [history]
  );

  const lookup = PATHS.join('|');
  const regexPattern = new RegExp(`/(${lookup})(?!.*/(${lookup}))`);

  useEffect(() => {
    const path = history.location.pathname;

    const match = path.match(regexPattern);

    const idx = match ? match.index : path.length;
    setNavigateTo(path.substring(0, idx));
  }, [history.location.pathname, regexPattern]);

  return { goToPath, navigateTo };
}
