import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  SageTokens,
} from '@kajabi/sage-react';
import {
  useUiContext,
} from 'common/components/ContentEditor/contexts';
import { DIALOG_IMAGE } from 'common/components/ContentEditor/components/Dialog';
import { EXTENSION_NAMES } from 'common/components/ContentEditor/extensions';

const ToolImage = ({ editor }) => {
  const { setDialog } = useUiContext();

  return (
    <Button
      iconOnly={true}
      color={Button.COLORS.SECONDARY}
      icon={SageTokens.ICONS.ADD_IMAGE}
      onClick={() => setDialog(DIALOG_IMAGE)}
      disabled={!editor.can().setImage()}
      className={editor.isActive(EXTENSION_NAMES.IMAGE) && 'sage-btn--active'}
    >
      Image
    </Button>
  );
};

ToolImage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolImage;
