// App flash messages

/* global App */
export const flashSuccess = props => App.flashSuccess(props);
export const flashNotice = props => App.flashNotice(props);
export const flashLoading = props => App.flash({ ...props, type: 'loading' });
export const flashError = props => App.flashError(props);

export const flashDismiss = id => App.flashDismiss(id);
export const flashReset = () => App.flashReset();
