import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import ScriptTag from 'react-script-tag';

import { ModalLoading } from '../ModalLoading';

import { EventsList } from './EventsList';
import { StatPanel } from './StatPanel';

const DEFAULT_PARAMS = {
  include: ['stats', 'events'],
};

const WISTIA_CONFIG = "doNotTrack=true uuid='wistia_video_embed' dontFit=true plea='romulus' videoFoam=true playButton=false controlsVisible=false fullscreenButton=false playbar=false smallPlayButton=false volume=0 volumeControl=false preview=true autoPlay=false settingsControl=false shareControl=false captionsControl=false videoQuality='low-fps'";

export default function VideoStatsModal({ onHide, show, videoId }) {
  const [page, setPage] = useState(1);
  const [video, setVideo] = useState(null);

  useEffect(() => {
    if (show) {
      const params = { ...DEFAULT_PARAMS, page };
      axios.get(`/admin/videos/${videoId}`, { params }).then(
        ({
          data: {
            data: { attributes: videoData },
          },
        }) => {
          if (page > 1) {
            setVideo(oldState => ({
              ...oldState,
              events: oldState.events.concat(videoData.events),
            }));
          } else {
            setVideo(videoData);
          }
        }
      );
    } else {
      setVideo(null);
      setPage(1);
    }
  }, [page, show, videoId]);

  return (
    <Modal
      className="modal modal-secondary modal-dynamic modal-spinner modal-large fade in"
      onHide={onHide}
      show={show}
    >
      {video ? (
        <>
          <div className="modal-header">
            <h3 className="modal-title">Stats</h3>
          </div>
          <div className="media-stats">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-5">
                  <StatPanel video={video} />
                </div>
                <div className="col-lg-7 kjb-audio-wrapper">
                  <div className="kjb-video-responsive">
                    <ScriptTag
                      async
                      src="//fast.wistia.com/assets/external/E-v1.js"
                    />
                    <div
                      className={`wistia_embed wistia_async_${video.wistiaId} ${WISTIA_CONFIG}`}
                      style={{ height: 349, width: 620 }}
                    />
                  </div>
                </div>
              </div>
              <EventsList events={video.events} />
              <button
                className="btn btn-default btn-outline"
                onClick={() => setPage(page + 1)}
                type="button"
              >
                Load More
              </button>
            </div>
          </div>
        </>
      ) : (
        <ModalLoading />
      )}
    </Modal>
  );
}

VideoStatsModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  videoId: PropTypes.string.isRequired,
};
