/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Tooltip } from '@kajabi/sage-react';
import noop from 'lodash/noop';

import { useUndoRedo } from '../../../../helpers/useUndoRedo';

import UndoRedoIcon from './images/undo_redo.svg';

export default function RedoUndoButtons() {
  const { handleRedo, handleUndo, redoDisabled, undoDisabled } = useUndoRedo();

  return (
    <span className="redo-undo">
      <Tooltip
        content={undoDisabled ? 'Nothing to undo' : 'Undo'}
        position={Tooltip.POSITIONS.BOTTOM}
      >
        <img
          alt=""
          className={undoDisabled ? 'disabled' : ''}
          onClick={undoDisabled ? noop : handleUndo}
          onKeyDown={undoDisabled ? noop : handleUndo}
          src={UndoRedoIcon}
        />
      </Tooltip>
      <Tooltip
        content={redoDisabled ? 'Nothing to redo' : 'Redo'}
        position={Tooltip.POSITIONS.BOTTOM}
      >
        <img
          alt=""
          className={redoDisabled ? 'disabled' : ''}
          onClick={redoDisabled ? noop : handleRedo}
          onKeyDown={redoDisabled ? noop : handleRedo}
          src={UndoRedoIcon}
        />
      </Tooltip>
    </span>
  );
}
/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
