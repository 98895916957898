import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ActionDownloadButton } from './ActionDownloadButton';
import { ActionInput } from './ActionInput';
import { ActionRemoteSelect } from './ActionRemoteSelect';
import { ActionSectionAnchor } from './ActionSectionAnchor';

export const ActionComponentLoader = ({
  actionType,
  filepickerOptions,
  isExternal,
  onChange,
  siteId,
  value
}) => {
  const ActionComponent = useMemo(() => {
    switch (actionType) {
      case 'anchor':
        return ActionSectionAnchor;

      case 'downloads':
        return ActionDownloadButton;

      case 'landing_pages':
      case 'offers':
      case 'sales_pages':
        return ActionRemoteSelect;

      case 'url':
        return ActionInput;

      default:
        return () => null;
    }
  }, [actionType]);

  return (
    React.createElement(ActionComponent, {
      actionType,
      config: filepickerOptions,
      isExternal,
      onChange,
      siteId,
      value,
    })
  );
};

ActionComponentLoader.defaultProps = {
  filepickerOptions: {},
  isExternal: false,
};

ActionComponentLoader.propTypes = {
  actionType: PropTypes.string.isRequired,
  filepickerOptions: PropTypes.shape({}),
  isExternal: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};
