import { useCallback } from 'react';

const DEFAULT_OPTIONS = {
  branding: false,
  cache_suffix: '?v=4.5.7',
  browser_spellcheck: true,
  height: 300,
  valid_elements: '#*[*]',
  plugins: [
    'code',
    'filepicker_image',
    'textcolor',
    'fullscreen',
    'colorpicker',
    'link',
    'autolink',
    'media',
    'hr',
    'paste',
    'lists',
  ],
  textcolor_rows: '7',
  textcolor_map: [
    '000000', 'Black',
    '444444', 'Charcoal',
    '666666', 'Dim Grey',
    '999999', 'Grey',
    'cccccc', 'Very Light Grey',
    'eeeeee', 'White Smoke',
    'f3f3f3', 'White Smoke Light',
    'ffffff', 'White',

    'f4cccc', 'Coral Candy',
    'fce5cd', 'Serenade',
    'fff2cc', 'Blanced Almond',
    'd9ead3', 'Peppermind',
    'd0e0e3', 'Iceberg',
    'cfe2f3', 'Anahau',
    'd9d2e9', 'Blue Chalk',
    'ead1dc', 'Vanilla Ice',

    'ea9999', 'Tonys Pink',
    'f9cb9c', 'Apricot',
    'ffe599', 'Cream Brule',
    'b6d7a8', 'Moss Green',
    'a2c4c9', 'Shadow Green',
    '9fc5e8', 'Sail',
    'b4a7d6', 'Biloba Flower',
    'd5a6bd', 'Melanie',

    'e06666', 'Froly',
    'f6b26b', 'Tacao',
    'ffd966', 'Dandelion',
    '93c47d', 'Olivine',
    '76a5af', 'Neptune',
    '6fa8dc', 'Jordy Blue',
    '8e7cc3', 'Moody Blue',
    'c27ba0', 'Puce',

    'cc0000', 'Speech Red',
    'e69138', 'California',
    'f1c232', 'Saffron',
    '6aa84f', 'Apple',
    '45818e', 'Jelly bean',
    '3d85c6', 'Curious Blue',
    '674ea7', 'Studio',
    'a64d79', 'Royal Heath',

    '990000', 'Sangria',
    'b45f06', 'Tawny',
    'bf9000', 'Dark Goldenrod',
    '38761d', 'Bilbao',
    '134f5c', 'Blue Stone',
    '0b5394', 'Dark Cerulean',
    '351c75', 'Midnight Blue',
    '741b47', 'Pompadour',

    '660000', 'Maroon',
    '783f04', 'Saddle Brown',
    '7f6000', 'Olive',
    '274e13', 'Verdun Green',
    '0c343d', 'Cyprus',
    '073763', 'Sapphire',
    '20124d', 'Midnight Blue',
    '4c1130', 'Pohutukawa',
  ],
  paste_as_text: false,
  menubar: false,
  convert_urls: false,
  toolbar:
    'code | styleselect | bold italic strikethrough forecolor | hr bullist numlist outdent indent | link | fullscreen',
};
export default function useTinyMce(fields) {
  const addCustomFields = useCallback(
    (editorManager) => {
      editorManager.PluginManager.add('customFieldsPlugin', (editor) => {
        editor.addButton('customFieldsCombo', {
          type: 'listbox',
          text: 'Personalize',
          icon: false,
          onselect() {
            editor.insertContent(this.value());
            this.value('');
          },
          values: JSON.parse(JSON.stringify(fields)),
        });
      });
    },
    [fields]
  );

  const addAlignmentSplit = (editorManager) => {
    editorManager.PluginManager.add('alignmentsplit', (editor) => {
      editor.addButton('alignmentsplit', {
        type: 'listbox',
        text: '',
        tooltip: 'Alignment',
        icon: 'alignleft',
        menu: [
          {
            value: 'alignleft',
            icon: 'alignleft',
            text: 'Align Left',
            onclick() {
              editorManager.execCommand('JustifyLeft');
            },
          },
          {
            value: 'alignright',
            icon: 'alignright',
            text: 'Align Right',
            onclick() {
              editorManager.execCommand('JustifyRight');
            },
          },
          {
            value: 'aligncenter',
            icon: 'aligncenter',
            text: 'Align Center',
            onclick() {
              editorManager.execCommand('JustifyCenter');
            },
          },
          {
            value: 'alignjustify',
            icon: 'alignjustify',
            text: 'Justify',
            onclick() {
              editorManager.execCommand('JustifyFull');
            },
          },
        ],
        onselect() {
          this.value('');
        },
      });
    });
  };

  const addFilePicker = (editorManager) => {
    editorManager.PluginManager.add('filepicker_file', (editor) => {
      editor.addButton('filepicker_file', {
        icon: 'custom material-icons attach_file',
        tooltip: 'Upload file',
        onclick: editor.settings.selectFile,
      });
    });
  };

  return {
    addAlignmentSplit,
    addCustomFields,
    addFilePicker,
    defaultOptions: DEFAULT_OPTIONS,
  };
}
