import React, { useRef, useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import clone from 'lodash/clone';
import { Button } from '@kajabi/sage-react';
import { flashError, flashNotice, flashLoading, flashDismiss } from 'common/components/FlashMessages';
import { postRecentFile } from '../../services';

const FilepickerUploadButton = ({ config, onUploadSuccess, buttonColor }) => {
  const mutableConfig = useMemo(() => {
    // Allows filepicker.js to make changes to services array
    const services = config.services && clone(config.services);
    return {
      ...config,
      services
    };
  }, [config]);

  const uploadRef = useRef(new window.App.FilepickerUpload(mutableConfig)).current;
  const pick = uploadRef.pick.bind(uploadRef);
  const store = uploadRef.store.bind(uploadRef);
  const convert = uploadRef.convert.bind(uploadRef);
  const initialButtonTxt = config.isLink ? config.linkText : config.buttonText;
  const [buttonTxt, setButtonTxt] = useState(initialButtonTxt);
  const [isUploading, setIsUploading] = useState(false);

  const toastId = useRef(null);

  const setToast = (newToast) => {
    toastId.current = newToast;
  };

  const dismissToast = () => {
    flashDismiss(toastId.current);
  };

  const showLoadingMessage = (message, response) => {
    setToast(flashLoading({ text: message }));
    return response;
  };

  const handleUploadError = (err) => {
    setButtonTxt(initialButtonTxt);
    setIsUploading(false);

    // Don't display generic JS errors
    if (err && !err.code) {
      dismissToast();
      setToast(flashError({ text: 'Filepicker Error: Please try again' }));
    }

    if (err.code && err.code !== 101) {
      dismissToast();
      setToast(flashError({ text: `Filepicker Error ${err.message}` }));
    }

    // The user closed v3 filepicker before upload completed
    if (err.message === 'Upload Canceled') {
      dismissToast();
      setToast(flashNotice({ text: err.message }));
    }
  };

  const logRecentFile = (response) => {
    if (!config.recentImagesPath) {
      return response;
    }

    postRecentFile(config, response);

    return response;
  };

  const updateButton = (text, uploading = true) => (response) => {
    setButtonTxt(text);
    setIsUploading(uploading);

    return response;
  };

  const startUploading = () => {
    updateButton('Updating...')();
  };

  const handleClick = () => {
    Promise.resolve(startUploading())
      .then(pick)
      .then(response => showLoadingMessage('Uploading...', response))
      .then(updateButton('Storing...'))
      .then(store)
      .then(updateButton('Converting...'))
      .then(convert)
      .then(logRecentFile)
      .then(onUploadSuccess)
      .then(updateButton(initialButtonTxt, false))
      .then(() => {
        dismissToast();
      })
      .catch(handleUploadError);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={isUploading}
        color={buttonColor}
        className={config.buttonClass}
      >
        {buttonTxt}
      </Button>
    </>
  );
};

FilepickerUploadButton.defaultProps = {
  onUploadSuccess: () => {},
  buttonColor: Button.COLORS.PRIMARY
};

FilepickerUploadButton.propTypes = {
  config: PropTypes.shape({}).isRequired,
  onUploadSuccess: PropTypes.func,
  buttonColor: PropTypes.oneOf(Object.values(Button.COLORS)),
};

export default memo(FilepickerUploadButton);
