import React, { createContext, useReducer, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import store from './store';
import * as consts from './constants';

const initialState = {
  messages: []
};

const messageReducer = (state, action) => {
  switch (action.type) {
    case consts.ADD_MESSAGE: {
      return {
        ...state,
        messages: [...state.messages, action.message]
      };
    }
    case consts.REMOVE_MESSAGE: {
      return {
        ...state,
        messages: state.messages.filter(({ id }) => id !== action.id)
      };
    }
    case consts.REMOVE_ALL_MESSAGES: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export const FlashMessagesContext = createContext([]);

export const FlashMessageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(messageReducer, initialState);
  const value = useMemo(() =>
    ({ state, dispatch }),
  [state, dispatch]);

  useEffect(() => {
    if (!store.isReady) {
      store.isReady = true;
      store.dispatch = payload => dispatch(payload);
    }

    return () => {
      store.isReady = false;
    };
  }, []);

  return (
    <FlashMessagesContext.Provider value={value}>
      {children}
    </FlashMessagesContext.Provider>
  );
};

FlashMessageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
