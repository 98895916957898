import React, { useState } from 'react';
import PropTypes from 'prop-types';

import noop from 'lodash/noop';

export const NumberInput = ({
  name,
  onChange,
  placeholder,
  stepIncrement,
  value,
}) => {
  const [step, setStep] = useState(stepIncrement);

  const handleKeyDown = (event) => {
    if (event.shiftKey) {
      setStep(10);
    }
  };

  const handleKeyUp = (event) => {
    if (event.shiftKey === false) {
      setStep(0);
    }
  };

  return (
    <input
      className="form-control"
      name={name}
      onChange={e => onChange(e)}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      placeholder={placeholder}
      step={step}
      type="number"
      value={value}
    />
  );
};

NumberInput.defaultProps = {
  onChange: noop,
  placeholder: '0',
  stepIncrement: 0,
};

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  stepIncrement: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
