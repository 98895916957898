/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Label,
  SageTokens,
  SageClassnames,
} from '@kajabi/sage-react';

/**
 * SaveIndicator
 * UI component used to show clean, dirty, saving, and saved states in the editor.
 * When saved, the component updates 'Saved <timeago>' at an interval.
 *
 * @param {number} updatedAt - timestamp
 * @param {bool} isSaving
 * @param {bool} isDirty
 */

const UPDATED_AT_INTERVAL = 1000 * 20; // 20 seconds

const formatTimestamp = (time) => {
  moment.relativeTimeThreshold('ss', 10);
  return moment(time).fromNow();
};

const SaveIndicator = ({
  updatedAt,
  isSaving,
  isDirty,
}) => {
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    if (updatedAt) {
      setTextValue(formatTimestamp(updatedAt));

      const interval = setInterval(() => (
        setTextValue(formatTimestamp(updatedAt))
      ), UPDATED_AT_INTERVAL);

      return () => { clearInterval(interval); };
    }
    return () => {};
  }, [updatedAt]);

  if (isSaving) {
    return (
      <Label
        color={Label.COLORS.DRAFT}
        icon={SageTokens.ICONS.SYNC}
        value="Auto-saving"
      />
    );
  }

  if (isDirty) {
    return (
      <p className={`
        ${SageClassnames.TYPE.BODY_SMALL_MED}
        ${SageClassnames.TYPE_COLORS.GREY_500}
      `}
      >
        Unsaved&nbsp;changes
      </p>
    );
  }

  if (textValue) {
    return (
      <Label
        color={Label.COLORS.SUCCESS}
        icon={SageTokens.ICONS.CHECK_CIRCLE_FILLED}
        value={`Saved ${textValue}`}
      />
    );
  }

  return false;
};

SaveIndicator.defaultProps = {
  updatedAt: null,
  isSaving: false,
  isDirty: false,
};

SaveIndicator.propTypes = {
  updatedAt: PropTypes.number,
  isSaving: PropTypes.bool,
  isDirty: PropTypes.bool,
};

export default SaveIndicator;
