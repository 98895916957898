import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import noop from 'lodash/noop';

import LabelTooltip from './LabelTooltip';

export const FontSelect = ({ element, name, onChange, value }) => {
  const items = useMemo(
    () => element.options.map(({ id, label }) => ({ label, value: id })),
    [element.options]
  );

  const handleChange = (selectedOption) => {
    onChange(selectedOption.value);
  };

  /* eslint-disable-next-line react/prop-types */
  const formatOptionLabel = ({ label }) => (
    <span className="font-family-option" style={{ fontFamily: label }}>
      {label}
    </span>
  );

  return (
    <div className="theme-input">
      <LabelTooltip element={element} name={name} />
      <Select
        formatOptionLabel={formatOptionLabel}
        onChange={handleChange}
        options={items}
        value={items.find(i => i.value === value)}
      />
    </div>
  );
};

FontSelect.defaultProps = {
  onChange: noop,
  value: '',
};

FontSelect.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
};
