import React from 'react';
import PropTypes from 'prop-types';
import { SageClassnames } from '@kajabi/sage-react';

const RecentMediaCard = ({ media, selected, onClick }) => (
  <div>
    <img
      role="presentation"
      className="img-thumbnail"
      src={media.attributes.thumbnailPath}
      alt=""
      onClick={() => { onClick(media); }}
      style={{
        backgroundColor: (selected ? '#0072ef' : 'white'),
        cursor: 'pointer'
      }}
    />
    { media.attributes.description
    && <p className={SageClassnames.TYPE.BODY_SMALL}>{media.attributes.description}</p>}
  </div>
);

RecentMediaCard.defaultProps = {
  onClick: () => {},
  selected: false,
};

RecentMediaCard.propTypes = {
  media: PropTypes.shape({}).isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default RecentMediaCard;
