import React from 'react';

import { SageTokens, Button, Dropdown, SageClassnames } from '@kajabi/sage-react';
import Recommendation from './components/Recommendation';
import PreviewCard from './components/PreviewCard';
import { fileName } from '../../utils';

// eslint-disable-next-line react/prop-types
export const defaultUploadControls = ({ isUploading, buttonTxt, handleFileUpload }) => (
  <Button
    icon={SageTokens.ICONS.CARET_DOWN}
    color={Button.COLORS.PRIMARY}
    iconPosition={Button.ICON_POSITIONS.RIGHT}
    subtle={true}
    onClick={handleFileUpload}
    disabled={isUploading}
  >
    {buttonTxt}
  </Button>
);

// eslint-disable-next-line react/prop-types
export const defaultFilePreview = ({ value, previewUrl, recommendationText, ...rest }) => (
  <>
    <PreviewCard value={value} previewUrl={previewUrl} />
    <div className="sage-upload-card__body">
      {defaultUploadControls(rest)}
      <Recommendation>{recommendationText}</Recommendation>
    </div>
  </>
);

// eslint-disable-next-line react/prop-types
const editableUploadControls = ({ isUploading, buttonTxt, handleRemove, handleFileUpload }) => {
  const items = [
    {
      id: 'replace-file',
      label: 'Upload a new file',
      onClick: handleFileUpload,
    },
    {
      id: 'delete-file',
      label: 'Remove file',
      borderBefore: true,
      color: Dropdown.ITEM_COLORS.DANGER,
      onClick: handleRemove
    }
  ];

  return (
    <Dropdown
      align={Dropdown.POSITIONS.CENTER}
      disabled={isUploading}
      closePanelOnExit
      customTrigger={(
        <Button
          icon={SageTokens.ICONS.CARET_DOWN}
          color={Button.COLORS.PRIMARY}
          iconPosition={Button.ICON_POSITIONS.RIGHT}
          subtle={true}
        >
          {buttonTxt}
        </Button>
      )}
    >
      <Dropdown.ItemList items={items} />
    </Dropdown>
  );
};

// eslint-disable-next-line react/prop-types
export const editableFilePreview = ({ value, previewUrl, recommendationText, ...rest }) => {
  const previewName = fileName(previewUrl);

  return (
    <>
      <PreviewCard value={value} previewUrl={previewUrl} />
      <div className="sage-upload-card__body">
        {previewName && (
          <div className={SageClassnames.SPACERS.SM_BOTTOM}>{previewName}</div>
        )}
        {editableUploadControls(rest)}
        <Recommendation>{recommendationText}</Recommendation>
      </div>
    </>
  );
};
