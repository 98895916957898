function findToastMessage() {
  return document.getElementById('main-static-messages');
}

export function hideToastMessage() {
  const toast = findToastMessage();
  if (toast) {
    toast.querySelector('.media-body').textContent = '';
    toast.querySelector('.media').classList.remove('notifications-static');
    toast.classList.add('hide');
  }
}

export function showToastMessage(message) {
  const toast = findToastMessage();
  if (toast) {
    toast.querySelector('.media-body').textContent = message;
    toast.querySelector('.media').classList.add('notifications-static');
    toast.classList.remove('hide');
  }
}
