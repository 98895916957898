import React from 'react';

export function ModalLoading() {
  return (
    <div className="modal-loading">
      <div className="waiting-loader-container">
        <div className="waiting-loader-spinner">
          <div className="waiting-loader-circle">
            <i className="mi waiting-loader-icon">done</i>
          </div>
        </div>
      </div>
    </div>
  );
}
