import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

export function getDifference(object, base) {
  function changes(o, b) {
    return transform(o, (result, value, key) => {
      if (!isEqual(value, b[key])) {
        result[key] = isObject(value) && isObject(b[key]) ? changes(value, b[key]) : value;
      }
    });
  }
  return changes(object, base);
}
