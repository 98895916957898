/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NodeViewWrapper } from '@tiptap/react';

const ImageView = ({
  node: { attrs: {
    width,
    height,
    ...restAttributes
  } },
  selected,
  updateAttributes,
}) => {
  const [isResizeActive, setIsResizeActive] = useState(false);

  const handleMouseDown = useCallback((mouseDownEvent) => {
    const extensionEl = mouseDownEvent.target.parentNode;
    if (!extensionEl) return;
    setIsResizeActive(true);

    const startSize = { x: extensionEl.clientWidth, y: extensionEl.clientHeight };
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    const onMouseMove = (mouseMoveEvent) => {
      updateAttributes({
        width: startSize.x - startPosition.x + mouseMoveEvent.pageX,
        height: startSize.y - startPosition.y + mouseMoveEvent.pageY
      });
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      setIsResizeActive(false);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp, { once: true });
  }, [updateAttributes, setIsResizeActive]);

  return (
    <NodeViewWrapper
      className={classnames('kjb-content-editor-node-image', {
        'kjb-content-editor-node-image--selected': selected,
        'kjb-content-editor-node-image--resizing': isResizeActive
      })}
      style={{
        width,
        height
      }}
    >
      <img
        className="kjb-content-editor-node-image__img"
        {...restAttributes}
      />
      <button
        className="kjb-content-editor-node-image__resize-handler"
        onMouseDown={handleMouseDown}
        aria-grabbed={isResizeActive}
        type="button"
      />
    </NodeViewWrapper>
  );
};

ImageView.propTypes = {
  node: PropTypes.shape({
    attrs: PropTypes.shape({
      width: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string]).isRequired,
      height: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string]).isRequired,
    }).isRequired
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  updateAttributes: PropTypes.func.isRequired,
};

export default ImageView;
