import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import kebabCase from 'lodash/kebabCase';
import startsWith from 'lodash/startsWith';
import Select from 'react-select';

import { ActionComponentLoader } from './ActionComponents/ActionComponentLoader';
import LabelTooltip from './LabelTooltip';

const AllowableTypes = [
  { value: 'landing_pages', label: 'Go to a Landing Page' },
  { value: 'sales_pages', label: 'Go to a Sales Page' },
  { value: 'offers', label: 'Go to a Checkout Page' },
  { value: 'downloads', label: 'Download a file' },
  { value: 'url', label: 'Go to a URL' },
  { value: 'two_step', label: 'Open two step optin popup' },
  { value: 'next_pipeline_step', label: 'Go to the next Pipeline Step' },
  { value: 'anchor', label: 'Go to a section on this page' },
];

const getActionType = (redirectUrl) => {
  switch (true) {
    case redirectUrl === '#two-step':
      return 'two_step';
    case redirectUrl === '#next_pipeline_step':
      return 'next_pipeline_step';
    case startsWith(redirectUrl, '#section-') === true:
      return 'anchor';
    case (redirectUrl || []).includes('resource_redirect') === true:
      return redirectUrl.substr(redirectUrl.indexOf('resource_redirect')).split('/')[1];
    default:
      return 'url';
  }
};

export const Action = ({
  element,
  name,
  onChange,
  theme: {
    attributes: {
      ctaOptions,
      filepickerOptions,
      siteId
    }
  },
  value,
}) => {
  const kebabName = `${kebabCase(name)}-input`;

  const [selectedActionType, setSelectedActionType] = useState(getActionType(value));
  const isExternal = element.external;

  const handleChange = (e) => {
    setSelectedActionType(e.value);

    const changedValue = e.value === 'two_step' || e.value === 'next_pipeline_step'
      ? `#${kebabCase(e.value)}`
      : '';

    onChange(changedValue);
  };

  const filteredOptions = useMemo(
    () =>
      AllowableTypes.filter(
        ({ value: valueType }) =>
          ctaOptions.includes(valueType)
          || (valueType === 'anchor' && element.anchor)
          || (valueType === 'two_step' && element.two_step)
      ),
    [ctaOptions, element]
  );

  return (
    <div className="theme-input action-type">
      <LabelTooltip element={element} name={name} />
      <Select
        id={kebabName}
        options={filteredOptions}
        onChange={handleChange}
        value={filteredOptions.find(
          item => item.value === selectedActionType)}
      />

      {selectedActionType
        && (
          <>
            <br />
            <ActionComponentLoader
              actionType={selectedActionType}
              filepickerOptions={filepickerOptions}
              onChange={onChange}
              isExternal={isExternal}
              siteId={siteId}
              value={value}
            />
          </>
        )}
    </div>
  );
};

Action.defaultProps = {
  value: '',
};

Action.propTypes = {
  element: PropTypes.shape({
    anchor: PropTypes.bool,
    default: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    two_step: PropTypes.bool,
    type: PropTypes.oneOf(['action']).isRequired
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    attributes: PropTypes.shape({
      ctaOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
      filepickerOptions: PropTypes.shape({
        download: PropTypes.shape({}).isRequired,
      }),
      siteId: PropTypes.number.isRequired,
    }).isRequired
  }).isRequired,
  value: PropTypes.string,
};
