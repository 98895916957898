import store from './store';
import * as appProvider from './providers/appProvider';
import * as storeProvider from './providers/storeProvider';
import * as nullProvider from './providers/nullProvider';

/* global App */
const getProvider = () => {
  // It prefers App.flash implementation when App.flash is available. e.g. admin app
  if (typeof App !== 'undefined' && typeof App.flash !== 'undefined') {
    return appProvider;
  }

  // It uses React messages implementation when App.flash is not available. e.g. website
  // It requires `<FlashMessagesContainer />`
  if (store.isReady) {
    return storeProvider;
  }

  // It won't break when App.flash or React messages are not available
  return nullProvider;
};

export const flashSuccess = props => getProvider().flashSuccess(props);
export const flashNotice = props => getProvider().flashNotice(props);
export const flashLoading = props => getProvider().flashLoading(props);
export const flashError = props => getProvider().flashError(props);

export const flashDismiss = id => getProvider().flashDismiss(id);
export const flashReset = () => getProvider().flashReset();
