import React from 'react';
import PropTypes from 'prop-types';

export function Feedback({ url }) {
  return (
    <div className="list-group-item media">
      <div className="media-left media-middle">
        <i className="mi md-24 text-light si-comment" />
      </div>
      <div className="media-body media-middle">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="title"
        >
          Send Feedback
        </a>
      </div>
    </div>
  );
}

Feedback.propTypes = {
  url: PropTypes.string.isRequired,
};
