import React from 'react';
import PropTypes from 'prop-types';

import {
  UiProvider,
  TiptapEditorProvider,
  SettingsProvider,
} from './contexts';
import { EXTENSION_NAMES } from './extensions';
import { VALUE_TYPES } from './constants';

import ToolbarWrapper from './components/ToolbarWrapper';
import BubbleMenu from './components/BubbleMenu';
import Dialog from './components/Dialog';
import ToolMenu from './components/ToolMenu';
import TiptapEditor from './components/TiptapEditor';
import './style.scss';

/**
 * ContentEditor
 *
 * @param {string|object} value - Value of the editor
 * @param {string} valueType - Value data type (one of VALUE_TYPES),
 *                             this will be the type of the returned `onChange` payload
 * @param {function} onChange - onChange callback function
 * @param {array} extensionNames - Extension names (array of EXTENSIONS)
 * @param {string} label - Label of the input shown to user e.g. `Edit content`
 * @param {object} uploaderConfig - File uploader config for `EXTENSION_NAMES.IMAGE`
 * @param {[string]} colors - Optional CSS hex value presets for `EXTENSION_NAMES.COLOR`
 * @param {[{ handle: string, title: string }]} liquidTags - Optional liquid tag presets
 *                                                           for `EXTENSION_NAMES.LIQUID_TAGS`
 * @param {node|function} toolbarChildren - Contextual UI adjacent to `label`,
 *                                          e.g. save indicator or help dropdown
 */

const ContentEditor = ({
  value,
  valueType,
  onChange,
  extensionNames,
  toolbarChildren,
  ...rest
}) => (
  <SettingsProvider
    extensionNames={extensionNames}
    valueType={valueType}
    {...rest}
  >
    <UiProvider>
      <TiptapEditorProvider
        value={value}
        valueType={valueType}
        onChange={onChange}
        extensionNames={extensionNames}
      >
        <ToolbarWrapper toolbarChildren={toolbarChildren}>
          <div className="kjb-content-editor-field">
            <BubbleMenu />
            <Dialog />
            <ToolMenu />
            <TiptapEditor />
          </div>
        </ToolbarWrapper>
      </TiptapEditorProvider>
    </UiProvider>
  </SettingsProvider>
);

ContentEditor.defaultProps = {
  toolbarChildren: <React.Fragment />
};

ContentEditor.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  extensionNames: PropTypes.arrayOf(PropTypes.oneOf(Object.values(EXTENSION_NAMES))).isRequired,
  valueType: PropTypes.oneOf(Object.values(VALUE_TYPES)).isRequired,
  onChange: PropTypes.func.isRequired,
  toolbarChildren: PropTypes.node,
};

export default ContentEditor;
