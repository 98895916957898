import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import snakeCase from 'lodash/snakeCase';
import clientInfo from 'common/lib/clientInfo';

import useDebounce from '../../../helpers/useDebounce';

import * as Components from '../components/InputTypes';

const Types = {
  action: { Component: Components.Action },
  alert: { Component: Components.Alert },
  align: { Component: Components.Align },
  assessment: { Component: Components.Assessment },
  audio: { Component: Components.Audio },
  checkbox: { Component: Components.CheckBox, delay: 0 },
  color: { Component: Components.ColorPicker },
  date_time: { Compoment: Components.DateTime },
  divider: { Component: Components.Info },
  event: { Component: Components.Event },
  font_select: { Component: Components.FontSelect },
  form: { Component: Components.Form },
  grid: { Component: Components.Grid },
  header: { Component: Components.Header },
  image: { Component: Components.Image },
  image_picker: { Component: Components.Image },
  info: { Component: Components.Info },
  link_list: { Component: Components.LinkList },
  offer: { Component: Components.Offer },
  post: { Component: Components.Post },
  radio: { Component: Components.Radio, delay: 0 },
  range: { Component: Components.Range },
  rich_text: {
    ...(
      clientInfo.featureActive('new_theme_editor_rich_text_next')
        ? { Component: Components.RichTextNext, delay: 0 }
        : { Component: Components.RichText }
    )
  },
  sales_page: { Component: Components.SalesPage },
  select: { Component: Components.Select },
  spacer: { Component: Components.Spacer },
  text: { Component: Components.Text },
  textarea: { Component: Components.TextArea },
  url: { Component: Components.URL },
  video: { Component: Components.Video },
};

export const InputType = (props) => {
  const {
    blockId,
    sectionId,
    element,
    inputPrefix,
    settings,
    theme,
    type,
    onChange,
    paths,
  } = props;

  const id = element.id || '_';
  const name = `${inputPrefix}[${id}]`;
  const wrapperId = element.id ? snakeCase(name) : null;

  const handleChange = useCallback(
    (value) => {
      onChange(name, value);
    },
    [name, onChange]
  );

  const [debouncedValue, setDebouncedValue] = useDebounce({
    value: settings[id],
    delay: (Types[type] || {}).delay,
    callback: handleChange,
  });

  const newProps = Object.assign(
    {},
    {
      blockId,
      element,
      id,
      name,
      onChange: setDebouncedValue,
      paths,
      sectionId,
      settings,
      theme,
      type,
      value: debouncedValue,
    }
  );

  if (typeof Types[type] !== 'undefined') {
    const { Component } = Types[type];

    return (
      <div className="form-group" id={wrapperId}>
        <Component key={id} {...newProps} />
      </div>
    );
  }
  return React.createElement(
    () => (
      <div>{`The component '${type}' has not been created yet.`}</div>
    ),
    {}
  );
};

InputType.defaultProps = {
  blockId: undefined,
  type: undefined,
  element: {},
  inputPrefix: 'settings',
  onChange: null,
  sectionId: undefined,
};

InputType.propTypes = {
  blockId: PropTypes.string,
  sectionId: PropTypes.string,
  type: PropTypes.string,
  element: PropTypes.shape({}),
  inputPrefix: PropTypes.string,
  settings: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func,
  paths: PropTypes.shape({}).isRequired,
};
