import { useCallback } from 'react';

export function useIframe() {
  const sendMessage = (message) => {
    const iframe = document.querySelector('iframe#preview-window');
    if (iframe) {
      iframe.contentWindow.postMessage(message, '*');
    }
  };

  const sendAddHighlightSectionMessage = useCallback((id) => {
    sendMessage({
      type: 'highlightSection',
      id,
    });
  }, []);

  const sendAddSectionMessage = useCallback((section, sectionOrder) => {
    sendMessage({
      type: 'addSection',
      id: section.id,
      sectionType: section.type,
      overrides: { overrides: section },
      sectionOrder,
    });
  }, []);

  const sendEndHighlightMessage = useCallback(() => {
    sendMessage({
      type: 'endHighlight',
    });
  }, []);

  const sendReloadMessage = useCallback(() => {
    sendMessage({
      type: 'reload',
    });
  }, []);

  const sendReloadSectionMessage = useCallback((section) => {
    sendMessage({
      type: 'reloadSection',
      id: section.id,
      sectionType: section.type,
      overrides: { overrides: section },
    });
  }, []);

  const sendRemoveSectionMessage = useCallback((id) => {
    sendMessage({
      type: 'removeSection',
      id,
    });
  }, []);

  const sendReorderSectionMessage = useCallback((id, nextId) => {
    sendMessage({
      type: 'reorderSection',
      id,
      nextId,
    });
  }, []);

  const sendScrollToBlockMessage = useCallback((id) => {
    sendMessage({
      type: 'scrollToBlock',
      id,
    });
  }, []);

  const sendScrollToSectionMessage = useCallback((id) => {
    sendMessage({
      type: 'scrollToSection',
      id,
    });
  }, []);

  const sendToggleEdit = useCallback((edit) => {
    sendMessage({
      type: 'toggleEditing',
      edit,
    });
  }, []);

  return {
    sendAddHighlightSectionMessage,
    sendAddSectionMessage,
    sendEndHighlightMessage,
    sendReloadMessage,
    sendReloadSectionMessage,
    sendRemoveSectionMessage,
    sendReorderSectionMessage,
    sendScrollToBlockMessage,
    sendScrollToSectionMessage,
    sendToggleEdit,
  };
}
