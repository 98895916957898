import * as actionTypes from './actionTypes';
import * as themeActionTypes from '../theme/actionTypes';
import { FETCH_THEME, UPDATE_THEME } from '../api/constants';
import * as CONSTANTS from '../../constants';

import { VIEW_MODE_DEFAULT_KEY } from '../../components/ThemeEditorContainer/components/TopBar/ViewPortControl';

export const initialState = {
  disableIframePointerEvents: false,
  flashMessage: {},
  toastMessage: null,
  lastUpdatedAt: null,
  reordering: false,
  savingTheme: false,
  search: '',
  sideBar: {
    activeTab: CONSTANTS.DEFAULT_TAB_NAME,
    activeThemeFile: null,
  },
  thirdPartyCookiesEnabled: null,
  videos: [],
  viewPort: VIEW_MODE_DEFAULT_KEY,
};

const addVideo = (video, state) => ({
  ...state,
  videos: state.videos.concat(video),
});

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_VIDEO:
      return addVideo(action.payload, state);
    case themeActionTypes.ADD_AUDIO.ERROR:
      return {
        ...state,
        flashMessage: {
          error: 'Error saving uploaded file reference.',
        },
      };
    case FETCH_THEME.SUCCESS:
      return {
        ...state,
        lastUpdatedAt: action.data.theme.data.attributes.updatedAt,
      };
    case UPDATE_THEME.REQUEST:
      return {
        ...state,
        savingTheme: true,
      };
    case UPDATE_THEME.ERROR:
      return {
        ...state,
        flashMessage: {
          error: action.errors.join(', '),
        },
        savingTheme: false,
      };
    case UPDATE_THEME.SUCCESS:
      return {
        ...state,
        lastUpdatedAt: action.data.updated_at,
        savingTheme: false,
      };
    case actionTypes.SET_DISABLE_IFRAME_POINTER_EVENTS:
      return {
        ...state,
        disableIframePointerEvents: action.payload,
      };
    case actionTypes.SET_FLASH_MESSAGE:
      return {
        ...state,
        flashMessage: action.payload,
      };
    case actionTypes.SET_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: action.payload,
      };
    case actionTypes.SET_REORDERING:
      return {
        ...state,
        reordering: action.payload,
      };
    case actionTypes.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case actionTypes.SET_THIRD_PARTY_COOKIES_ENABLED:
      return {
        ...state,
        thirdPartyCookiesEnabled: action.payload,
      };
    case actionTypes.SET_VIEWPORT:
      return {
        ...state,
        viewPort: action.payload,
      };
    case actionTypes.UPDATE_SELECTED_SIDEBAR_TAB:
      return {
        ...state,
        sideBar: {
          ...state.sideBar,
          activeTab: action.payload,
        },
      };
    default:
      return state;
  }
};
