import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { StatDetails } from './StatDetails';

const formattedPercent = value => `${(value * 100).toFixed(0)}%`;

export function StatPanel({
  video: {
    createdAt,
    stats: { engagement, hoursWatched, playCount, playRate },
  },
}) {
  const formattedEngagment = useMemo(() => formattedPercent(engagement), [
    engagement,
  ]);

  return (
    <div className="panel panel-default">
      <div className="panel-body list-group stats">
        <StatDetails title="Average Engagement" value={formattedEngagment}>
          <>
            <p className="description">
              On average, people play <strong>{formattedEngagment} </strong> of
              the video.
            </p>
            <div className="calculation">
              <div className="media-left media-middle nowrap">
                {formattedEngagment} engaged =
              </div>
              <div className="media-body">
                <div className="numerator">
                  {Math.round(hoursWatched)} hours watched
                </div>
                <div className="denominator">total plays × video length</div>
              </div>
            </div>
          </>
        </StatDetails>
        <StatDetails title="Total Plays" value={playCount}>
          <p className="description">
            This video was uploaded {moment(createdAt).fromNow()}.
          </p>
        </StatDetails>
        <StatDetails title="Play Rate" value={formattedPercent(playRate)}>
          <p className="description">
            The percentage of time someone who saw the page containing the
            embedded video played the video.
          </p>
        </StatDetails>
      </div>
    </div>
  );
}

StatPanel.propTypes = {
  video: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    stats: PropTypes.shape({
      engagement: PropTypes.number.isRequired,
      hoursWatched: PropTypes.number.isRequired,
      playCount: PropTypes.number.isRequired,
      playRate: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
