import findIndex from 'lodash/findIndex';
import findKey from 'lodash/findKey';

// Keys are material icon ids, values are arrays of potential keywords to match
const icons = {
  dashboard: ['welcome screen navigation', 'navigation', 'navigation progress'],
  tab: ['tabs'],
  grade: ['action buttons'],
  header: ['header'],
  slideshow: ['slideshow', 'hero with video', 'multi-video launch', 'video gallery'],
  art_track: ['text and image', 'text & image', 'info', 'image and text'],
  hero_with_form: ['hero with form'],
  hero_with_countdown: ['hero countdown'],
  collections: ['hero with image', 'showcase', 'image and image'],
  '2_testimonials': ['2 testimonials'],
  '3_testimonials': ['3 testimonials'],
  format_quote: ['testimonial', 'testimonials', 'quote'],
  wb_iridescent: ['call to action', 'cta', 'button'],
  photo_library: ['gallery'],
  camera_alt: ['image'],
  link: ['link'],
  inline_opt_in_form: ['inline opt-in form'],
  text_and_form: ['form & text'],
  email: ['opt_in', 'form'],
  share: ['social'],
  text_format: ['heading'],
  vertical_align_center: ['spacer'],
  dropdown_menu: ['dropdown menu'],
  announcement: ['announcement'],
  user_menu: ['user dropdown'],
  menu: ['menu'],
  logo_list: ['logo list'],
  logo: ['logo', 'logo image'],
  copyright: ['copyright'],
  '2_column_text': ['2 column text', 'text and text'],
  '3_column_text': ['3 column text'],
  text_in_panel: ['text in panel'],
  video_embed: ['embeded video'],
  text_and_video: ['text & video'],
  videocam: ['video'],
  event_countdown: ['event countdown'],
  event: ['event'],
  pricing_card: ['pricing grid', 'pricing grid', 'pricing'],
  view_carousel: ['pricing option', 'feature', 'carousel'],
  invert_colors: ['color'],
  custom_code: ['custom code'],
  library_add: ['custom', 'advanced'],
  text_fields: ['typography'],
  hourglass_full: ['countdown', 'timer'],
  file_download: ['download'],
  pie_chart: ['progress'],
  rss_feed: ['blog'],
  person_pin: ['instructor', 'bio'],
  search: ['search'],
  layout_agenda: ['sidebar'],
  linear_scale: ['breadcrumb'],
  comment: ['comment'],
  format_list_numbered: ['syllabus'],
  check_circle: ['complete', 'completion message'],
  faq_grid: ['faq grid'],
  faq: ['faq'],
  list: ['accordion', 'outline'],
  assignment: ['assessment'],
  shop: ['offer storefront', 'sales'],
  offer: ['offer'],
  view_module: ['offers', 'store builder'],
  footer: ['footer'],
  '2_feature_cards': ['2 feature cards', 'hero with audio'],
  '3_feature_cards': ['3 feature cards'],
  '2_feature_columns': ['3 feature columns'],
  '4_feature_columns': ['4 feature columns'],
  format_align_left: ['text', 'announcements', 'categories body', 'welcome body', 'search body', 'body', 'navigation title', 'dashboard'],
  subtract_square: ['divider'],
  panorama: ['hero', 'marquee'],
  audiotrack: ['audio'],
};

export const themeIcon = (name) => {
  function matchName(keyword) {
    return name.toLowerCase().indexOf(keyword) !== -1;
  }

  function matchKey(list) {
    return findIndex(list, matchName) !== -1;
  }

  function toString() {
    const key = findKey(icons, matchKey);
    return key || 'settings';
  }

  return toString(name);
};
