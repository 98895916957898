import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setViewPort } from '../../../../redux/ui/actions';

import desktop from './images/md-desktop.svg';
import tabletH from './images/md-tablet-H.svg';
import tabletV from './images/md-tablet.svg';
import mobile from './images/md-mobile.svg';

export const VIEW_MODE_DEFAULT_KEY = 'desktop';
export const VIEW_MODES = {
  [VIEW_MODE_DEFAULT_KEY]: 'md-desktop',
  tabletH: 'md-tablet-H',
  tabletV: 'md-tablet',
  mobile: 'md-mobile',
};

const VIEW_ICONS = {
  desktop,
  tabletH,
  tabletV,
  mobile,
};

export default function ViewPortControl() {
  const dispatch = useDispatch();
  const { viewPort } = useSelector(state => state.ui);

  return (
    <div id="view-port">
      {Object.keys(VIEW_MODES).map(key => (
        <span
          className={`${viewPort === key ? 'active' : ''}`}
          key={`view-port-${key}`}
          onClick={() => dispatch(setViewPort(key))}
          onKeyDown={() => dispatch(setViewPort(key))}
          role="link"
          tabIndex={0}
        >
          <img
            src={VIEW_ICONS[key]}
            alt=""
            className={`mi ${VIEW_MODES[key]}`}
          />
        </span>
      ))}
    </div>
  );
}
