import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { truncate } from 'lodash';
import { NodeViewWrapper } from '@tiptap/react';

const PREVIEW_LENGTH = 40;

const CallToActionView = ({
  node: { attrs: {
    scriptContent,
    scriptAttributes: { src },
  } },
  selected,
}) => (
  <NodeViewWrapper
    as="div"
    className={
      classnames(
        'kjb-content-editor-node-script-tag',
        { 'kjb-content-editor-node-script-tag--selected': selected },
      )}
  >
    {scriptContent.length ? (
      <>
        {'<script>'}
        <br />
        &nbsp;&nbsp;{truncate(scriptContent, { length: PREVIEW_LENGTH })}
        <br />
        {'</script>'}
      </>
    ) : (
      `<script src="${truncate(src, { length: PREVIEW_LENGTH })}"></script>`
    )}
  </NodeViewWrapper>
);

CallToActionView.propTypes = {
  node: PropTypes.shape({
    attrs: PropTypes.shape({
      scriptContent: PropTypes.string.isRequired,
      scriptAttributes: PropTypes.shape({
        src: PropTypes.string.isRequired
      })
    })
  }).isRequired,
  selected: PropTypes.bool.isRequired
};

export default CallToActionView;
