import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import snakeCase from 'lodash/snakeCase';

import {
  reorderBlocks,
  updateSectionSettings,
} from '../../../../redux/theme/actions';
import {
  selectTheme,
  selectSectionSchema,
  selectSection,
} from '../../../../redux/theme/selectors';
import useAddBlock from '../../../../helpers/useAddBlock';
import useDynamicSections from '../../../../helpers/useDynamicSections';
import { useEmptySectionOrBlock } from '../../../../helpers/useEmptySectionOrBlock';
import { useIframe } from '../../../../helpers/useIframe';
import useQuery from '../../../../helpers/useQuery';
import useEncore from '../../../../helpers/useEncore';

import DraggableRow from '../Sections/DraggableRow';
import { InputType } from '../../helpers/inputGenerator';
import Accordion from '../Accordion';

export const SectionSettingsPanel = () => {
  const queryParams = useQuery();
  const isEncore = useEncore();
  const {
    params: { sectionId },
  } = useRouteMatch();
  const addBlock = useAddBlock(sectionId);
  const { pathname, state: { fromBackLink } = {} } = useLocation();

  const theme = useSelector(state => selectTheme(state), shallowEqual);
  const section = useSelector(state => selectSection(state, sectionId), shallowEqual) || {};
  const { settings } = section;
  const sectionSchema = useSelector(
    state => selectSectionSchema(state, section.type),
    shallowEqual
  ) || {};
  const linkPaths = useSelector(state => state.linkPaths, shallowEqual);
  const dispatch = useDispatch();
  const blockOrder = useMemo(
    () =>
      (section.block_order || [])
        .map(id => Object.keys(section.blocks).includes(id) && { id })
        .filter(Boolean),
    [section]
  );
  const dynamicSections = useDynamicSections();
  const isDynamicSection = useMemo(() => dynamicSections.includes(section), [
    dynamicSections,
    section,
  ]);
  const { sendScrollToSectionMessage } = useIframe();
  const [sortDisabled, setSortDisabled] = useState(false);

  const prefix = `settings[sections][${sectionId}]`;

  // The value returned from the child component
  // should be the value to be persisted and not
  // an element / component
  const handleChange = useCallback(
    (name, value) => {
      const attribute = name
        .replace(`${prefix}[settings]`, '')
        .match(/\[(.*)\]/)[1];
      dispatch(updateSectionSettings({ id: sectionId, attribute, value }));
    },
    [dispatch, prefix, sectionId]
  );

  const setList = useCallback(
    (data) => {
      const ids = map(data, 'id');
      if (!isEqual(ids, map(blockOrder, 'id'))) {
        dispatch(reorderBlocks({ sectionId, ids }));
      }
    },
    [blockOrder, dispatch, sectionId]
  );

  const accordionItems = useMemo(
    () =>
      (sectionSchema.groups || []).map(group => ({
        id: snakeCase(group.name),
        group,
        title: group.name,
      })),
    [sectionSchema.groups]
  );

  const buildContentLink = name => (
    <>
      <div className="media-left media-middle">
        <i className="mi md-24 mi-playlist-add">playlist_add</i>
      </div>
      <div className="media-body media-middle">
        <span className="title">Add {name}</span>
      </div>
    </>
  );

  const addContentLink = useMemo(() => {
    const { blocks: schemaBlocks = [] } = sectionSchema;
    switch (schemaBlocks.length) {
      case 0:
        return null;
      case 1:
        return (
          <div
            className="list-group-item add-block dropdown-toggle"
            onClick={() => addBlock(schemaBlocks[0])}
            onKeyUp={() => addBlock(schemaBlocks[0])}
            role="button"
            tabIndex={0}
          >
            {buildContentLink(schemaBlocks[0].name)}
          </div>
        );
      default:
        return (
          <Link
            to={`${pathname}/add_block${queryParams}`}
            className="list-group-item add-block dropdown-toggle"
          >
            {buildContentLink('Content')}
          </Link>
        );
    }
  }, [addBlock, pathname, queryParams, sectionSchema]);

  const { handleDeleteSection } = useEmptySectionOrBlock({ sectionId });

  useEffect(() => {
    if (!isEmpty(section) && !fromBackLink) {
      sendScrollToSectionMessage(section.id);
    }
  }, [fromBackLink, section, sendScrollToSectionMessage]);

  if (isEmpty(section)) {
    return null;
  }

  return (
    <div className="form-section">
      <div className="form-group">
        <div className="header-type">
          <h3 className="header section-name">{section.name}</h3>
        </div>
      </div>
      {/* <% sectionSchema.elements.each do |element| %>
        <%= render_theme_setting_partial
              section.theme,
              element,
              section.settings,
              input_prefix: "#{prefix}[settings]"
        %>
      <% end %> */}
      {(sectionSchema.elements || []).map((element, idx) => (
        <InputType
          // eslint-disable-next-line react/no-array-index-key
          key={`${element.type}_${idx}`}
          type={element.type}
          element={element}
          settings={settings}
          theme={theme}
          inputPrefix={`${prefix}[settings]`}
          onChange={handleChange}
          paths={linkPaths}
          sectionId={sectionId}
        />
      ))}
      {addContentLink && (
        <div className="sidenav-blocks">
          <div className="form-group draggable-sections">
            <div className="list-group">
              <ReactSortable
                disabled={sortDisabled}
                list={blockOrder}
                setList={setList}
              >
                {blockOrder.map(({ id }) => {
                  const block = section.blocks[id];
                  return (
                    block && (
                      <DraggableRow
                        key={id}
                        itemType="block"
                        itemId={id}
                        item={{ ...block, sectionId }}
                        setSortDisabled={setSortDisabled}
                      />
                    )
                  );
                })}
              </ReactSortable>
              {addContentLink}
            </div>
          </div>
        </div>
      )}
      <Accordion
        icon="settings"
        id={section.id}
        items={accordionItems}
        title="Section Settings"
        settings={settings}
        theme={theme}
        inputPrefix={`${prefix}[settings]`}
        onChange={handleChange}
        paths={linkPaths}
        sectionId={sectionId}
      />
      {isEncore && section.id === 'two_step' && (
        <div className="help-links m-t-3">
          <div className="list-group-item media">
            <div className="media-left media-middle">
              <i className="mi md-24 text-light si-menu" />
            </div>
            <div className="media-body media-middle">
              <span className="title">
                <a
                  href={linkPaths.encoreTwoStepUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Help Guide
                </a>
              </span>
            </div>
          </div>
        </div>
      )}
      {isDynamicSection && section.deletable && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a className="delete-link" href="#" onClick={handleDeleteSection}>
          Delete This Section
        </a>
      )}
    </div>
  );
};
