import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { DynamicSelect } from './DynamicSelect';

const Event = ({
  element,
  name,
  value,
  onChange,
  theme: {
    attributes: { siteId },
  },
}) => (
  <DynamicSelect
    element={element}
    helpUrl={`/admin/sites/${siteId}/events`}
    name={name}
    onChange={onChange}
    placeholder="Select your event"
    resourceName="event"
    siteId={siteId}
    value={value}
  />
);

Event.defaultProps = {
  value: '',
};

Event.propTypes = {
  element: PropTypes.exact({
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['event']).isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    attributes: PropTypes.shape({
      siteId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(Event);
