import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import PropTypes from 'prop-types';

import axios from 'axios';
import cancellablePromise from 'common/lib/cancellablePromise';

import LabelTooltip from './LabelTooltip';

export const Post = ({
  element,
  name,
  value,
  onChange,
  theme: {
    attributes: {
      siteId,
      themeable: {
        id: themeableId,
      }
    }
  } }) => {
  const [items, setItems] = useState([]);

  const url = `/admin/sites/${siteId}/select_options/product_post`;

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(
      axios.get(url, { params: { product_id: themeableId } })
    );

    promise.then((res) => {
      const selectData = res.data;
      setItems(
        selectData.results.map(({ id, text, ...rest }) => ({
          value: id,
          label: text,
          ...rest,
        }))
      );
    });

    return cancel;
  }, [url, themeableId]);

  function handleChange(selectedOption) {
    onChange(selectedOption.value);
  }

  return (
    <div className="theme-input">
      <LabelTooltip element={element} name={name} />

      <Select
        isClearable
        onChange={handleChange}
        options={items}
        value={items.find(i => String(i.value) === String(value))}
      />

      <div className="post-type-html" dangerouslySetInnerHTML={{ __html: element.info_html }} />
    </div>
  );
};

Post.defaultProps = {
  value: '',
};

Post.propTypes = {
  element: PropTypes.exact({
    id: PropTypes.string.isRequired,
    info_html: PropTypes.string,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['post']).isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    attributes: PropTypes.shape({
      siteId: PropTypes.number.isRequired,
      themeable: {
        id: PropTypes.number.isRequired,
      },
    }).isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
