import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { useDispatch } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';

import {
  updateBlockSettings,
  updateGlobalSettings,
  updateSectionSettings,
  updateHasRecentMedias,
} from '../../../../redux/theme/actions';
import { setToastMessage } from '../../../../redux/ui/actions';
import { RecentMediaModal } from '../RecentMediaModal';

import { useFilepicker } from './hooks/useFilepicker';
import FilepickerButton from './FilepickerButton';
import LabelTooltip from './LabelTooltip';

function createImageAndUpdateStore({
  blockId,
  key,
  sectionId,
  siteId,
  attribute,
}) {
  return (dispatch) => {
    const token = document.querySelector('meta[name="csrf-token"]').content;

    dispatch(setToastMessage('Processing'));

    axios
      .post(
        `/admin/sites/${siteId}/recent_images`,
        { recent_image: { image: key } },
        {
          responseType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': token,
          },
        }
      )
      .then(() => {
        dispatch(updateHasRecentMedias({ type: 'images', value: true }));
        if (blockId) {
          dispatch(
            updateBlockSettings({
              id: blockId,
              sectionId,
              attribute,
              value: key,
            })
          );
        } else if (sectionId) {
          dispatch(
            updateSectionSettings({ id: sectionId, attribute, value: key })
          );
        } else {
          dispatch(updateGlobalSettings({ attribute, value: key }));
        }
      })
      .finally(() => {
        dispatch(setToastMessage(null));
      });
  };
}

export function Image({
  blockId,
  element,
  name,
  paths: { baseAssetUrl, imageBasePath, imagePlaceholderPath },
  sectionId,
  theme: {
    id: themeId,
    attributes: { cachebuster, filepickerOptions, hasRecentMedias, siteId },
  },
  type,
  value,
}) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const imageAssetPlaceholderPath = useMemo(() => {
    let url = `${baseAssetUrl}/themes/${themeId}/assets/${element.id}`;
    if (cachebuster) {
      url += `?${cachebuster}`;
    }
    return url;
  }, [baseAssetUrl, cachebuster, element, themeId]);

  const placeholderPath = useMemo(
    () => (type === 'image' ? imageAssetPlaceholderPath : imagePlaceholderPath),
    [imageAssetPlaceholderPath, imagePlaceholderPath, type]
  );

  const imageSource = useMemo(
    () => (value ? `${imageBasePath}/${value}` : placeholderPath),
    [imageBasePath, placeholderPath, value]
  );
  const previewId = useMemo(() => `${name}-preview`, [name]);

  const fit = useMemo(() => {
    if (element.fit) {
      return element.fit;
    }
    if (element.width || element.height) {
      return 'max';
    }
    return null;
  }, [element]);

  const format = useMemo(() => {
    const regex = new RegExp(/\.(?!.*\.)(.*)/);
    const extensionMatch = element.id.match(regex);
    return extensionMatch ? extensionMatch[1] : undefined;
  }, [element]);

  const convertOptions = useMemo(
    () => ({
      ...pickBy(
        {
          format,
          width: element.width,
          height: element.height,
          fit,
          ...pick(filepickerOptions.settings_image, ['policy', 'signature']),
        },
        o => !isEmpty(o) || isNumber(o)
      ),
      compress: true,
    }),
    [
      element.height,
      element.width,
      filepickerOptions.settings_image,
      fit,
      format,
    ]
  );

  const config = useMemo(() => {
    const mappedConfig = {
      ...filepickerOptions.settings_image,
      convertOptions,
      preview: `#${previewId}`,
    };

    if (!isEmpty(convertOptions)) {
      if (mappedConfig.services) {
        mappedConfig.services = mappedConfig.services.concat('CONVERT');
      }

      if (typeof mappedConfig.autoProceed !== 'undefined') {
        mappedConfig.autoProceed = false;
      }

      if (typeof mappedConfig.autoOpenFileEditor !== 'undefined') {
        mappedConfig.autoOpenFileEditor = true;
      }
    }

    return mappedConfig;
  }, [convertOptions, filepickerOptions.settings_image, previewId]);

  const onSelectFile = ({ key }) => {
    dispatch(
      createImageAndUpdateStore({
        siteId,
        key,
        blockId,
        sectionId,
        attribute: element.id,
      })
    );
  };

  const resetModal = () => {
    setShowModal(false);
  };

  const handleComplete = ({ key }) => {
    if (blockId) {
      dispatch(
        updateBlockSettings({
          id: blockId,
          sectionId,
          attribute: element.id,
          value: key,
        })
      );
    } else if (sectionId) {
      dispatch(
        updateSectionSettings({
          id: sectionId,
          attribute: element.id,
          value: key,
        })
      );
    } else {
      dispatch(updateGlobalSettings({ attribute: element.id, value: key }));
    }
  };

  const { convertFile } = useFilepicker({ config, handleComplete });

  const storeSelectedImage = (selectedImage) => {
    convertFile(selectedImage.attributes.key);
    resetModal();
  };

  return (
    <>
      <div className="theme-input theme-input-image img-type">
        <LabelTooltip
          element={{ ...element, info: type === 'image' ? null : element.info }}
          name={name}
        />
        <div className="img-frame">
          <img
            alt="selected"
            src={imageSource}
            id={previewId}
            className="img-preview"
          />
        </div>
        <div className="form-group">
          {hasRecentMedias.images ? (
            <div className="select-asset-dropdown dropdown select-asset-dropdown-block">
              <Dropdown>
                <Dropdown.Toggle
                  as="button"
                  className="btn btn-default btn-outline dropdown-toggle"
                >
                  <i className="mi icon-caret">arrow_drop_down</i>Select Image
                </Dropdown.Toggle>
                <Dropdown.Menu as="ul" className="dropdown-menu">
                  <li>
                    <FilepickerButton
                      additionalClassNames="fp-input"
                      config={config}
                      handleSelect={onSelectFile}
                      isLink
                      text="Upload a New File"
                    />
                  </li>
                  <li>
                    <Dropdown.Item onClick={() => setShowModal(true)}>
                      Select a Recent File
                    </Dropdown.Item>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <FilepickerButton
              additionalClassNames="btn-primary btn-outline fp-input"
              config={config}
              handleSelect={onSelectFile}
            />
          )}
        </div>
        {type === 'image' && <p className="help-block">{element.info}</p>}
      </div>
      <RecentMediaModal
        onHide={resetModal}
        onSubmit={storeSelectedImage}
        remoteUrl={`/admin/sites/${siteId}/recent_images`}
        show={showModal}
        title="Recent Images"
      />
    </>
  );
}

Image.defaultProps = {
  blockId: undefined,
  sectionId: undefined,
  value: '',
};

Image.propTypes = {
  blockId: PropTypes.string,
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    info: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  paths: PropTypes.shape({
    baseAssetUrl: PropTypes.string.isRequired,
    imageBasePath: PropTypes.string.isRequired,
    imagePlaceholderPath: PropTypes.string.isRequired,
  }).isRequired,
  sectionId: PropTypes.string,
  theme: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      cachebuster: PropTypes.string,
      filepickerOptions: PropTypes.shape({
        settings_image: PropTypes.shape({}).isRequired,
      }).isRequired,
      hasRecentMedias: PropTypes.shape({
        images: PropTypes.bool.isRequired,
      }).isRequired,
      siteId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
