import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { VALUE_TYPES } from '../constants';
import ContentEditor from '../ContentEditor';
import Autosave from './components/Autosave';
import HiddenInput from './components/HiddenInput';

/**
 * ContentEditorRails
 * A Rails-view adapted ContentEditor, it provides a ready-made component intended
 * to be mounted inside any `<form>` tag. This component handles these operations:
 * - Autosaving functionality
 * - Injection of the ContentEditor's value into a hidden `<input>` field
 *
 * @param {string} inputValue - Initial value of the editor
 * @param {string} inputName - Name of the external `<input>` tag for use
 *                             by wrapping `<form>` element in the Rails view.
 * @param {string} label - Label of the input shown to user e.g. `Edit content`
 * @param {string} autosavePayloadShape - Autosave payload shape
 * @param {string} autosaveEndpoint - Autosave endpoint url
 */

const ContentEditorRails = ({
  inputValue: initialEditorValue,
  inputName: hiddenInputName,
  autosavePayloadShape,
  autosaveEndpoint,
  ...rest
}) => {
  const [editorValue, setEditorValue] = useState(initialEditorValue);
  const [htmlValue, setHtmlValue] = useState(null);
  const hasEditorValueChanged = useRef(false);

  useEffect(() => {
    if (hasEditorValueChanged.current) {
      setHtmlValue(editorValue);
    } else {
      hasEditorValueChanged.current = true;
    }
  }, [editorValue]);

  return (
    <>
      <ContentEditor
        {...rest}
        value={editorValue}
        valueType={VALUE_TYPES.HTML}
        onChange={newValue => setEditorValue(newValue)}
        toolbarChildren={(
          <Autosave
            value={htmlValue}
            payloadShape={autosavePayloadShape}
            endpoint={autosaveEndpoint}
          />
        )}
      />
      <HiddenInput
        value={htmlValue}
        name={hiddenInputName}
      />
    </>
  );
};

ContentEditorRails.propTypes = {
  inputValue: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autosavePayloadShape: PropTypes.string.isRequired,
  autosaveEndpoint: PropTypes.string.isRequired,
};

export default ContentEditorRails;
