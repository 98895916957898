import Text from '@tiptap/extension-text';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import Paragraph from '@tiptap/extension-paragraph';
import OrderedList from '@tiptap/extension-ordered-list';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import History from '@tiptap/extension-history';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import Strike from '@tiptap/extension-strike';
import Code from '@tiptap/extension-code';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import HardBreak from '@tiptap/extension-hard-break';
import Blockquote from '@tiptap/extension-blockquote';

import Document from './Document';
import Image from './Image';
import Placeholder from './Placeholder';
import Link from './Link';
import TextAlign from './TextAlign';
import Indent from './Indent';
import Color from './Color';
import HtmlEditor from './HtmlEditor';
import LiquidTag from './LiquidTag';
import FontSize from './FontSize';
import ScriptTag from './ScriptTag';
import Iframe from './Iframe';
import ListItem from './ListItem';
import ListItemContent from './ListItemContent';

export const EXTENSION_NAMES = {
  HEADING: Heading.name,
  BLOCKQUOTE: Blockquote.name,
  BULLET_LIST: BulletList.name,
  ORDERED_LIST: OrderedList.name,
  HORIZONTAL_RULE: HorizontalRule.name,
  IMAGE: Image.name,
  BOLD: Bold.name,
  ITALIC: Italic.name,
  LINK: Link.name,
  HISTORY: History.name,
  SUPERSCRIPT: Superscript.name,
  SUBSCRIPT: Subscript.name,
  STRIKE: Strike.name,
  TEXT_ALIGN: TextAlign.name,
  CODE: Code.name,
  INDENT: Indent.name,
  UNDERLINE: Underline.name,
  COLOR: Color.name,
  HTML_EDITOR: HtmlEditor.name,
  HARD_BREAK: HardBreak.name,
  LIQUID_TAG: LiquidTag.name,
  FONT_SIZE: FontSize.name,
  SCRIPT_TAG: ScriptTag.name,
  IFRAME: Iframe.name,
};

export const PLUGGABLE_EXTENSION_MANIFEST = {
  [EXTENSION_NAMES.HEADING]: [Heading],
  [EXTENSION_NAMES.BLOCKQUOTE]: [Blockquote],
  [EXTENSION_NAMES.BULLET_LIST]: [BulletList, ListItem, ListItemContent],
  [EXTENSION_NAMES.ORDERED_LIST]: [OrderedList, ListItem, ListItemContent],
  [EXTENSION_NAMES.HORIZONTAL_RULE]: [HorizontalRule],
  [EXTENSION_NAMES.IMAGE]: [Image],
  [EXTENSION_NAMES.BOLD]: [Bold],
  [EXTENSION_NAMES.ITALIC]: [Italic],
  [EXTENSION_NAMES.LINK]: [Link],
  [EXTENSION_NAMES.HISTORY]: [History],
  [EXTENSION_NAMES.SUPERSCRIPT]: [Superscript],
  [EXTENSION_NAMES.SUBSCRIPT]: [Subscript],
  [EXTENSION_NAMES.STRIKE]: [Strike],
  [EXTENSION_NAMES.TEXT_ALIGN]: [TextAlign],
  [EXTENSION_NAMES.CODE]: [Code],
  [EXTENSION_NAMES.INDENT]: [Indent],
  [EXTENSION_NAMES.UNDERLINE]: [Underline],
  [EXTENSION_NAMES.COLOR]: [Color, TextStyle],
  [EXTENSION_NAMES.HTML_EDITOR]: [HtmlEditor],
  [EXTENSION_NAMES.HARD_BREAK]: [HardBreak],
  [EXTENSION_NAMES.LIQUID_TAG]: [LiquidTag],
  [EXTENSION_NAMES.FONT_SIZE]: [FontSize, TextStyle],
  [EXTENSION_NAMES.SCRIPT_TAG]: [ScriptTag],
  [EXTENSION_NAMES.IFRAME]: [Iframe],
};

export const EXTENSION_MANIFEST = {
  REQUIRED: [
    Document,
    Text,
    Paragraph,
    Dropcursor,
    Gapcursor,
    Placeholder,
  ],
  ...PLUGGABLE_EXTENSION_MANIFEST,
};
