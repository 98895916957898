import React from 'react';
import PropTypes from 'prop-types';

import { EXTENSION_NAMES } from '../extensions';

const SettingsContext = React.createContext({});

const useSettingsContext = () => {
  const context = React.useContext(SettingsContext);

  if (context === undefined) {
    throw new Error('useSettingsContext was used outside of its Provider');
  }

  return context;
};

const SettingsProvider = ({
  children,
  extensionNames,
  colors,
  label,
  liquidTags,
  uploaderConfig,
}) => (
  <SettingsContext.Provider
    value={{
      extensionNames,
      colors,
      label,
      liquidTags,
      uploaderConfig,
    }}
  >
    {children}
  </SettingsContext.Provider>
);

SettingsProvider.defaultProps = {
  colors: [],
  label: 'Content',
  liquidTags: [],
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  extensionNames: PropTypes.arrayOf(PropTypes.oneOf(Object.values(EXTENSION_NAMES))).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  liquidTags: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    handle: PropTypes.string,
  })),
  uploaderConfig: PropTypes.shape({}).isRequired,
};

export { SettingsProvider, useSettingsContext };
