import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { deleteBlock, deleteSection } from '../redux/theme/actions';
import { selectSection } from '../redux/theme/selectors';

import useContentKey from './useContentKey';
import { useNavigate } from './useNavigate';

export function useEmptySectionOrBlock({ blockId, sectionId }) {
  const section = useSelector(state => selectSection(state, sectionId), shallowEqual) || {};
  const block = useMemo(
    () => (isEmpty(section) ? null : section.blocks[blockId]),
    [section, blockId]
  );

  const dispatch = useDispatch();
  const contentKey = useContentKey();
  const { goToPath, navigateTo } = useNavigate();

  const deleted = useMemo(
    () => (sectionId && isEmpty(section)) || (blockId && isEmpty(block)),
    [block, blockId, section, sectionId]
  );

  useEffect(() => {
    if (deleted) {
      goToPath(navigateTo);
    }
  }, [goToPath, navigateTo, deleted]);

  const handleDeleteBlock = useCallback(() => {
    dispatch(deleteBlock({ id: blockId, sectionId }));
  }, [blockId, dispatch, sectionId]);

  const handleDeleteSection = useCallback(() => {
    dispatch(deleteSection({ contentKey, id: sectionId }));
  }, [contentKey, dispatch, sectionId]);

  return { handleDeleteBlock, handleDeleteSection };
}
