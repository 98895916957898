import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setSearch } from '../../../../redux/ui/actions';

export default function SearchBar({ showSearch }) {
  const dispatch = useDispatch();

  const onSearchInput = ({ target: { value } }) => {
    dispatch(setSearch(value));
  };

  useEffect(
    () => () => {
      if (showSearch) {
        dispatch(setSearch(''));
      }
    },
    [dispatch, showSearch]
  );

  if (showSearch) {
    return (
      <div>
        <div className="search-picker-container">
          <span>
            <i className="mi md-16">search</i>
          </span>
          <input
            onChange={onSearchInput}
            type="text"
            name=""
            className="search-input"
          />
        </div>
      </div>
    );
  }

  return null;
}

SearchBar.propTypes = {
  showSearch: PropTypes.bool.isRequired,
};
