import React from 'react';
import PropTypes from 'prop-types';

import clientInfo from 'common/lib/clientInfo';

const imagePlaceholderUrl = clientInfo.get('imagePaths.imagePlaceholder');

const ImagePreview = ({ url }) => {
  const imageUrl = url || imagePlaceholderUrl;

  return (
    <>
      { imageUrl && <img alt="" className="img-thumbnail" src={imageUrl} /> }
      <p className="help-block">Please use .jpg or .png with non-transparent background.</p>
    </>
  );
};

ImagePreview.defaultProps = {
  url: null
};

ImagePreview.propTypes = {
  url: PropTypes.string,
};

export default ImagePreview;
