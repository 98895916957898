import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

import { useHotKeys } from '../../../../helpers/useHotKeys';
import { selectFile } from '../../../../redux/file/selectors';

import { PageSwitcher } from '../PageSwitcher';
import StatusLabel from '../StatusLabel';

import BackLink from './BackLink';
import PreviewLink from './PreviewLink';
import RedoUndoButtons from './RedoUndoButtons';
import SaveButton from './SaveButton';
import ViewPortControl from './ViewPortControl';

import '../../../../index.scss';

export function TopBar(props) {
  const hotKeysListener = useHotKeys();
  const dispatch = useDispatch();
  const { id: fileId } = useSelector(state => selectFile(state));

  useEffect(() => {
    document.addEventListener('keydown', hotKeysListener);

    return () => {
      document.removeEventListener('keydown', hotKeysListener);
    };
  }, [hotKeysListener]);

  useEffect(() => {
    dispatch(UndoActionCreators.clearHistory());
  }, [dispatch, fileId]);

  return (
    <div id="editor-top-bar">
      <div>
        <BackLink />
        <div>
          <span>
            <PageSwitcher />
          </span>
          <ViewPortControl />
          <StatusLabel {...props} />
        </div>
      </div>
      <div>
        <RedoUndoButtons />
        <div>
          <PreviewLink />
          <SaveButton />
        </div>
      </div>
    </div>
  );
}
