import { useEffect } from 'react';
import noop from 'lodash/noop';
import querystring from 'query-string';

import useQuery from './useQuery';

const COLLAPSE_CLASS = 'collapse';

export function useHighlightId(prefix = undefined) {
  const { element: elementId } = querystring.parse(useQuery()) || {};

  useEffect(() => {
    const id = `${prefix ? `${prefix}_` : ''}settings_${elementId}`;
    const docElement = document.getElementById(id);
    if (docElement) {
      const { parentElement } = docElement;
      const withinAccordionPanel = parentElement.classList.contains(
        COLLAPSE_CLASS
      );
      if (withinAccordionPanel) {
        parentElement.previousElementSibling.click();
      }

      const highlightHandler = setTimeout(
        () => {
          docElement.scrollIntoView();
          docElement.classList.add('highlight');
        },
        withinAccordionPanel ? 500 : 0
      );

      const unhighlightHandler = setTimeout(
        () => {
          docElement.classList.remove('highlight');
        },
        withinAccordionPanel ? 1500 : 1000
      );

      return () => {
        clearTimeout(highlightHandler);
        clearTimeout(unhighlightHandler);
      };
    }
    return noop;
  }, [elementId, prefix]);
}
