import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import clientInfo from 'common/lib/clientInfo';

export const initializeSentry = ({ tags, ignoreErrors = [] } = {}) => {
  const history = createBrowserHistory();

  const tracingOptions = clientInfo.get('sentryTracingOptions') || {};

  Sentry.init({
    dsn: window.CLIENT_INFO.sentry_dsn,
    normalizeDepth: 5,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        ...tracingOptions
      }),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    traceSampleRate: window.CLIENT_INFO.env === 'production' ? 0.2 : 1.0,
    environment: window.CLIENT_INFO.env,
    ignoreErrors
  });

  Sentry.setTag('site_id', clientInfo.get('site_id'));

  if (tags) {
    Object.keys(tags).forEach(tagKey => Sentry.setTag(tagKey, tags[tagKey]));
  }
};
