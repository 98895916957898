import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  SageTokens,
} from '@kajabi/sage-react';
import {
  useSettingsContext,
} from 'common/components/ContentEditor/contexts';
import { EXTENSION_NAMES } from 'common/components/ContentEditor/extensions';

const ToolFormats = ({ editor }) => {
  const { extensionNames } = useSettingsContext();
  const isExtensionActive = useCallback(name => extensionNames.includes(name), [extensionNames]);

  return (
    <Dropdown
      label="Formats"
      panelSize={Dropdown.PANEL_SIZES.SMALL}
    >
      <Dropdown.ItemList items={[
        {
          borderAfter: true,
          id: 'paragraph',
          label: 'Paragraph',
          icon: SageTokens.ICONS.ALIGN_LEFT,
          isActive: editor.isActive('paragraph'),
          onClick: () => (
            editor
              .chain()
              .focus()
              .setParagraph()
              .run()
          )
        },
        ...(isExtensionActive(EXTENSION_NAMES.HEADING) ? [
          {
            id: 'heading-1',
            label: 'Heading 1',
            icon: SageTokens.ICONS.HEADING_LARGE,
            disabled: !editor.can().toggleHeading({ level: 2 }),
            isActive: editor.isActive('heading', { level: 1 }),
            onClick: () => (
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 1 })
                .run()
            )
          },
          {
            id: 'heading-2',
            label: 'Heading 2',
            icon: SageTokens.ICONS.HEADING_LARGE,
            disabled: !editor.can().toggleHeading({ level: 2 }),
            isActive: editor.isActive('heading', { level: 2 }),
            onClick: () => (
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 2 })
                .run()
            )
          },
          {
            id: 'heading-3',
            label: 'Heading 3',
            icon: SageTokens.ICONS.HEADING_LARGE,
            disabled: !editor.can().toggleHeading({ level: 3 }),
            isActive: editor.isActive('heading', { level: 3 }),
            onClick: () => (
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 3 })
                .run()
            )
          },
          {
            id: 'heading-4',
            label: 'Heading 4',
            icon: SageTokens.ICONS.HEADING_SMALL,
            disabled: !editor.can().toggleHeading({ level: 4 }),
            isActive: editor.isActive('heading', { level: 4 }),
            onClick: () => (
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 4 })
                .run()
            )
          },
          {
            id: 'heading-5',
            label: 'Heading 5',
            icon: SageTokens.ICONS.HEADING_SMALL,
            disabled: !editor.can().toggleHeading({ level: 5 }),
            isActive: editor.isActive('heading', { level: 5 }),
            onClick: () => (
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 5 })
                .run()
            )
          },
          {
            id: 'heading-6',
            label: 'Heading 6',
            icon: SageTokens.ICONS.HEADING_SMALL,
            disabled: !editor.can().toggleHeading({ level: 6 }),
            isActive: editor.isActive('heading', { level: 6 }),
            onClick: () => (
              editor
                .chain()
                .focus()
                .toggleHeading({ level: 6 })
                .run()
            )
          }
        ] : [])
      ]}
      />
    </Dropdown>
  );
};

ToolFormats.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolFormats;
