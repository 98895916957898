import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import jsonApiMiddleware from 'common/lib/redux/middlewares/jsonApi';

// Development tools
import composeEnhancers from 'common/lib/redux/composeWithDevTools';

import createReducer, { initialState } from './reducer';

export default function configureStore(state = initialState) {
  const middlewares = [thunkMiddleware, jsonApiMiddleware];

  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(composeEnhancers(...enhancers));

  const store = createStore(createReducer(), state, composedEnhancers);

  return store;
}
