import React, {
  Children,
  cloneElement,
  createContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

export const TabContext = createContext();

export const Tabs = ({
  activeTab,
  children,
  onTabChange,
}) => {
  const value = useMemo(() => ({
    activeTab,
    onTabChange,
  }), [activeTab, onTabChange]);

  return (
    <ul className="settings-tabs">
      <TabContext.Provider value={value}>
        {
          Children.map(children, child => (
            cloneElement(child, activeTab, onTabChange)
          ))
        }

      </TabContext.Provider>
    </ul>
  );
};

Tabs.defaultProps = {
  children: [],
  onTabChange: noop,
};

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  children: PropTypes.node,
  onTabChange: PropTypes.func
};
