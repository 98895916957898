import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import arrDiff from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import without from 'lodash/without';

import { selectThemeAttributes } from '../../../../redux/theme/selectors';
import { getDifference } from '../../../../helpers/getDifference';
import useContentKey from '../../../../helpers/useContentKey';
import { useIframe } from '../../../../helpers/useIframe';

import { LoaderContainer } from '../Loader';

const findReorderIds = (newArr, oldArr) => {
  const movedId = newArr.filter(e =>
    isEqual(without(newArr, e), without(oldArr, e))
  )[0];
  const nextId = newArr[newArr.findIndex(e => e === movedId) + 1];
  return [movedId, nextId];
};

const PreviewLoader = () => (
  <LoaderContainer top="40%">
    <div className="spinning-loader" />
    <span>Preview loading...</span>
  </LoaderContainer>
);

export const PreviewFrame = () => {
  const { disableIframePointerEvents, reordering, viewPort } = useSelector(
    state => state.ui
  );
  const { themeFileEditingPreviewUrl } = useSelector(
    state => state.linkPaths,
    shallowEqual
  );
  const { settings } = useSelector(state => selectThemeAttributes(state));
  const previousSettings = useRef(settings);
  const [fullScreen, setFullScreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const contentKey = useContentKey();
  const previousContentKey = useRef(contentKey);
  const {
    sendAddSectionMessage,
    sendReloadSectionMessage,
    sendRemoveSectionMessage,
    sendReorderSectionMessage,
  } = useIframe();

  const handleClick = () => setFullScreen(s => !s);

  useEffect(() => {
    setLoading(true);
  }, [themeFileEditingPreviewUrl]);

  useEffect(() => {
    const differences = contentKey === previousContentKey.current
      ? getDifference(settings, previousSettings.current)
      : [];

    if (!isEmpty(differences)) {
      if (previousSettings.current[contentKey] && differences[contentKey]) {
        const currentSections = settings[contentKey].filter(Boolean);
        const previousSections = previousSettings.current[contentKey].filter(
          Boolean
        );
        if (currentSections.length < previousSections.length) {
          const sectionId = arrDiff(previousSections, currentSections)[0];
          sendRemoveSectionMessage(sectionId);
        } else if (currentSections.length > previousSections.length) {
          const sectionId = arrDiff(currentSections, previousSections)[0];
          const section = settings.sections[sectionId];
          sendAddSectionMessage(section, settings[contentKey]);
        } else {
          const [movedSectionId, nextSectionId] = findReorderIds(
            currentSections,
            previousSections
          );
          sendReorderSectionMessage(movedSectionId, nextSectionId);
        }
      } else if (differences.sections) {
        const sectionId = Object.keys(differences.sections)[0];
        const section = settings.sections[sectionId];
        sendReloadSectionMessage(section);
      }
    }
    previousSettings.current = settings;
    previousContentKey.current = contentKey;
  }, [
    contentKey,
    sendAddSectionMessage,
    sendReloadSectionMessage,
    sendRemoveSectionMessage,
    sendReorderSectionMessage,
    settings,
  ]);
  return (
    <div id="content" className={fullScreen ? 'full-screen' : ''}>
      <div className="content-wrapper">
        <div className={`preview ${reordering ? 'dragdrop' : viewPort}`}>
          {loading && <PreviewLoader />}
          <iframe
            // In old markup, theme_settings.js and theme_previewing.js are rendered
            // onLoad; this then initializes theme_editor-bindings.js, which sets up
            // the listeners for messages that the definiton of ThemePreview calls.
            // Each of the function calls in theme_preview.js will be addressed
            // in subsequent tickets
            // define='{ preview: new App.ThemePreview(this) }'
            frameBorder="0"
            id="preview-window"
            onLoad={() => setLoading(false)}
            src={themeFileEditingPreviewUrl}
            style={{
              pointerEvents: disableIframePointerEvents ? 'none' : 'unset',
            }}
            title="preview-window"
          />
        </div>
        <div
          className="expand"
          onClick={handleClick}
          onKeyDown={handleClick}
          role="link"
          tabIndex={0}
        >
          <i className="mi">
            {fullScreen ? 'arrow_drop_up' : 'arrow_drop_down'}
          </i>
        </div>
      </div>
    </div>
  );
};
