/* eslint-disable no-console */
import axios from 'axios';
import normalize from 'json-api-normalizer';
import noop from 'lodash/noop';

import * as consts from 'common/lib/redux/constants';
import clientInfo from 'common/lib/clientInfo';
import { flashError, flashSuccess } from 'common/components/FlashMessages';

export const getJsonApiHeaders = () => {
  const token = document.querySelector('meta[name="csrf-token"]');
  const headers = {
    Authorization: window.validationToken,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': token ? token.getAttribute('content') : null,
  };

  if (clientInfo.get('superAdminMasquerading')) {
    headers.MasqueradingToken = clientInfo.get('masqueradingToken');
  }

  return headers;
};

const instance = axios.create({
  baseURL: window.location.origin,
  headers: getJsonApiHeaders(),
});

const showSuccessMessage = (response, fallbackMessage) => {
  const { flashMessage } = response.meta || {};
  const message = flashMessage || fallbackMessage;

  if (message) {
    flashSuccess({ text: message });
  }
};

const showErrorMessage = (errors, status) => {
  let { title: errorMessage } = errors.find(error => error.status && error.title) || {};
  if (status === 422) {
    errorMessage = consts.MESSAGES.UNPROCESSABLE_ENTITY;
  }

  if (errorMessage) {
    flashError({ text: errorMessage });
  }
};

const jsonApiMiddleware = ({ dispatch }) => next => (action) => {
  if (action.type !== consts.API_REQUEST) {
    return next(action);
  }

  next(action);

  const {
    url,
    method = 'GET',
    data = {},
    normalizeData = true,
    onComplete = noop,
    onStart,
    onSuccess,
    onFailure,
    successMessage,
    pollingStopCondition,
    pollingInterval,
  } = action.payload;

  if (!url) {
    throw new Error('An "url" attribute is missing');
  }

  if (onStart) dispatch(onStart());

  const onSuccessCallback = (response) => {
    dispatch(
      onSuccess({
        data: normalizeData ? normalize(response.data) : response.data,
        meta: response.data.meta,
      })
    );
    showSuccessMessage(response.data, successMessage);
  };

  const pollingCallback = (response) => {
    if (pollingStopCondition(response)) {
      onSuccessCallback(response);
      return;
    }

    if (pollingInterval && pollingInterval > 0) {
      setTimeout(
        () =>
          dispatch({
            type: action.type,
            payload: { ...action.payload, onStart: null },
          }),
        pollingInterval
      );
    }
  };

  const onFailureCallback = (errorData) => {
    if (process.env.NODE_ENV === 'development') {
      console.log('API request error:', errorData);
    }
    const {
      status,
      data: { errors = [] },
    } = errorData.response || { data: {} };

    showErrorMessage(errors, status);
    if (onFailure) dispatch(onFailure({ errors, status }));
  };

  const dataKey = method === 'GET' || method === 'DELETE' ? 'params' : 'data';
  const req = instance.request({ url, method, [dataKey]: data });

  req
    .then((response) => {
      if (pollingStopCondition && pollingInterval) {
        pollingCallback(response);
      } else {
        onSuccessCallback(response);
      }
    })
    .catch(onFailureCallback)
    .then(onComplete);

  return req;
};

export default jsonApiMiddleware;
