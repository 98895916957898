import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import pluralize from 'pluralize';

import { RemoteSelect } from '../RemoteSelect';
import HelpBlock from './HelpBlock';
import LabelTooltip from './LabelTooltip';

export function DynamicSelect({
  classNamePrefix,
  element,
  helpUrl,
  itemSelectAttr,
  name,
  onChange,
  placeholder,
  resourceName,
  siteId,
  value,
}) {
  const url = useMemo(
    () => `/admin/sites/${siteId}/select_options/${resourceName}`,
    [resourceName, siteId]
  );

  function handleChange(selectedOption) {
    onChange(String((selectedOption || {})[itemSelectAttr]));
  }

  return (
    <div className="theme-input">
      <LabelTooltip element={element} name={name} />
      <RemoteSelect
        classNamePrefix={classNamePrefix}
        itemSelectAttr={itemSelectAttr}
        onChange={handleChange}
        placeholder={placeholder}
        remoteUrl={url}
        value={value}
      />
      <HelpBlock
        href={helpUrl}
        text={`Edit your ${pluralize(element.type.replace(/_/g, ' '))}`}
      />
    </div>
  );
}

DynamicSelect.defaultProps = {
  classNamePrefix: undefined,
  itemSelectAttr: 'value',
  placeholder: undefined,
  value: undefined,
};

DynamicSelect.propTypes = {
  classNamePrefix: PropTypes.string,
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      'assessment',
      'event',
      'form',
      'link_list',
      'offer',
      'sales_page',
    ]),
  }).isRequired,
  helpUrl: PropTypes.string.isRequired,
  itemSelectAttr: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  resourceName: PropTypes.oneOf([
    'assessment',
    'event',
    'form',
    'navbar',
    'offer',
    'sales_page',
  ]).isRequired,
  siteId: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
