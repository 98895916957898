import { useEffect, useState } from 'react';

export default function useDebounce({ callback, delay = 500, value }) {
  const [updatedValue, setUpdatedValue] = useState(value);
  const [debouncedValue, setDebouncedValue] = useState(updatedValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (debouncedValue !== updatedValue) {
        setDebouncedValue(updatedValue);
        callback(updatedValue);
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedValue, updatedValue, delay, callback]);

  useEffect(() => {
    setUpdatedValue(value);
  }, [value]);

  return [updatedValue, setUpdatedValue];
}
