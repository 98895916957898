import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  SageTokens,
} from '@kajabi/sage-react';

const ToolIndent = ({ editor }) => (
  <>
    <Button
      iconOnly={true}
      color={Button.COLORS.SECONDARY}
      icon={SageTokens.ICONS.MARGIN_LEFT}
      onClick={() => editor
        .chain()
        .focus()
        .indent()
        .run()}
      disabled={!editor.can().indent()}
    >
        Indent
    </Button>
    <Button
      iconOnly={true}
      color={Button.COLORS.SECONDARY}
      icon={SageTokens.ICONS.MARGIN_RIGHT}
      onClick={() => editor
        .chain()
        .focus()
        .outdent()
        .run()}
      disabled={!editor.can().outdent()}
    >
        Outdent
    </Button>
  </>
);

ToolIndent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolIndent;
