import { useRef, useState, useMemo, useCallback } from 'react';

import clone from 'lodash/clone';

const useFilepicker = ({ config, onUploadSuccess }) => {
  const mutableConfig = useMemo(() => {
    if (!config) {
      return {};
    }
    // Allows filepicker.js to make changes to services array
    const services = config.services && clone(config.services);
    return {
      ...config,
      services
    };
  }, [config]);

  const canInitUploader = window.App
    && window.App.FilepickerUpload
    && Object.keys(config || {}).length;

  const uploadRef = useRef(
    canInitUploader
      ? new window.App.FilepickerUpload(mutableConfig)
      : null
  ).current;

  const [isUploading, setIsUploading] = useState(false);

  const startUploading = () => {
    setIsUploading(true);
  };

  const handleUploadError = (error) => {
    /* eslint-disable-next-line no-console */
    console.log(error);
  };

  const handleFileUpload = useCallback(() => {
    if (isUploading || !uploadRef) {
      return;
    }

    const pick = uploadRef.pick.bind(uploadRef);
    const store = uploadRef.store.bind(uploadRef);
    const convert = uploadRef.convert.bind(uploadRef);

    Promise.resolve(startUploading())
      .then(pick)
      .then(store)
      .then(convert)
      .then(onUploadSuccess)
      .catch(handleUploadError)
      .finally(() => setIsUploading(false));
  }, [uploadRef, isUploading, onUploadSuccess]);

  return {
    isUploading,
    handleFileUpload
  };
};

export default useFilepicker;
