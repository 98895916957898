import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import compact from 'lodash/compact';

import useContentKey from '../../../../helpers/useContentKey';
import useDynamicSections from '../../../../helpers/useDynamicSections';
import { useIframe } from '../../../../helpers/useIframe';
import useQuery from '../../../../helpers/useQuery';
import { selectSections as selectSectionsOrder } from '../../../../redux/file/selectors';
import { reorderSections } from '../../../../redux/theme/actions';
import { selectSections } from '../../../../redux/theme/selectors';
import { setReordering } from '../../../../redux/ui/actions';

import { StaticSectionItem } from './StaticSectionItem';
import DraggableRow from './DraggableRow';
import { ThirdPartyCookieAlert } from './ThirdPartyCookieAlert';

const MAX_DYNAMIC_SECTION_COUNT = 50;

export const Sections = () => {
  const queryParams = useQuery();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const sections = useSelector(state => selectSections(state));
  const sectionOrder = useSelector(state => selectSectionsOrder(state));
  const dynamicSections = useDynamicSections();
  const contentKey = useContentKey();
  const dynamicSectionsOrder = compact(dynamicSections).map(({ id }) => ({
    id,
  }));
  const {
    sendAddHighlightSectionMessage,
    sendEndHighlightMessage,
    sendReorderSectionMessage,
  } = useIframe();
  const [sortDisabled, setSortDisabled] = useState(false);

  const setList = useCallback(
    (data) => {
      const dataIds = map(data, 'id');
      const sectionIds = map(dynamicSectionsOrder, 'id');
      if (!isEqual(dataIds, sectionIds)) {
        dispatch(reorderSections({ contentKey, ids: dataIds }));
      }
    },
    [contentKey, dispatch, dynamicSectionsOrder]
  );

  const handleChange = ({ oldIndex, newIndex }) => {
    const newOrder = [...dynamicSectionsOrder];
    const element = newOrder[oldIndex];
    newOrder.splice(oldIndex, 1);
    newOrder.splice(newIndex, 0, element);
    sendReorderSectionMessage(element.id, (newOrder[newIndex + 1] || {}).id);
  };

  const handleStartSort = ({ oldIndex }) => {
    sendAddHighlightSectionMessage(dynamicSectionsOrder[oldIndex].id);
    dispatch(setReordering(true));
  };

  const handleEndSort = () => {
    sendEndHighlightMessage();
    dispatch(setReordering(false));
  };

  const sectionItems = sectionOrder.map((section) => {
    if (section.isDynamic) {
      return (
        <React.Fragment key="dynamicSections">
          <div className="form-group draggable-sections dynamic-sections-list">
            <div className="list-group">
              <ReactSortable
                disabled={sortDisabled}
                onChange={handleChange}
                onStart={handleStartSort}
                onEnd={handleEndSort}
                list={dynamicSectionsOrder}
                setList={setList}
              >
                {dynamicSections.map(s => (
                  <DraggableRow
                    key={s.id}
                    itemType="section"
                    itemId={s.id}
                    item={s}
                    setSortDisabled={setSortDisabled}
                  />
                ))}
              </ReactSortable>
              {dynamicSections.length < MAX_DYNAMIC_SECTION_COUNT ? (
                <Link
                  to={`${pathname}/add_section${queryParams}`}
                  className="list-group-item add-section"
                >
                  <div className="media-left media-middle">
                    <i className="mi md-24 mi-playlist-add">playlist_add</i>
                  </div>
                  <div className="media-body media-middle">
                    <span className="title">Add Section</span>
                  </div>
                </Link>
              ) : (
                <p className="help-block section-count-warning">
                  This page cannot have more than {MAX_DYNAMIC_SECTION_COUNT}{' '}
                  sections.
                </p>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    }

    const mergedSection = { ...sections[section.id], ...section };
    return (
      <React.Fragment key={section.id}>
        <StaticSectionItem key={section.id} {...mergedSection} />
      </React.Fragment>
    );
  });

  return (
    <>
      <ThirdPartyCookieAlert />
      {sectionItems}
    </>
  );
};
