import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { changeSidebarTab } from '../../../../redux/ui/actions';
import {
  selectSettingsSchema,
  selectThemeAttributes,
} from '../../../../redux/theme/selectors';

import { Sections } from '../Sections';
import { Settings } from '../Settings';
import { Tabs, TabItem } from '../Tabs';

import { HelpLinks } from './HelpLinks';

export function SectionsPanel() {
  const dispatch = useDispatch();

  const { activeTab } = useSelector(state => state.ui.sideBar);
  const { sectionSchemas, themeable, themeableType } = useSelector(state =>
    selectThemeAttributes(state)
  );
  const settingsSchema = useSelector(state => selectSettingsSchema(state));
  const user = useSelector(state => state.user.data.attributes);

  function handleTabChange(tab) {
    dispatch(changeSidebarTab(tab));
  }

  return (
    <div className="settings-main">
      {isEmpty(sectionSchemas) ? (
        <Settings {...settingsSchema} />
      ) : (
        <>
          <Tabs
            activeTab={activeTab}
            onTabChange={clickedTab => handleTabChange(clickedTab)}
          >
            <TabItem label="Sections" />
            <TabItem label="Settings" />
          </Tabs>
          <div className="sidenav-sections">
            {activeTab === 'Sections' ? (
              <Sections />
            ) : (
              <Settings {...settingsSchema} />
            )}
          </div>
          <HelpLinks
            activeTab={activeTab}
            theme_info={settingsSchema.theme_info}
            themeable={themeable}
            themeableType={themeableType}
            user={user}
          />
        </>
      )}
    </div>
  );
}
