import React, { useCallback } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import kebabCase from 'lodash/kebabCase';

import { updateGlobalSettings } from '../../../../redux/theme/actions';
import {
  selectTheme,
  selectSettingsSchema,
} from '../../../../redux/theme/selectors';
import { InputType } from '../../helpers/inputGenerator';
import { useHighlightId } from '../../../../helpers/useHighlightId';

export const GlobalSettingsPanel = () => {
  const {
    params: { settingId },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const theme = useSelector(state => selectTheme(state));

  const {
    attributes: { settings },
  } = theme;

  const schema = useSelector(
    state => selectSettingsSchema(state),
    shallowEqual
  );

  const schemaGroup = schema.groups.find(g => g.name.replace(/\s/g, '') === settingId);

  const elementId = kebabCase(schemaGroup.name);

  const linkPaths = useSelector(state => state.linkPaths, shallowEqual);

  // The value returned from the child component
  // should be the value to be persisted and not
  // an element / component
  const handleChange = useCallback(
    (name, value) => {
      const attribute = name.replace('[settings]', '').match(/\[(.*)\]/)[1];
      dispatch(updateGlobalSettings({ attribute, value }));
    },
    [dispatch]
  );

  useHighlightId();

  return (
    <div
      data-settings-panel={elementId}
      data-settings-panel-snippet={schemaGroup.snippet} // What is group.snippet
      className="form-section"
    >
      {schemaGroup.elements.map((element, idx) => (
        <InputType
          // eslint-disable-next-line react/no-array-index-key
          key={`${element.type}_${idx}`}
          type={element.type}
          element={element}
          settings={settings}
          theme={theme}
          onChange={handleChange}
          paths={linkPaths}
        />
      ))}
    </div>
  );
};
