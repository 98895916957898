import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import useDynamicSections from 'apps/admin/themes/editor/helpers/useDynamicSections';

export const ActionSectionAnchor = ({ onChange, value }) => {
  const dynamicSections = useDynamicSections();

  const handleChange = (e) => {
    onChange(e ? e.value : '');
  };

  const items = dynamicSections.map(({ id, name, ...rest }) => ({
    value: `#section-${id}`,
    label: name,
    ...rest,
  }));

  return (
    <Select
      isClearable
      onChange={handleChange}
      options={items}
      value={items.find(
        item => String(item.value) === String(value))}
    />
  );
};

ActionSectionAnchor.defaultProps = {
  value: '',
};

ActionSectionAnchor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};
