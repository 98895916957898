import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import IframeView from './IframeView';

const EXTENSION_NAME = 'iframe';

export default Node.create({
  name: EXTENSION_NAME,
  group: 'block',
  atom: true,

  addAttributes() {
    return ({
      iframeAttributes: {
        default: {},
        parseHTML: element => Object.fromEntries(
          element.getAttributeNames().map(attrKey => [attrKey, element.getAttribute(attrKey)])
        ),
      },
    });
  },

  parseHTML() {
    return [{ tag: 'iframe' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'iframe',
      HTMLAttributes.iframeAttributes,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(IframeView);
  }
});
