import React, { useMemo, useState } from 'react';
import { Button, Modal, SageClassnames, SageTokens } from '@kajabi/sage-react';

import KeyboardIcon from './images/keyboard.svg';

const MAC_PLATFORMS = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'iPad'];

export function KeyboardShortcuts() {
  const [showModal, setShowModal] = useState(false);

  const modifierKey = useMemo(
    () =>
      (MAC_PLATFORMS.indexOf(window.navigator.platform) !== -1
        ? 'Command'
        : 'Control'),
    []
  );

  const closeModal = () => setShowModal(false);

  return (
    <>
      <div className="list-group-item media">
        <div className="media-left media-middle">
          <img
            src={KeyboardIcon}
            alt=""
            className="mi md-24"
            style={{
              filter:
                'brightness(0) saturate(100%) invert(51%) sepia(6%) saturate(1007%) hue-rotate(169deg) brightness(88%) contrast(93%)',
            }}
          />
        </div>
        <div className="media-body media-middle">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" className="title" onClick={() => setShowModal(true)}>
            Keyboard Shortcuts
          </a>
        </div>
      </div>
      <Modal active={showModal} onExit={closeModal}>
        <Modal.Header
          aside={(
            <Button
              color={Button.COLORS.SECONDARY}
              onClick={closeModal}
              icon={SageTokens.ICONS.REMOVE}
              iconOnly
              raised={false}
              subtle
            />
)}
          customHeader={(
            <h4 className={SageClassnames.TYPE.HEADING_4}>
              Keyboard Shortcuts
            </h4>
)}
        />
        <Modal.Body style={{ fontSize: 14 }}>
          <div className={SageClassnames.SPACERS.MD_BOTTOM}>
            <h4 className={SageClassnames.TYPE_COLORS.CHARCOAL_100}>General</h4>
            <hr style={{ margin: '4px 0 24px 0' }} />
            <div className="sage-row">
              <div
                className={[
                  'sage-col--sm-6',
                  SageClassnames.TYPE_ALIGN_RIGHT,
                ].join(' ')}
              >
                {modifierKey} + S
              </div>
              <div className="sage-col--sm-6">Save</div>
            </div>
          </div>
          <div>
            <h4 className={SageClassnames.TYPE_COLORS.CHARCOAL_100}>
              Undo/Redo
            </h4>
            <hr style={{ margin: '4px 0 24px 0' }} />
            <div className="sage-row">
              <div
                className={[
                  'sage-col--sm-6',
                  SageClassnames.TYPE_ALIGN_RIGHT,
                ].join(' ')}
              >
                {modifierKey} + Z
              </div>
              <div className="sage-col--sm-6">Undo</div>
            </div>
            <div className="sage-row">
              <div
                className={[
                  'sage-col--sm-6',
                  SageClassnames.TYPE_ALIGN_RIGHT,
                ].join(' ')}
              >
                {modifierKey} + Shift + Z
              </div>
              <div className="sage-col--sm-6">Redo</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
