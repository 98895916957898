import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Input,
  Checkbox,
  Modal,
  Button,
  SageTokens,
} from '@kajabi/sage-react';

import { useTiptapEditorContext } from 'common/components/ContentEditor/contexts';

const Link = ({
  closeFn
}) => {
  const { editor } = useTiptapEditorContext();
  const initialHrefValue = editor.getAttributes('link').href;
  const initialTargetValue = editor.getAttributes('link').target || '';
  const initialRelValue = editor.getAttributes('link').rel || '';

  const [hrefValue, setHrefValue] = useState(initialHrefValue);
  const [targetValue, setTargetValue] = useState(initialTargetValue);

  const renderDeleteButton = useCallback(() => {
    if (!initialHrefValue) return false;

    const deleteFn = () => {
      editor.chain()
        .extendMarkRange('link')
        .unsetLink()
        .run();
      closeFn();
    };

    return (
      <Button
        color={Button.COLORS.DANGER}
        subtle={true}
        onClick={deleteFn}
      >
        Delete link
      </Button>
    );
  }, [initialHrefValue, editor, closeFn]);

  const renderSaveButton = useCallback(() => {
    const setFn = () => {
      if (editor.isActive('link')) {
        editor.chain()
          .focus()
          .extendMarkRange('link')
          .updateAttributes('link', { href: hrefValue, target: targetValue, rel: initialRelValue })
          .run();
      } else {
        editor.chain()
          .focus()
          .setLink({ href: hrefValue, target: targetValue, rel: initialRelValue })
          .run();
      }
      closeFn();
    };

    return (
      <Button
        color={Button.COLORS.PRIMARY}
        raised={false}
        onClick={setFn}
        disabled={!hrefValue}
      >
        {initialHrefValue ? 'Update link' : 'Add link'}
      </Button>
    );
  }, [hrefValue, initialHrefValue, editor, closeFn, targetValue, initialRelValue]);

  return (
    <Modal active={true} onExit={closeFn}>
      <Modal.Header
        title="Link"
        aside={(
          <Button
            color={Button.COLORS.SECONDARY}
            iconOnly={true}
            icon={SageTokens.ICONS.REMOVE}
            subtle={true}
            onClick={closeFn}
          >
            Close link modal
          </Button>
        )}
      />
      <Modal.Body>
        <Input
          label="Link Url"
          value={hrefValue}
          onChange={({ target }) => setHrefValue(target.value)}
        />
        <Checkbox
          label="Open in new window"
          onClick={() => setTargetValue((prevState) => {
            if (prevState === '_blank') return '';
            return '_blank';
          })}
          checked={targetValue === '_blank'}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterAside>
          {renderDeleteButton()}
        </Modal.FooterAside>
        <Button
          color={Button.COLORS.SECONDARY}
          raised={false}
          onClick={closeFn}
        >
          Cancel
        </Button>
        {renderSaveButton()}
      </Modal.Footer>
    </Modal>
  );
};

Link.propTypes = {
  closeFn: PropTypes.func.isRequired
};

export default Link;
