import React from 'react';
import PropTypes from 'prop-types';

import { DynamicSelect } from './DynamicSelect';

export const Offer = ({
  element,
  name,
  onChange,
  theme: {
    attributes: { siteId },
  },
  value,
}) => (
  <DynamicSelect
    element={element}
    helpUrl={`/admin/sites/${siteId}/offers`}
    name={name}
    onChange={onChange}
    resourceName="offer"
    siteId={siteId}
    value={value}
  />
);


Offer.defaultProps = {
  value: undefined,
};

Offer.propTypes = {
  element: PropTypes.shape({
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    attributes: PropTypes.shape({
      siteId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
