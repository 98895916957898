import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import startCase from 'lodash/startCase';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

function CustomToggle({ eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey);

  return (
    <div
      className="accordion-toggle mi keyboard_arrow_down"
      onClick={decoratedOnClick}
      onKeyUp={decoratedOnClick}
      role="button"
      tabIndex="0"
    />
  );
}

CustomToggle.propTypes = {
  eventKey: PropTypes.string.isRequired,
};

export function EventsList({ events }) {
  return (
    <div className="media-events">
      {events.map(
        ({
          city,
          country,
          embed_url: embedUrl,
          event_key: eventKey,
          iframe_heatmap_url: heatmapUrl,
          ip,
          received_at: receivedAt,
          region,
          user_agent_details: userAgentDetails,
        }) => {
          const localReceivedAt = moment.utc(receivedAt).local();

          return (
            <Accordion key={eventKey}>
              <div className="media-event">
                <div className="media">
                  <div className="media-event-time media-left media-middle nowrap">
                    {localReceivedAt.format('MMMM D, YYYY')}
                    <br />
                    {localReceivedAt.format('H:mm a')}
                  </div>
                  <div className="media-event-location media-body media-middle">
                    {`${city}, ${region}, ${country}`}
                  </div>
                  <div className="media-right media-middle">
                    <CustomToggle eventKey={eventKey} />
                  </div>
                  <div className="media-right media-middle media-heatmap">
                    <iframe
                      src={heatmapUrl}
                      width="100%"
                      height="76px"
                      frameBorder="0"
                      title={eventKey}
                    />
                  </div>
                </div>
                <Accordion.Collapse eventKey={eventKey}>
                  <div className="stat-event-details">
                    <div className="media">
                      <div className="media-left stat-detail-label">
                        {userAgentDetails && (
                          <>
                            Device <br />
                          </>
                        )}
                        IP
                        <br />
                        Page
                        <br />
                      </div>
                      <div className="media-body stat-detail">
                        {userAgentDetails && (
                          <>
                            {userAgentDetails.mobile ? 'Mobile' : 'Desktop'} /{' '}
                            {startCase(userAgentDetails.platform)} /{' '}
                            {startCase(userAgentDetails.browser)}
                          </>
                        )}
                        <br />
                        {ip} <br />
                        <a
                          className="page"
                          href={embedUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                          title={embedUrl}
                        >
                          {embedUrl}
                        </a>
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </div>
            </Accordion>
          );
        }
      )}
    </div>
  );
}

EventsList.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      embed_url: PropTypes.string.isRequired,
      event_key: PropTypes.string.isRequired,
      iframe_heatmap_url: PropTypes.string.isRequired,
      ip: PropTypes.string.isRequired,
      received_at: PropTypes.string.isRequired,
      region: PropTypes.string.isRequired,
      user_agent_details: PropTypes.shape({}),
    })
  ).isRequired,
};
