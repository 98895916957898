import { uniq } from 'lodash';
import { EXTENSION_MANIFEST } from './manifest';

const selectPluggableExtensions = (extensionNames = []) => {
  const pluggableExtensions = extensionNames.map((name) => {
    const extension = EXTENSION_MANIFEST[name];
    if (!extension) throw new Error(`[ContentEditor] ${name} not an available extension`);
    return extension;
  }).flat();

  return uniq(pluggableExtensions);
};

const selectExtensions = (extensionNames = []) => (
  [...EXTENSION_MANIFEST.REQUIRED, ...selectPluggableExtensions(extensionNames)]
);

export default selectExtensions;
