import { createAsyncAction } from 'common/lib/redux/createActions';

import { FETCH_THEME, UPDATE_THEME } from './constants';

export const fetchThemeSettings = createAsyncAction(
  FETCH_THEME,
  ({ themeId, themeFileId = null }) => ({
    url: `/admin/themes/${themeId}/settings`,
    data: { theme_file_id: themeFileId },
    normalizeData: false,
  })
);

export const updateThemeSettings = createAsyncAction(
  UPDATE_THEME,
  ({ onComplete, settings, themeId, themeFileId = null }) => ({
    url: `/admin/themes/${themeId}/settings`,
    data: { file_id: themeFileId, theme_setting: settings },
    method: 'PUT',
    normalizeData: false,
    onComplete,
  })
);
