import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import clientInfo from 'common/lib/clientInfo';

/**
 * ContentEditorRails HiddenInput
 * Injects a hidden `<input>` DOM element with the editor value on to the page.
 *
 * This functionality allows any Rails-based `<form>` element wrapped around this
 * component to save the value stored within the editor.
 *
 * The component bypasses the shadow DOM and manually sets the `value` and `name`
 * in a performant (debounced) manner. Bypassing React's shadow DOM is necessary
 * for rendering html attributes to a hidden element.
 *
 * @param {string} value - Stringified HTML
 * @param {string} name - `name` HTML input attribute,
 *                        used by the browser `<form>` to identify the resource name
 */

const IS_TEST_ENV = (clientInfo.get('env') === 'test');
const DOM_INJECTION_DEBOUNCE = IS_TEST_ENV ? 0 : 500; // milliseconds

const HiddenInput = ({
  value,
  name,
}) => {
  const externalInputRef = useRef();

  useEffect(() => {
    debouncedUpdateRef.current(externalInputRef, value, name);
  }, [value, name]);

  const debouncedUpdateRef = useRef(debounce((ref, newValue, newName) => {
    if (newValue) {
      ref.current.setAttribute('value', newValue);
      ref.current.setAttribute('name', newName);
    }
  }, DOM_INJECTION_DEBOUNCE));

  return (
    <input
      ref={externalInputRef}
      readOnly
      hidden
    />
  );
};

HiddenInput.defaultProps = {
  value: null,
};

HiddenInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default HiddenInput;
