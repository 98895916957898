import React from 'react';

import { useUiContext } from 'common/components/ContentEditor/contexts';
import DialogCallToAction from './DialogCallToAction';
import DialogLink from './DialogLink';
import DialogImage from './DialogImage';
import DialogHtmlEditor from './DialogHtmlEditor';

import {
  DIALOG_CALL_TO_ACTION,
  DIALOG_LINK,
  DIALOG_IMAGE,
  DIALOG_HTML_EDITOR,
} from './constants';

const DIALOGS = {
  [DIALOG_CALL_TO_ACTION]: DialogCallToAction,
  [DIALOG_LINK]: DialogLink,
  [DIALOG_IMAGE]: DialogImage,
  [DIALOG_HTML_EDITOR]: DialogHtmlEditor,
};

const Dialog = () => {
  const { dialog, setDialog } = useUiContext();
  const DialogComponent = DIALOGS[dialog];

  if (!DialogComponent) return null;

  return <DialogComponent closeFn={() => setDialog(null)} />;
};

export default Dialog;
