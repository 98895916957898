import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

import useEncore from '../../../../helpers/useEncore';

import { Feedback } from './Feedback';
import { HelpGuide } from './HelpGuide';
import { KeyboardShortcuts } from './KeyboardShortcuts';

const buildItem = (Component, props = {}) => ({ Component, props });

export const HelpLinks = ({
  activeTab,
  theme_info: themeInfo,
  user,
  themeable: { id: themeableId },
  themeableType,
}) => {
  const { encoreGlobalSettingUrl } = useSelector(
    state => state.linkPaths,
    shallowEqual
  );
  const isEncore = useEncore();

  const items = useMemo(() => {
    const isSectionsTab = activeTab === 'Sections';
    const isSite = themeableType === 'Site';
    const helpComponents = [buildItem(KeyboardShortcuts)];
    if (isSite && isEncore) {
      helpComponents.push(
        buildItem(HelpGuide, {
          url: isSectionsTab
            ? 'https://help.kajabi.com/hc/en-us'
            : encoreGlobalSettingUrl,
        })
      );
      if (isSectionsTab) {
        helpComponents.splice(
          0,
          0,
          buildItem(Feedback, {
            url: `https://kajabi.typeform.com/to/r0DbWdAX#account_id=${user.accountId}&site_id=${themeableId}&email=${user.email}&theme_version=${themeInfo.theme_version}`,
          })
        );
      }
    }
    return helpComponents;
  }, [
    activeTab,
    encoreGlobalSettingUrl,
    isEncore,
    themeInfo,
    themeableId,
    themeableType,
    user,
  ]);

  return (
    <div className="help-links list-group m-t-3">
      {items.map(({ Component, props }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Component {...props} key={`${Component.name}_${index}`} />
      ))}
    </div>
  );
};

HelpLinks.propTypes = {
  activeTab: PropTypes.string.isRequired,
  theme_info: PropTypes.shape({
    theme_version: PropTypes.string.isRequired,
  }).isRequired,
  themeable: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  themeableType: PropTypes.string.isRequired,
  user: PropTypes.shape({}).isRequired,
};
