import React from 'react';
import PropTypes from 'prop-types';

export const Header = ({ element }) => (
  <div
    className={`header-type ${
      element.style === 'subheading' ? 'subheading-type' : ''
    }`}
  >
    <h3 className="header">{element.content}</h3>
    <p className="help-block">
      {element.info_html || element.info}
    </p>
  </div>
);

Header.propTypes = {
  element: PropTypes.shape({
    style: PropTypes.string,
    content: PropTypes.string.isRequired,
    info: PropTypes.string,
    info_html: PropTypes.string,
  }).isRequired,
};
