import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import LabelTooltip from './LabelTooltip';

export const URL = ({ element, name, onChange, value }) => {
  function handleChange(e) {
    onChange(e.target.value);
  }

  return (
    <div className="theme-input">
      <LabelTooltip element={element} name={name} />
      <input
        className="form-control"
        name={name}
        onChange={handleChange}
        type="url"
        value={value}
      />
    </div>
  );
};

URL.defaultProps = {
  onChange: noop,
};

URL.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
};
