import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash/noop';

import LabelTooltip from './LabelTooltip';

const Radio = ({ element, name, value: inputValue, onChange }) => {
  const handleChange = (e) => {
    const newValue = e.currentTarget.querySelector('input').value;
    onChange(newValue);
  };

  const options = element.options.map(option => (
    <div
      className="radio"
      key={option.value}
      onClick={handleChange}
      onKeyUp={handleChange}
      tabIndex={0}
      role="button"
    >
      <label>
        <input
          id={element.id}
          type="radio"
          checked={inputValue === option.value}
          name={name}
          value={option.value}
          readOnly
        />
        {option.label}
      </label>
    </div>
  ));

  return (
    <div className="theme-input" id={element.id}>
      <LabelTooltip element={element} name={name} />
      {options}
    </div>
  );
};

Radio.defaultProps = {
  value: '',
  onChange: noop,
};

Radio.propTypes = {
  element: PropTypes.exact({
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    default: PropTypes.string,
    info: PropTypes.string,
    type: PropTypes.oneOf(['radio']).isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default memo(Radio);
