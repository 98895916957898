import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

import { useFilepicker } from '../InputTypes/hooks/useFilepicker';
import { HelpHover } from '../HelpHover';
import { ModalLoading } from '../ModalLoading';

const DEFAULT_PARAMS = {
  include: ['captions'],
};
const HELP_LINK = 'https://help.kajabi.com/hc/en-us/articles/360037062914';

export default function ClosedCaptionsModal({ config, onHide, show, videoId }) {
  const token = document.querySelector('meta[name="csrf-token"]').content;
  const axiosConfig = useMemo(
    () => ({
      responseType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
      },
    }),
    [token]
  );
  const [video, setVideo] = useState(null);
  const [selectedCaption, setSelectedCaption] = useState();

  const { selectFile } = useFilepicker({
    config,
    handleComplete: (file) => {
      const payload = {
        file_path: file.key
      };
      axios.post(`/admin/videos/${videoId}/captions`, payload, axiosConfig);
      onHide();
    },
  });

  const deleteCaption = (captionId) => {
    axios.delete(`/admin/captions/${captionId}`, axiosConfig);
    onHide();
  };

  const updateCaption = () => {
    axios.put(
      `/admin/captions/${selectedCaption.id}`,
      { ...selectedCaption },
      axiosConfig
    );
    onHide();
  };

  useEffect(() => {
    if (show) {
      axios
        .get(`/admin/videos/${videoId}`, { params: { ...DEFAULT_PARAMS } })
        .then(
          ({
            data: {
              data: { attributes: videoData },
            },
          }) => {
            setVideo(videoData);
          }
        );
    } else {
      setVideo(null);
      setSelectedCaption(null);
    }
  }, [show, videoId]);

  return (
    <Modal
      className="modal modal-dynamic modal-spinner fade in"
      onHide={onHide}
      show={show}
    >
      {video ? (
        <>
          <div className="modal-header">
            <div className="media media-overflow">
              <div className="media-body media-middle">
                <h3 className="modal-title">
                  {selectedCaption ? (
                    'Edit Closed Caption'
                  ) : (
                    <>
                      Closed Captions
                      <HelpHover link={HELP_LINK} />
                    </>
                  )}
                </h3>
              </div>
              <div className="media-right">
                <i
                  aria-label="Close"
                  className="mi modal-close"
                  onClick={onHide}
                  onKeyUp={onHide}
                  role="button"
                  tabIndex="0"
                >
                  close
                </i>
              </div>
            </div>
          </div>
          <div className="modal-body">
            {selectedCaption ? (
              <>
                <p className="help-block margin-bottom-lg">
                  Your captions for this video are in form of an{' '}
                  <a href={HELP_LINK} rel="noopener noreferrer" target="_blank">
                    SRT file
                  </a>
                  , which you can edit below.
                </p>
                <div className="form-group text optional caption_text">
                  <textarea
                    className="form-control text optional"
                    cols="5"
                    onChange={e =>
                      setSelectedCaption({
                        ...selectedCaption,
                        text: e.currentTarget.value,
                      })
                    }
                    rows="15"
                    value={selectedCaption.text}
                  />
                </div>
              </>
            ) : (
              <>
                {video.captions.map((caption) => {
                  const { id, language_name: languageName } = caption;
                  return (
                    <div className="row margin-bottom-lg" key={`caption-${id}`}>
                      <div className="col-md-12">
                        <div className="media">
                          <div className="media-left media-middle">
                            {languageName}
                          </div>
                          <div className="media-body" />
                          <div className="media-right media-middle nowrap">
                            <i
                              className="mi text-light margin-right-sm edit-caption-btn"
                              onClick={() => setSelectedCaption(caption)}
                              onKeyUp={() => setSelectedCaption(caption)}
                              role="button"
                              tabIndex="0"
                            >
                              create
                            </i>
                            <i
                              className="mi text-light destroy-caption-btn"
                              onClick={() => deleteCaption(id)}
                              onKeyUp={() => deleteCaption(id)}
                              role="button"
                              style={{ cursor: 'pointer' }}
                              tabIndex="0"
                            >
                              delete
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  as="button"
                  onClick={selectFile}
                  onKeyUp={selectFile}
                  role="button"
                  tabIndex="0"
                >
                  Add Closed Caption
                </a>
              </>
            )}
          </div>
          <div className="modal-footer">
            {selectedCaption ? (
              <div className="row">
                <label className="text-left col-sm-12">Select Language</label>
                <div className="col-sm-4">
                  <div className="form-group select optional caption_language_name">
                    <select
                      className="form-control select optional"
                      onChange={e =>
                        setSelectedCaption({
                          ...selectedCaption,
                          language_name: e.currentTarget.value,
                        })
                      }
                      value={selectedCaption.language_name}
                    >
                      {selectedCaption.available_language_names.map(
                        language => (
                          <option key={language}>{language}</option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-sm-8">
                  <button
                    className="btn btn-primary"
                    onClick={updateCaption}
                    type="button"
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : (
              <>
                <p className="help-block pull-left">
                  Upload an{' '}
                  <a href={HELP_LINK} rel="noopener noreferrer" target="_blank">
                    SRT file
                  </a>{' '}
                  to add a caption to your video.
                </p>
                <button
                  className="btn btn-primary pull-right btn-block-mobile"
                  onClick={onHide}
                  type="button"
                >
                  Save
                </button>
              </>
            )}
          </div>
        </>
      ) : (
        <ModalLoading />
      )}
    </Modal>
  );
}

ClosedCaptionsModal.propTypes = {
  config: PropTypes.shape({}).isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  videoId: PropTypes.string.isRequired,
};
