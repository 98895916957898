/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

export const Alert = ({ element }) => (
  <div className={`theme-input box-shadow alert-soft alert-soft-${element.alert_type}`}>
    <p className="alert-content">{element.content}</p>
    <div className="alert-html" dangerouslySetInnerHTML={{ __html: element.info_html }} />
  </div>
);

Alert.propTypes = {
  element: PropTypes.shape({
    alert_type: PropTypes.oneOf(['danger', 'default', 'info', 'primary', 'success', 'warning']),
    content: PropTypes.string.isRequired,
    info_html: PropTypes.string
  }).isRequired
};
