import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import LabelTooltip from './LabelTooltip';

export const Range = ({ element, name, onChange, value }) => {
  function handleChange(e) {
    onChange(e.target.value);
  }

  return (
    <div className="theme-input range">
      <LabelTooltip element={element} name={name} />
      <div className="media range-wrapper" define="range">
        <div className="media-body media-middle">
          <input
            className="range-slider"
            max={element.max}
            min={element.min}
            name={name}
            onChange={handleChange}
            step={element.step}
            type="range"
            value={value}
          />
        </div>
        <div className="media-right media-middle">
          <input
            className="range-input"
            max={element.max}
            min={element.min}
            name={name}
            onChange={handleChange}
            required
            type="number"
            value={value}
          />
        </div>
      </div>
    </div>
  );
};

Range.defaultProps = {
  onChange: noop,
  value: undefined,
};

Range.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
