export const isImage = (filepathOrUrl) => {
  if (!filepathOrUrl) {
    return false;
  }

  return filepathOrUrl.toLowerCase().match(/\.(jpeg|jpg|gif|png)\??/) != null;
};

export const fileName = (url, { fallbackName = '', sanitize = true } = {}) => {
  if (!url) {
    return fallbackName;
  }

  let filename;
  try {
    const pathname = decodeURIComponent(new URL(url).pathname);
    filename = pathname.split('/').pop();
    if (sanitize) {
      // removes starting hash (oo24RzSsCglRjqHHRi5g_Special_Offer.pdf -> Special_Offer.pdf)
      filename = filename.replace(/[^_]{20,}_/, '');
    }
  } catch (error) {
    /* eslint-disable no-console */
    if (process.env.NODE_ENV === 'development') {
      console.log(error);
    }
  }

  return filename || fallbackName;
};

export const urlFor = (config, value) => {
  if (!value || !config) {
    return '';
  }

  if (value.includes(config.s3Url)) {
    return value;
  }

  return `${config.s3Url}/${value}`;
};
