import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccordionBootstrap from 'react-bootstrap/Accordion';

import { InputType } from '../../helpers/inputGenerator';

export default function Accordion({
  blockId,
  icon,
  inputPrefix,
  items,
  onChange,
  paths,
  sectionId,
  settings,
  theme,
  title,
}) {
  const [openSection, setOpenSection] = useState();

  if (items.length) {
    return (
      <div className="settings-accordion-wrapper">
        {title && (
          <div className="settings-accordion-label">
            {title}
            {icon && <i className="mi">{icon}</i>}
          </div>
        )}
        <AccordionBootstrap
          activeKey={openSection}
          className="settings-accordion panel"
        >
          {items.map(({ id, group, title: itemTitle }) => (
            <React.Fragment key={id}>
              <AccordionBootstrap.Toggle
                as="a"
                className={`settings-accordion-trigger ${
                  openSection === id ? '' : 'collapsed'
                }`}
                onClick={() => setOpenSection(openSection === id ? null : id)}
                variant="link"
                eventKey={id}
              >
                {itemTitle}
              </AccordionBootstrap.Toggle>
              <AccordionBootstrap.Collapse eventKey={id}>
                <>
                  {group.elements.map((element, idx) => (
                    <InputType
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${element.type}_${idx}`}
                      type={element.type}
                      element={element}
                      settings={settings}
                      theme={theme}
                      inputPrefix={inputPrefix}
                      onChange={onChange}
                      paths={paths}
                      sectionId={sectionId}
                      blockId={blockId}
                    />
                  ))}
                </>
              </AccordionBootstrap.Collapse>
            </React.Fragment>
          ))}
        </AccordionBootstrap>
      </div>
    );
  }

  return null;
}

Accordion.propTypes = {
  blockId: PropTypes.string,
  icon: PropTypes.string,
  inputPrefix: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      group: PropTypes.shape({}).isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  paths: PropTypes.shape({}).isRequired,
  sectionId: PropTypes.string.isRequired,
  settings: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
};

Accordion.defaultProps = {
  blockId: undefined,
  icon: undefined,
  title: undefined,
};
