import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import noop from 'lodash/noop';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import { Editor } from '@tinymce/tinymce-react-theme-editor';

import { useHotKeys } from '../../../../helpers/useHotKeys';
import useTinyMce from '../../../../helpers/useTinyMce';

import { useFilepicker } from './hooks/useFilepicker';

import LabelTooltip from './LabelTooltip';

const EMAIL_EDITOR = 'email';
const ENCORE_EDITOR = 'encore';
const SIMPLE_EDITOR = 'simple';

const LiquidTagInfo = ({ fields }) => {
  const [collapsed, setCollapsed] = useState(true);

  const handleFields = () => setCollapsed(!collapsed);

  return (
    <div className="help-block">
      <p>You can use liquid objects in the body of your email broadcast.</p>
      <div
        onClick={handleFields}
        onKeyUp={handleFields}
        role="link"
        tabIndex={0}
      >
        <p className="help-text-expander">
          <i className={`mi expand_${collapsed ? 'more' : 'less'}`} />
          {collapsed ? 'Show' : 'Hide'} supported liquid objects
        </p>
      </div>
      {!collapsed && (
        <dl className="dl-spaced">
          {fields.map(({ text, value }) => (
            <React.Fragment key={value}>
              <dt>{text}</dt>
              <dd>{value}</dd>
            </React.Fragment>
          ))}
        </dl>
      )}
    </div>
  );
};

LiquidTagInfo.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const RichText = ({
  element,
  name,
  onChange,
  paths: { tinymceContentPath },
  theme: {
    attributes: {
      colorOptions,
      fields,
      filepickerOptions,
      settings,
      themeableType,
    },
  },
  value,
}) => {
  const editorRef = useRef();
  const { selectFile } = useFilepicker({
    config: filepickerOptions.download,
    handleComplete: (file) => {
      const url = `${filepickerOptions.download.s3Url}/${file.key}`;
      editorRef.current.editor.editorCommands.execCommand(
        'mceInsertContent',
        false,
        `<a href="${url}">Download now</a>`
      );
    },
  });
  const hotKeysListener = useHotKeys();

  const {
    addAlignmentSplit,
    addCustomFields,
    addFilePicker,
    defaultOptions,
  } = useTinyMce(fields);

  const contentStyles = useMemo(
    () =>
      compact(
        Object.keys(settings).map((key) => {
          const match = key.match(/font_size_(.*)_desktop/);
          if (!match) {
            return null;
          }
          const id = match[1] === 'body' ? 'p, li, pre, blockquote' : match[1];
          return `${id} { font-size: ${settings[key]}; }`;
        })
      ).join(' '),
    [settings]
  );

  const toolbarOptions = useMemo(() => {
    switch (element.style) {
      case SIMPLE_EDITOR:
        return 'bold italic underline link | fullscreen';
      case EMAIL_EDITOR:
        return 'code | bold italic strikethrough | fontsizeselect forecolor | alignleft aligncenter alignright alignjustify | formatselect | bullist numlist | customFieldsCombo | link filepicker_file | fullscreen';
      case ENCORE_EDITOR:
        return 'code | bold italic strikethrough link | alignleft aligncenter alignright alignjustify | fontsizeselect forecolor | formatselect bullist numlist | fullscreen';
      default:
        return undefined;
    }
  }, [element]);

  const addPlugins = useCallback(
    ({ editorManager }) => {
      addAlignmentSplit(editorManager);
      addCustomFields(editorManager);
      addFilePicker(editorManager);
    },
    [addAlignmentSplit, addCustomFields, addFilePicker]
  );

  const editorOptions = useMemo(() => {
    let options = {
      height: 150,
      content_css: tinymceContentPath,
      content_style: contentStyles,
      selector: 'textarea',
    };

    if (element.style !== SIMPLE_EDITOR) {
      options = merge(options, colorOptions);
    }

    if ((element.style || '').length) {
      options.toolbar = toolbarOptions;
    }

    if ([EMAIL_EDITOR, ENCORE_EDITOR].includes(element.style)) {
      options.fontsize_formats = '8px 10px 12px 14px 16px 18px 21px 24px 28px 32px 36px 42px 48px 56px 64px 72px 80px 88px 96px 104px 120px 144px';
    }

    if (element.style === EMAIL_EDITOR) {
      options.block_formats = 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6;';
      options.setup = addPlugins;
      options.plugins = 'customFieldsPlugin lists fullscreen colorpicker code textcolor alignmentsplit link autolink customLink buttonLink filepicker_file';
      options.selectFile = selectFile;
    }

    return merge(defaultOptions, options);
  }, [
    addPlugins,
    colorOptions,
    contentStyles,
    defaultOptions,
    element.style,
    selectFile,
    tinymceContentPath,
    toolbarOptions,
  ]);

  const handleNodeChange = ({ selectionChange, target }) => {
    if (selectionChange) {
      const content = target.getContent();
      if (!isEqual(value, content)) {
        onChange(content);
      }
    }
  };

  return (
    <div className="theme-input">
      <LabelTooltip element={element} name={name} />

      <Editor
        className="form-control"
        init={editorOptions}
        value={value}
        onEditorChange={onChange}
        onBeforeAddUndo={() => false}
        onNodeChange={handleNodeChange}
        onKeyDown={hotKeysListener}
        ref={editorRef}
        rollback={false}
      />

      {[
        'EmailBroadcast',
        'EmailSequenceEmail',
        'MarketingEmailTemplate',
      ].includes(themeableType) && <LiquidTagInfo fields={fields} />}
    </div>
  );
};

RichText.defaultProps = {
  onChange: noop,
  value: '',
};

RichText.propTypes = {
  element: PropTypes.exact({
    default: PropTypes.string,
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    style: PropTypes.string,
    type: PropTypes.oneOf(['rich_text']).isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  paths: PropTypes.shape({
    tinymceContentPath: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    attributes: PropTypes.shape({
      colorOptions: PropTypes.shape({}).isRequired,
      fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      filepickerOptions: PropTypes.shape({}).isRequired,
      settings: PropTypes.shape({}).isRequired,
      themeableType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  value: PropTypes.string,
};

export default RichText;
