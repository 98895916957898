import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  SageTokens,
  SageClassnames,
} from '@kajabi/sage-react';

import {
  useUiContext,
  useSettingsContext,
} from 'common/components/ContentEditor/contexts';

const ToolbarInline = ({
  toolbarChildren,
  children
}) => {
  const { setIsFullscreen } = useUiContext();
  const { label } = useSettingsContext();

  return (
    <div className="kjb-content-editor-toolbar-inline">
      <div className={
        `kjb-content-editor-toolbar-inline__header
        ${SageClassnames.GRID_TEMPLATES.TI}`}
      >
        <span className={`
          ${SageClassnames.TYPE.BODY_SMALL}
        `}
        >
          {label}
        </span>
        <div>
          {toolbarChildren}
        </div>
        <Button
          subtle={true}
          icon={SageTokens.ICONS.FULLSCREEN}
          iconOnly={true}
          onClick={() => setIsFullscreen(true)}
        >
          Expand
        </Button>
      </div>
      {children}
    </div>
  );
};

ToolbarInline.propTypes = {
  toolbarChildren: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default ToolbarInline;
