import React from 'react';
import PropTypes from 'prop-types';
import {
  useUiContext,
} from 'common/components/ContentEditor/contexts';

import BubbleCallToAction from './BubbleCallToAction';
import BubbleImage from './BubbleImage';
import BubbleLink from './BubbleLink';

const BubbleMenu = ({ editor }) => {
  const { setDialog } = useUiContext();
  const props = { setDialog, editor };

  return (
    <>
      <BubbleCallToAction {...props} />
      <BubbleImage {...props} />
      <BubbleLink {...props} />
    </>
  );
};

BubbleMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default BubbleMenu;
