import clientInfo from 'common/lib/clientInfo';

const IS_TEST_ENV = (clientInfo.get('env') === 'test');

export const VALUE_TYPES = {
  HTML: 'html',
  TEXT: 'text',
  JSON: 'json',
};

export const CONTENT_EDITOR_ON_CHANGE_DEBOUNCE_CONFIG = (
  IS_TEST_ENV
    ? [0]
    : [500, { trailing: true, maxWait: 5000 }]
);

export const TIPTAP_EDITOR_CONTEXT_DEBOUNCE_CONFIG = (
  IS_TEST_ENV
    ? [0, { leading: true, trailing: true }]
    : [250, { leading: true, trailing: true, maxWait: 500 }]
);
