import * as actionTypes from './actionTypes';

export function addVideo(payload) {
  return {
    type: actionTypes.ADD_VIDEO,
    payload,
  };
}

export function changeSidebarTab(payload) {
  return {
    type: actionTypes.UPDATE_SELECTED_SIDEBAR_TAB,
    payload,
  };
}

export function setDisableIframePointerEvents(payload) {
  return {
    type: actionTypes.SET_DISABLE_IFRAME_POINTER_EVENTS,
    payload,
  };
}

export function setFlashMessage(payload) {
  return {
    type: actionTypes.SET_FLASH_MESSAGE,
    payload,
  };
}

export function setToastMessage(payload) {
  return {
    type: actionTypes.SET_TOAST_MESSAGE,
    payload,
  };
}

export function setReordering(payload) {
  return {
    type: actionTypes.SET_REORDERING,
    payload,
  };
}

export function setSearch(payload) {
  return {
    type: actionTypes.SET_SEARCH,
    payload,
  };
}

export function setThirdPartyCookiesEnabled(payload) {
  return {
    type: actionTypes.SET_THIRD_PARTY_COOKIES_ENABLED,
    payload,
  };
}

export function setViewPort(payload) {
  return {
    type: actionTypes.SET_VIEWPORT,
    payload,
  };
}
