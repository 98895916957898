import React from 'react';

// List of default colors came from
// app/assets/javascripts/admin/colorpicker.js
const DEFAULT_COLOR_PALETTE = [
  '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e',
  '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50',
  '#f1c40f', '#e67e22', '#e74c3c', '#ecf0f1', '#95a5a6',
  '#000000', '#ffffff',
];

const ThemeContext = React.createContext({
  colorPalette: DEFAULT_COLOR_PALETTE
});

export default ThemeContext;
