import { datadogRum } from '@datadog/browser-rum';

export const initializeDatadogRum = ({
  applicationId = '8dca32bf-6fd4-41ad-8dc8-48410773bf4f', // Default application Kajabi Admin
  clientToken = 'pube4fa394886c79879ca1ef71d7ddc353b',
  serviceName = 'kajabi-admin',
  trackViewsManually = false,
  version = '1.0.0',
}) => {
  const otherConfig = {
    env: window.CLIENT_INFO.env,
    defaultPrivacyLevel: 'mask-user-input',
    site: 'datadoghq.com',
    sampleRate: window.CLIENT_INFO.env === 'test' ? 0 : 100,
    trackInteractions: true,
    trackViewsManually,
    version,
  };

  datadogRum.init({
    applicationId,
    clientToken,
    service: serviceName,
    ...otherConfig,
  });

  datadogRum.setUser({
    id: window.CLIENT_INFO.user_id,
    name: window.CLIENT_INFO.name,
    email: window.CLIENT_INFO.email,
    plan: window.CLIENT_INFO.account_plan_name,
    siteId: window.CLIENT_INFO.site_id,
  });
};

export { datadogRum };
