import React from 'react';
import PropTypes from 'prop-types';

export const LoaderContainer = ({ left, top, children }) => (
  <div style={{ position: 'relative', height: '100vh' }}>
    <div style={{ position: 'absolute', left, top, transform: 'translate(-50%, -50%)' }}>
      {children}
    </div>
  </div>
);

LoaderContainer.propTypes = {
  left: PropTypes.string,
  top: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LoaderContainer.defaultProps = {
  left: '50%',
  top: '50%',
};
