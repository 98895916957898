import React from 'react';
import PropTypes from 'prop-types';

import { IconCard } from '@kajabi/sage-react';
import { isImage } from 'common/components/FilePicker/utils';


const thumbnailStyles = {
  border: 'none',
  padding: 0,
  width: 'auto'
};

const PreviewCard = ({ value, previewUrl }) => {
  if (!value) {
    return null;
  }

  if (isImage(value)) {
    return (
      <div className="sage-upload-card__preview">
        <img
          alt=""
          src={previewUrl}
          className="img-thumbnail"
          style={thumbnailStyles}
        />
      </div>
    );
  }

  return (
    <IconCard
      className="sage-upload-card__preview"
      color={IconCard.COLORS.DRAFT}
      icon={IconCard.ICONS.FILE}
      size={IconCard.SIZES['4XL']}
    />
  );
};

PreviewCard.propTypes = {
  previewUrl: PropTypes.string,
  value: PropTypes.string
};

PreviewCard.defaultProps = {
  previewUrl: null,
  value: null
};

export default PreviewCard;
