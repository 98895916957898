import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import kebabCase from 'lodash/kebabCase';

export const Grid = ({ element, name, onChange, value }) => {
  const items = [];
  const prefix = kebabCase(name);

  for (let i = 12; i >= 1; i -= 1) {
    const kebabName = `${prefix}-${i}-input`;
    items.push(
      <Fragment key={i}>
        <input
          checked={parseInt(value, 10) === i}
          id={kebabName}
          name={name}
          onChange={e => onChange(e.target.value)}
          type="radio"
          value={i}
        />
        <label htmlFor={kebabName} />
      </Fragment>
    );
  }

  return (
    <div className="theme-input grid" define="width">
      <div className="grid-select-label">
        {element.label}
        <div className="grid-select-value">
          <strong>{value}</strong> columns
        </div>
      </div>
      <div className="grid-select">{items}</div>
      {element.info_html}
    </div>
  );
};

Grid.defaultProps = {
  onChange: noop,
};

Grid.propTypes = {
  element: PropTypes.shape({
    label: PropTypes.string.isRequired,
    info_html: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
};
