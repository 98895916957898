import { useContext } from 'react';

import { SaveThemeContext } from '../components/ThemeEditorContainer/components/EditorWatcher';

import { useUndoRedo } from './useUndoRedo';

const S_KEY = 83;
const Z_KEY = 90;

export function useHotKeys() {
  const { saveTheme } = useContext(SaveThemeContext);

  const { handleRedo, handleUndo } = useUndoRedo();

  const hotKeysListener = (e) => {
    const { which, metaKey, ctrlKey, shiftKey } = e;
    if ([Z_KEY, S_KEY].includes(which) && (metaKey || ctrlKey)) {
      e.preventDefault();

      if (which === S_KEY) {
        saveTheme();
      } else {
        (shiftKey ? handleRedo : handleUndo)();
      }
    }
  };

  return hotKeysListener;
}
