import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import { saveAs } from 'file-saver';

import {
  updateBlockSettings,
  updateGlobalSettings,
  updateSectionSettings,
  updateHasRecentMedias,
} from '../../../../redux/theme/actions';
import {
  addVideo,
  setFlashMessage,
  setToastMessage,
} from '../../../../redux/ui/actions';

import { ClosedCaptionsModal } from '../ClosedCaptionsModal';
import { RecentMediaModal } from '../RecentMediaModal';
import { VideoStatsModal } from '../VideoStatsModal';
import { WistiaPlayer } from '../WistiaPlayer';

import LabelTooltip from './LabelTooltip';
import FilepickerButton from './FilepickerButton';

const CLOSED_CAPTIONS = 'CLOSED_CAPTIONS';
const RECENT_VIDEO = 'RECENT_VIDEO';
const VIDEO_STATS = 'VIDEO_STATS';

function buildHeaders() {
  return {
    responseType: 'json',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    },
  };
}

function updateStore({
  dispatch,
  video,
  blockId,
  sectionId,
  attribute,
  value,
}) {
  dispatch(addVideo(video));
  dispatch(updateHasRecentMedias({ type: 'videos', value: true }));
  if (blockId) {
    dispatch(
      updateBlockSettings({
        id: blockId,
        sectionId,
        attribute,
        value,
      })
    );
  } else if (sectionId) {
    dispatch(updateSectionSettings({ id: sectionId, attribute, value }));
  } else {
    dispatch(updateGlobalSettings({ attribute, value }));
  }
}

function copyVideo({ blockId, sectionId, attribute, siteId, videoId }) {
  return (dispatch) => {
    dispatch(setToastMessage('Copying your video...'));
    axios
      .post(
        `/admin/sites/${siteId}/copy_video`,
        {
          video: {
            video_id: videoId,
          },
        },
        buildHeaders()
      )
      .then(
        ({
          data: {
            resource: { data },
          },
        }) => {
          updateStore({
            dispatch,
            video: data,
            blockId,
            sectionId,
            attribute,
            value: data.id,
          });
        }
      )
      .catch(() => {
        dispatch(setFlashMessage({ error: 'Something went wrong' }));
      })
      .finally(() => {
        dispatch(setToastMessage(null));
      });
  };
}

function createVideo({ blockId, key, sectionId, siteId, attribute, themeId }) {
  return (dispatch) => {
    axios
      .post(
        `/admin/sites/${siteId}/videos`,
        {
          video: {
            original_video: key,
            videoable_type: 'Theme',
            videoable_id: themeId,
          },
        },
        buildHeaders()
      )
      .then(({ data: { data } }) => {
        updateStore({
          dispatch,
          video: data,
          blockId,
          sectionId,
          attribute,
          value: data.id,
        });
      })
      .catch(() => {
        dispatch(
          setFlashMessage({ error: 'Error saving uploaded file reference.' })
        );
      });
  };
}

export function Video({
  blockId,
  element,
  name,
  paths: { mediaPlaceholderPath },
  sectionId,
  theme: {
    id: themeId,
    attributes: { filepickerOptions, hasRecentMedias, siteId },
  },
  value,
}) {
  const [modalView, setModalView] = useState();
  const [refreshVideo, setRefreshVideo] = useState(false);
  const { videos } = useSelector(state => state.ui);
  const dispatch = useDispatch();

  const selectedVideo = useMemo(
    () => videos.find(v => String(v.id) === String(value)),
    [value, videos]
  );

  const onSelectFile = async ({ key }) => {
    dispatch(
      createVideo({
        siteId,
        key,
        blockId,
        sectionId,
        attribute: element.id,
        themeId,
      })
    );
  };

  const downloadVideo = useCallback(async () => {
    const {
      data: { signed_url: signedUrl },
    } = await axios.get(`/admin/videos/${selectedVideo.id}/download`);
    saveAs(signedUrl, selectedVideo.attributes.originalVideo);
  }, [selectedVideo]);

  const resetModal = () => {
    setModalView(null);
  };

  const storeSelectedVideo = (video) => {
    dispatch(
      copyVideo({
        siteId,
        videoId: video.id,
        blockId,
        sectionId,
        attribute: element.id,
      })
    );
    resetModal();
  };

  useEffect(() => {
    setRefreshVideo(false);

    if (value && !selectedVideo) {
      axios
        .get(`/admin/videos/${value}`)
        .then(({ data: { data } }) => {
          dispatch(addVideo(data));
        })
        .catch(noop);
    }
  }, [dispatch, selectedVideo, value]);

  return (
    <div className="theme-input">
      <LabelTooltip element={element} name={name} />
      <div className="media-preview embed-responsive embed-responsive-16by9">
        {selectedVideo ? (
          <WistiaPlayer
            refreshing={refreshVideo}
            wistiaId={selectedVideo.attributes.wistiaId}
          />
        ) : (
          <img
            id="poster-video-preview"
            className="embed-responsive-item media-preview"
            src={mediaPlaceholderPath}
            alt="Upload video placeholder"
          />
        )}
      </div>
      {hasRecentMedias.videos ? (
        <div className="select-asset-dropdown dropdown">
          <Dropdown>
            <Dropdown.Toggle
              as="button"
              className="btn btn-primary btn-outline dropdown-toggle"
            >
              Video Actions<i className="mi icon-caret">arrow_drop_down</i>
            </Dropdown.Toggle>
            <Dropdown.Menu as="ul" className="dropdown-menu">
              {selectedVideo && (
                <>
                  <li>
                    <Dropdown.Item onClick={() => setModalView(VIDEO_STATS)}>
                      Video Stats
                    </Dropdown.Item>
                  </li>
                  <li>
                    <Dropdown.Item
                      onClick={() => setModalView(CLOSED_CAPTIONS)}
                    >
                      Closed Captions
                    </Dropdown.Item>
                  </li>
                  <li>
                    <Dropdown.Item onClick={downloadVideo}>
                      Download Video
                    </Dropdown.Item>
                  </li>
                  <VideoStatsModal
                    onHide={resetModal}
                    show={modalView === VIDEO_STATS}
                    videoId={selectedVideo.id}
                  />
                  <ClosedCaptionsModal
                    config={filepickerOptions.caption}
                    onHide={resetModal}
                    show={modalView === CLOSED_CAPTIONS}
                    videoId={selectedVideo.id}
                  />
                </>
              )}
              <li>
                <FilepickerButton
                  additionalClassNames="fp-input"
                  config={filepickerOptions.video}
                  handleSelect={onSelectFile}
                  isLink
                  text="Upload a New File"
                />
              </li>
              <li>
                <Dropdown.Item onClick={() => setModalView(RECENT_VIDEO)}>
                  Select a Recent File
                </Dropdown.Item>
              </li>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        <FilepickerButton
          additionalClassNames="btn-default btn-block"
          config={filepickerOptions.video}
          handleSelect={onSelectFile}
          text="Add video"
        />
      )}
      <RecentMediaModal
        onHide={resetModal}
        onSubmit={storeSelectedVideo}
        remoteUrl={`/admin/sites/${siteId}/videos`}
        show={modalView === RECENT_VIDEO}
        title="Recent Videos"
      />
    </div>
  );
}

Video.defaultProps = {
  blockId: undefined,
  sectionId: undefined,
  value: '',
};

Video.propTypes = {
  blockId: PropTypes.string,
  element: PropTypes.exact({
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  paths: PropTypes.shape({
    mediaPlaceholderPath: PropTypes.string.isRequired,
  }).isRequired,
  sectionId: PropTypes.string,
  theme: PropTypes.shape({
    attributes: PropTypes.shape({
      filepickerOptions: PropTypes.shape({
        video: PropTypes.shape({}).isRequired,
      }).isRequired,
      hasRecentMedias: PropTypes.shape({
        videos: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
