import React from 'react';
import PropTypes from 'prop-types';

const Recommendation = ({ children }) => {
  if (!children) {
    return null;
  }
  return (
    <p className="sage-upload-card__subtext">
      {children}
    </p>
  );
};

Recommendation.propTypes = {
  children: PropTypes.node,
};

Recommendation.defaultProps = {
  children: null
};

export default Recommendation;
