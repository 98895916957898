import { useCallback, useRef, useEffect, useMemo } from 'react';
import { useEditor } from '@tiptap/react';
import { isEqual, debounce } from 'lodash';

import { VALUE_TYPES, CONTENT_EDITOR_ON_CHANGE_DEBOUNCE_CONFIG } from '../constants';
import { selectExtensions } from '../extensions';


function getEditorValue(editor, valueType) {
  switch (valueType) {
    case VALUE_TYPES.HTML: return editor.getHTML();
    case VALUE_TYPES.TEXT: return editor.getText();
    case VALUE_TYPES.JSON: return editor.getJSON();
    default: return null;
  }
}

export default function useTiptapEditor({
  value,
  valueType,
  onChange,
  extensionNames,
}) {
  // It can be expensive to generate editor HTML, we cache it for better performance
  const valueCache = useRef();
  const extensions = useMemo(() => selectExtensions(extensionNames), [extensionNames]);

  const handleUpdate = useCallback(debounce((event) => {
    const newValue = getEditorValue(event.editor, valueType);
    valueCache.current = newValue;
    onChange(newValue);
  }, ...CONTENT_EDITOR_ON_CHANGE_DEBOUNCE_CONFIG), [onChange, valueType]);

  const editor = useEditor({
    content: value,
    onUpdate: handleUpdate,
    extensions,
  }, []);

  // On `value` prop update handler:
  //   If `valueCache` and new `value` prop are equal skip setting the editor content,
  //   otherwise, update the editor's content and cache the latest `value`
  useEffect(() => {
    if (!editor) return;
    if (isEqual(valueCache.current, value)) return;

    const prevSelection = editor.state.selection;
    editor
      .chain()
      .setContent(value, false)
      .setTextSelection(prevSelection)
      .run();
    valueCache.current = value;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return editor;
}
