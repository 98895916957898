import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export function Tooltip({ children, placement, text }) {
  return (
    <OverlayTrigger
      overlay={({ arrowProps, show, style, ...rest }) => (
        <BootstrapTooltip
          {...rest}
          show={show.toString()}
          className={`in ${placement}`}
          style={{ ...style, maxWidth: 200 }}
        >
          <>
            <div className="tooltip-arrow" {...arrowProps} />
            {text}
          </>
        </BootstrapTooltip>
      )}
      placement={placement}
    >
      {children}
    </OverlayTrigger>
  );
}

Tooltip.defaultProps = {
  placement: 'top',
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  text: PropTypes.string.isRequired,
};
