import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import useQuery from '../../../../helpers/useQuery';

export const Settings = ({
  groups,
  theme_info: themeInfo,
}) => {
  const queryParams = useQuery();
  const { pathname: url } = useLocation();

  return (
    <>
      <ul className="nav nav-pills nav-stacked nav-pills-settings">
        {groups.map(group => (
          <li key={group.name}>
            <Link to={`${url}/global/${group.name.replace(/\s/g, '')}${queryParams}`}>
              {group.name}
            </Link>
          </li>
        ))}
      </ul>
      {
        themeInfo
        && (
        <div className="settings-info">
          <strong className="settings-info-item">{themeInfo.theme_name} ({themeInfo.theme_version})</strong>
          <div className="settings-info-item">Design and support by: <a className="settings-info-contact" href={themeInfo.theme_support_url} target="_blank" rel="noopener noreferrer">{themeInfo.theme_author}</a></div>
          <div className="settings-info-spacer" />
          {themeInfo.theme_documentation_url && (<a className="settings-info-item" href={themeInfo.theme_documentation_url} target="_blank" rel="noopener noreferrer">Read documentation</a>)}
          {themeInfo.theme_support_url && (<a className="settings-info-item" href={themeInfo.theme_support_url} target="_blank" rel="noopener noreferrer">Get Support</a>)}
        </div>
        )
      }
    </>
  );
};

Settings.defaultProps = {
  theme_info: {},
};

Settings.propTypes = {
  groups: PropTypes.instanceOf(Array).isRequired,
  theme_info: PropTypes.shape({}),
};
