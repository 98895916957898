import React from 'react';
import PropTypes from 'prop-types';

import FilepickerButton from '../FilepickerButton';

export const ActionDownloadButton = ({
  config: { settings_download: config },
  isExternal,
  onChange,
}) => {
  const onSelectFile = ({ key }) => {
    const source = isExternal
      ? `${config.s3Url}/${key}`
      : `/resource_redirect/downloads/${key}`;
    onChange(source);
  };

  return (
    <FilepickerButton
      additionalClassNames="btn btn-default btn-block"
      config={config}
      handleSelect={onSelectFile}
      text="Pick File"
    />
  );
};

ActionDownloadButton.propTypes = {
  config: PropTypes.shape({
    settings_download: PropTypes.shape({
      s3Url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isExternal: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
