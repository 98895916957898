import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import { MemoryRouter as Router } from 'react-router-dom';
import { configureStore } from 'apps/admin/themes/editor';
import { initializeSentry } from 'common/components/Sentry';
import { datadogRum, initializeDatadogRum } from 'common/components/Datadog/Datadog';
import { ThemeEditorContainer } from '../../../apps/admin/themes/editor/components/ThemeEditorContainer';
import { fetchThemeSettings } from '../../../apps/admin/themes/editor/redux/api/actions';

const renderThemeEditorContainer = () => {
  const container = document.getElementById('theme-editor-container');
  if (!container) return;

  const store = configureStore();

  const themeId = window.location.pathname.match(/\/admin\/themes\/(\d*)/)[1];
  const searchParams = new URLSearchParams(window.location.search);
  const themeFileId = searchParams.get('theme_file_id');
  store.dispatch(fetchThemeSettings({ themeId, themeFileId }));

  initializeSentry({ tags: { team: 'Themes', component: 'theme-editor' } });

  initializeDatadogRum({
    serviceName: 'react-theme-editor',
    trackViewsManually: true,
  });

  datadogRum.startSessionReplayRecording();

  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <Provider store={store}>
        <Router initialEntries={[{ pathname: `/admin/themes/${themeId}/settings/edit` }]}>
          <ThemeEditorContainer />
        </Router>
      </Provider>
    </Sentry.ErrorBoundary>,
    container
  );
};

document.addEventListener('DOMContentLoaded', () => {
  renderThemeEditorContainer();
});
