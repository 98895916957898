import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import {
  selectSection,
  selectSectionSchema,
} from '../../../../redux/theme/selectors';
import useAddBlock from '../../../../helpers/useAddBlock';
import { useEmptySectionOrBlock } from '../../../../helpers/useEmptySectionOrBlock';

import { AddBlockButton } from './AddBlockButton';

export function AddBlockPanel() {
  const {
    params: { sectionId },
  } = useRouteMatch();
  const addBlock = useAddBlock(sectionId);
  useEmptySectionOrBlock({ sectionId });

  const section = useSelector(state => selectSection(state, sectionId), shallowEqual) || {};
  const { blocks } = useSelector(
    state => selectSectionSchema(state, section.type),
    shallowEqual
  ) || {};

  if (isEmpty(blocks)) {
    return null;
  }

  return (
    <div className="form-section add-block">
      <div className="sidenav-blocks">
        <div className="form-group draggable-sections">
          <div className="dropup open">
            <div className="dropdown-menu blocks-menu">
              {sortBy(blocks, 'name').map(block => (
                <AddBlockButton
                  block={block}
                  handleClick={() => addBlock(block)}
                  key={block.name}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
