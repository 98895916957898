import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  SageTokens,
} from '@kajabi/sage-react';
import { EXTENSION_NAMES } from 'common/components/ContentEditor/extensions';

const ToolHorizontalRule = ({ editor }) => (
  <Button
    iconOnly={true}
    color={Button.COLORS.SECONDARY}
    icon={SageTokens.ICONS.HORIZONTAL_LINE}
    onClick={() => editor
      .chain()
      .focus()
      .setHorizontalRule()
      .run()}
    disabled={!editor.can().setHorizontalRule()}
    className={editor.isActive(EXTENSION_NAMES.HORIZONTAL_RULE) && 'sage-btn--active'}
  >
      Horizontal Rule
  </Button>
);

ToolHorizontalRule.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolHorizontalRule;
