import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import LabelTooltip from './LabelTooltip';

export const Select = ({ element, name, value, onChange }) => {
  const options = element.options.map(option => (
    <option
      value={option.value}
      key={`${name}_${option.value}_${option.label}`}
    >
      {option.label}
    </option>
  ));

  return (
    <div className="theme-input" id={element.id}>
      <LabelTooltip element={element} name={name} />
      <select
        className="form-control"
        onChange={e => onChange(e.target.value)}
        value={value}
      >
        {options}
      </select>
    </div>
  );
};

Select.defaultProps = {
  value: '',
  onChange: noop,
};

Select.propTypes = {
  element: PropTypes.exact({
    default: PropTypes.string,
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    preset: PropTypes.string,
    type: PropTypes.oneOf(['select']).isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
