import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@kajabi/sage-react';

import { EXTENSION_NAMES } from '../extensions';
import { VALUE_TYPES } from '../constants';
import useTiptapEditor from '../hooks/useTiptapEditor';

const TiptapEditorContext = React.createContext({});

const useTiptapEditorContext = () => {
  const context = React.useContext(TiptapEditorContext);

  if (context === undefined) {
    throw new Error('useTiptapEditorContext was used outside of its Provider');
  }

  return context;
};

const TiptapEditorProvider = ({
  value,
  valueType,
  onChange,
  extensionNames,
  children,
}) => {
  const editor = useTiptapEditor({
    value,
    valueType,
    onChange,
    extensionNames,
  });

  if (!editor) return <Loader loading={true} type={Loader.TYPES.SPINNER} />;

  return (
    <TiptapEditorContext.Provider value={{ editor }}>
      {children}
    </TiptapEditorContext.Provider>
  );
};

TiptapEditorProvider.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  extensionNames: PropTypes.arrayOf(PropTypes.oneOf(Object.values(EXTENSION_NAMES))).isRequired,
  valueType: PropTypes.oneOf(Object.values(VALUE_TYPES)).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export { TiptapEditorProvider, useTiptapEditorContext };
