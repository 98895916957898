import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  SageTokens,
} from '@kajabi/sage-react';
import { EXTENSION_NAMES } from 'common/components/ContentEditor/extensions';

const ToolStrike = ({ editor }) => (
  <Button
    iconOnly={true}
    color={Button.COLORS.SECONDARY}
    icon={SageTokens.ICONS.STRIKETHROUGH}
    onClick={() => editor
      .chain()
      .focus()
      .extendMarkRange(EXTENSION_NAMES.STRIKE)
      .toggleStrike()
      .run()}
    disabled={!editor.can().toggleStrike()}
    className={editor.isActive(EXTENSION_NAMES.STRIKE) && 'sage-btn--active'}
  >
      Strike-through
  </Button>
);

ToolStrike.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolStrike;
