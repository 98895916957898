/* eslint-disable no-undef */
import { useCallback, useMemo } from 'react';
import noop from 'lodash/noop';

export function useFilepicker({ config, handleComplete, handleReset = noop }) {
  const client = useMemo(() => {
    const nonExtensibleConfig = JSON.parse(JSON.stringify(config));
    return new window.App.FilepickerUpload(nonExtensibleConfig);
  }, [config]);

  const convertFile = useCallback(
    (fileUrl) => {
      client
        .storeUrl(fileUrl)
        .then(client.processImage.bind(client), noop)
        .then(client.store.bind(client), noop)
        .then(client.convert.bind(client), noop)
        .then(handleComplete, noop)
        .always(handleReset);
    },
    [client, handleComplete, handleReset]
  );

  const selectFile = useCallback(() => {
    client
      .pick()
      .then(client.store.bind(client), noop)
      .then(client.convert.bind(client), noop)
      .then(handleComplete, noop)
      .always(handleReset);
  }, [client, handleComplete, handleReset]);

  return { convertFile, selectFile };
}
