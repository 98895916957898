import React from 'react';
import {
  Button,
} from '@kajabi/sage-react';

import {
  useUiContext,
} from 'common/components/ContentEditor/contexts';
import { DIALOG_HTML_EDITOR } from 'common/components/ContentEditor/components/Dialog';

const ToolHtmlEditor = () => {
  const { setDialog } = useUiContext();

  return (
    <Button
      color={Button.COLORS.SECONDARY}
      onClick={() => setDialog(DIALOG_HTML_EDITOR)}
    >
      HTML
    </Button>
  );
};

export default ToolHtmlEditor;
