import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  SageTokens,
} from '@kajabi/sage-react';
import {
  useUiContext,
} from 'common/components/ContentEditor/contexts';
import { DIALOG_LINK } from 'common/components/ContentEditor/components/Dialog';
import { EXTENSION_NAMES } from 'common/components/ContentEditor/extensions';

const ToolLink = ({ editor }) => {
  const { setDialog } = useUiContext();

  return (
    <Button
      iconOnly={true}
      color={Button.COLORS.SECONDARY}
      icon={SageTokens.ICONS.URL}
      onClick={() => setDialog(DIALOG_LINK)}
      className={editor.isActive(EXTENSION_NAMES.LINK) && 'sage-btn--active'}
    >
      Link
    </Button>
  );
};

ToolLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolLink;
