import React from 'react';
import { EditorContent } from '@tiptap/react';
import { useTiptapEditorContext } from 'common/components/ContentEditor/contexts';

const TiptapEditor = () => {
  const { editor } = useTiptapEditorContext();
  return <EditorContent editor={editor} />;
};

export default TiptapEditor;
