import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import AceBeautify from 'ace-builds/src-noconflict/ext-beautify';
import 'ace-builds/src-noconflict/mode-html';

import {
  Modal,
  Button,
  SageTokens,
} from '@kajabi/sage-react';

import { useTiptapEditorContext } from 'common/components/ContentEditor/contexts';

const DialogHtmlEditor = ({
  closeFn
}) => {
  const { editor } = useTiptapEditorContext();
  const aceRef = useRef();
  const [htmlValue, setHtmlValue] = useState(editor.getHTML());

  useEffect(() => AceBeautify.beautify(aceRef.current.editor.session), []);

  return (
    <Modal
      large={true}
      active={true}
      onExit={closeFn}
    >
      <Modal.Header
        title="Edit HTML"
        aside={(
          <Button
            color={Button.COLORS.SECONDARY}
            iconOnly={true}
            icon={SageTokens.ICONS.REMOVE}
            subtle={true}
            onClick={closeFn}
          >
            Close HTML editor
          </Button>
        )}
      />
      <Modal.Body>
        <AceEditor
          ref={aceRef}
          mode="html"
          width="100%"
          value={htmlValue}
          onChange={newValue => setHtmlValue(newValue)}
          setOptions={{
            useWorker: false, // Disable syntax warnings (e.g. 'DOCTYPE missing')
            maxLines: null,
            minLines: null,
            printMargin: null,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          color={Button.COLORS.SECONDARY}
          raised={false}
          onClick={closeFn}
        >
          Cancel
        </Button>
        <Button
          color={Button.COLORS.PRIMARY}
          raised={false}
          onClick={() => {
            editor.commands.setContent(htmlValue, true);
            closeFn();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DialogHtmlEditor.propTypes = {
  closeFn: PropTypes.func.isRequired
};

export default DialogHtmlEditor;
