/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import noop from 'lodash/noop';

import LabelTooltip from './LabelTooltip';

const Align = ({ element, name, value, onChange }) => {
  function handleChange(newValue) {
    onChange(newValue);
  }

  return (
    <div className="theme-input">
      <LabelTooltip element={element} name={name} />
      {element.direction === 'horizontal' ? (
        <HorizontalAlign
          name={name}
          value={value}
          onChange={handleChange}
        />
      ) : (
        <VerticalAlign
          name={name}
          onChange={handleChange}
          value={value}
        />
      )}
    </div>
  );
};

Align.defaultProps = {
  value: 'false',
  onChange: noop,
};

Align.propTypes = {
  element: PropTypes.exact({
    id: PropTypes.string.isRequired,
    default: PropTypes.oneOf(['left', 'right', 'start', 'end', 'center']),
    direction: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['align']).isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOf(['left', 'right', 'start', 'end', 'center']),
};

const HorizontalAlign = ({ name, value, onChange }) => (
  <div
    className="btn-group btn-group-justified btn-group-setting"
    data-toggle="buttons"
  >
    <label
      className={`btn btn-default ${value === 'left' ? 'active' : ''}`}
      onClick={() => onChange('left')}
      onKeyUp={() => onChange('left')}
    >
      <input
        type="radio"
        name={name}
        value="left"
        autoComplete="off"
        checked={value === 'left'}
        readOnly
      />
      <i className="material-icons">format_align_left</i>
    </label>

    <label
      className={`btn btn-default ${value === 'center' ? 'active' : ''}`}
      onClick={() => onChange('center')}
      onKeyUp={() => onChange('center')}
    >
      <input
        type="radio"
        name={name}
        value="center"
        autoComplete="off"
        checked={value === 'center'}
        readOnly
      />
      <i className="material-icons">format_align_center</i>
    </label>

    <label
      className={`btn btn-default ${value === 'right' ? 'active' : ''}`}
      onClick={() => onChange('right')}
      onKeyUp={() => onChange('right')}
    >
      <input
        type="radio"
        name={name}
        value="right"
        autoComplete="off"
        checked={value === 'right'}
        readOnly
      />
      <i className="material-icons">format_align_right</i>
    </label>
  </div>
);

HorizontalAlign.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(['left', 'right', 'center']).isRequired,
};

const VerticalAlign = ({ name, onChange, value }) => (
  <div
    className="btn-group btn-group-justified btn-group-setting"
    data-toggle="buttons"
  >
    <label
      className={`btn btn-default ${value === 'start' ? 'active' : ''}`}
      onClick={() => onChange('start')}
      onKeyUp={() => onChange('start')}
    >
      <input
        type="radio"
        name={name}
        value="start"
        autoComplete="off"
        checked={value === 'start'}
        readOnly
      />

      <i className="material-icons">vertical_align_top</i>
    </label>

    <label
      className={`btn btn-default ${value === 'center' ? 'active' : ''}`}
      onClick={() => onChange('center')}
      onKeyUp={() => onChange('center')}
    >
      <input
        type="radio"
        name={name}
        value="center"
        autoComplete="off"
        checked={value === 'center'}
        readOnly
      />

      <i className="material-icons">vertical_align_center</i>
    </label>

    <label
      className={`btn btn-default ${value === 'end' ? 'active' : ''}`}
      onClick={() => onChange('end')}
      onKeyUp={() => onChange('end')}
    >
      <input
        type="radio"
        name={name}
        value="end"
        autoComplete="off"
        checked={value === 'end'}
        readOnly
      />
      <i className="material-icons">vertical_align_bottom</i>
    </label>
  </div>
);

VerticalAlign.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(['start', 'end', 'center']).isRequired,
};

export default memo(Align);
