import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Panel,
  SageClassnames,
  SageTokens,
} from '@kajabi/sage-react';

import {
  useUiContext,
  useSettingsContext,
} from 'common/components/ContentEditor/contexts';

const ToolbarOverlay = ({
  toolbarChildren,
  children,
}) => {
  const { setIsFullscreen } = useUiContext();
  const { label } = useSettingsContext();

  return (
    <dialog open className="kjb-content-editor-toolbar-overlay">
      <Panel>
        <Panel.Row gridTemplate={SageTokens.GRID_TEMPLATES.TI}>
          <h1 className={SageClassnames.TYPE.HEADING_5}>
            {label}
          </h1>
          <div>
            {toolbarChildren}
          </div>
          <Button
            onClick={() => setIsFullscreen(false)}
            icon={SageTokens.ICONS.REMOVE}
            iconPosition={Button.ICON_POSITIONS.RIGHT}
          >
            Close
          </Button>
        </Panel.Row>
        <Panel.Block>
          {children}
        </Panel.Block>
      </Panel>
    </dialog>
  );
};

ToolbarOverlay.propTypes = {
  toolbarChildren: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default ToolbarOverlay;
