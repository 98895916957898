import React from 'react';
import PropTypes from 'prop-types';
import ScriptTag from 'react-script-tag';

export function WistiaPlayer({ refreshing, wistiaId }) {
  return refreshing ? null : (
    <React.Fragment>
      <ScriptTag async src="//fast.wistia.com/assets/external/E-v1.js" />
      <div className="kjb-video-responsive">
        <div className={`wistia_embed wistia_async_${wistiaId}`} />
      </div>
    </React.Fragment>
  );
}

WistiaPlayer.propTypes = {
  refreshing: PropTypes.bool.isRequired,
  wistiaId: PropTypes.string.isRequired,
};
