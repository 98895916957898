import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, SageClassnames } from '@kajabi/sage-react';

export default function PreviewLink() {
  const { themeFilePreviewUrl } = useSelector(
    state => state.linkPaths,
    shallowEqual
  );

  return (
    <Button
      className={SageClassnames.SPACERS.SM_RIGHT}
      color={Button.COLORS.SECONDARY}
      href={themeFilePreviewUrl}
      raised={false}
      small
      target="kajabi-preview"
    >
      Preview
    </Button>
  );
}
