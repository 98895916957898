import { Extension } from '@tiptap/core';

/**
 * HtmlEditor extension
 *
 * This extension does not provide additional text editing functionality to the
 * TiptapEditor. Structured as an empty named editor extension, it allows a developer to
 * enable the HTML editing menu item using the ContentEditor's standardized extension syntax:
 *
 * e.g. `<ContentEditor extensions={[ContentEditor.EXTENSION_NAMES.HTML_EDITOR]} />`
 *
 */

export default Extension.create({
  name: 'htmlEditor',
});
