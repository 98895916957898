import { useCallback, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import forOwn from 'lodash/forOwn';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { updateThemeSettings } from '../redux/api/actions';
import { selectFile } from '../redux/file/selectors';
import { selectTheme } from '../redux/theme/selectors';
import { setFlashMessage } from '../redux/ui/actions';

import { getDifference } from './getDifference';
import useContentKey from './useContentKey';
import { useIframe } from './useIframe';

export function useSave() {
  const contentKey = useContentKey();

  const { sendReloadMessage } = useIframe();

  const dispatch = useDispatch();
  const themeState = useSelector(state => selectTheme(state), shallowEqual);
  const { id: themeFileId } = useSelector(
    state => selectFile(state),
    shallowEqual
  );

  const isMounted = useRef(false);
  const lastSavedTheme = useRef(themeState.attributes.settings);

  const differences = useMemo(
    () => getDifference(themeState.attributes.settings, lastSavedTheme.current),
    [themeState.attributes.settings, lastSavedTheme.current]
  );

  const saveDisabled = useMemo(() => isEmpty(differences), [differences]);

  const saveTheme = useCallback(() => {
    try {
      if (!saveDisabled) {
        const changedKeys = Object.keys(differences).concat([contentKey]);
        const settings = JSON.parse(
          JSON.stringify(pick(themeState.attributes.settings, changedKeys))
        );
        if (settings.sections) {
          settings.sections = pick(
            settings.sections,
            Object.keys(differences.sections)
          );
          forOwn(settings.sections || {}, (value, key) => {
            settings.sections[key] = omit(
              value,
              'id',
              'schema_name',
              'deletable',
              'duplicatable',
              'overrides'
            );
          });
        }

        dispatch(
          updateThemeSettings({
            onComplete: sendReloadMessage,
            themeId: themeState.id,
            themeFileId,
            settings,
          })
        );
        lastSavedTheme.current = themeState.attributes.settings;
      }
    } catch (e) {
      dispatch(
        setFlashMessage({ error: 'Error saving theme. Please try again.' })
      );
      Sentry.captureException(e);
    }
  }, [
    contentKey,
    differences,
    dispatch,
    saveDisabled,
    sendReloadMessage,
    themeFileId,
    themeState.attributes.settings,
    themeState.id,
  ]);

  useEffect(() => {
    if (isMounted.current) {
      lastSavedTheme.current = {};
    }
    isMounted.current = true;
  }, [dispatch, themeFileId]);

  useEffect(() => {
    if (isEmpty(lastSavedTheme.current)) {
      lastSavedTheme.current = themeState.attributes.settings;
    }
  }, [themeState]);

  return { saveDisabled, saveTheme };
}
