import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

import { trackProductAnalytics } from 'common/services/product_analytics';

import isEmpty from 'lodash/isEmpty';

const undoRedoTriggeredBy = event =>
  // the redo/undo buttons do have an event associated with them
  // while the shortcuts do not
  (event ? 'button' : 'keyboard');

const buildAnalyticProperties = event => ({
  target: undoRedoTriggeredBy(event),
});

export function useUndoRedo() {
  const dispatch = useDispatch();
  const { future, past } = useSelector(state => state.theme);

  const redoDisabled = useMemo(() => future.length === 0, [future]);
  const handleRedo = useCallback(
    (event) => {
      if (redoDisabled) return;

      dispatch(UndoActionCreators.redo());
      trackProductAnalytics(
        'Theme Editor Redo Change',
        buildAnalyticProperties(event)
      );
    },
    [dispatch, redoDisabled]
  );

  const undoDisabled = useMemo(() => isEmpty(past), [past]);
  const handleUndo = useCallback(
    (event) => {
      if (undoDisabled) return;

      dispatch(UndoActionCreators.undo());
      trackProductAnalytics(
        'Theme Editor Undo Change',
        buildAnalyticProperties(event)
      );
    },
    [dispatch, undoDisabled]
  );

  return { handleRedo, handleUndo, redoDisabled, undoDisabled };
}
