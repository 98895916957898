import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import { Modal, Button, Grid, Loader } from '@kajabi/sage-react';
import cancellablePromise from 'common/lib/cancellablePromise';
import { get } from '../../services';
import RecentMediaCard from '../RecentMediaCard';

import './styles.scss';

const RecentMediaPicker = ({ recentMediaPath, copyMedia, buttonText }) => {
  const [modalOpen, toggleModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [recentMedia, setRecentMedia] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(get(recentMediaPath));

    if (modalOpen) {
      setLoading(true);
      promise
        .then((response) => {
          const { data: { data } } = response;
          setRecentMedia(data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      cancel();
    }

    return cancel;
  }, [modalOpen, recentMediaPath]);

  const openImagePicker = () => {
    toggleModal(true);
  };

  const setMedia = (media) => {
    setSelectedMedia(media);
  };

  const mediaRows = () => (
    recentMedia && chunk(recentMedia, 4)
  );

  const startCopy = () => {
    toggleModal(false);
    setRecentMedia(null);
    copyMedia(selectedMedia);
  };

  const renderMediaRow = (mediaRow) => {
    const rowKey = mediaRow.map(media => media.id).join('-');
    return (
      <Grid.Row spacerBelow="sm" key={rowKey}>
        {mediaRow.map(media => (
          <Grid.Col size="3" key={media.id}>
            <RecentMediaCard
              key={media.id}
              media={media}
              selected={selectedMedia === media}
              onClick={setMedia}
            />
          </Grid.Col>
        ))}
      </Grid.Row>
    );
  };

  return (
    <>
      <Button
        onClick={openImagePicker}
        className="sage-dropdown__item-control"
        color={Button.COLORS.SECONDARY}
      >
        {buttonText}
      </Button>
      {ReactDOM.createPortal(
        <Modal large={true} active={modalOpen} id="recent-media-picker-modal">
          <Modal.Header>
            <h2>Recent Files</h2>
          </Modal.Header>
          <Modal.Body>
            <div className="recent-media-picker-content">
              <Loader
                loading={isLoading}
                hideWhenDone={true}
                fillSpace={true}
                type={Loader.TYPES.SPINNER}
              />
              {recentMedia && (
                <Grid container={Grid.CONTAINER_SIZES.FULL}>
                  {mediaRows().map(mediaRow => renderMediaRow(mediaRow))}
                </Grid>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.FooterAside>
              <Button
                color={Button.COLORS.SECONDARY}
                onClick={() => { toggleModal(false); }}
              >
                Cancel
              </Button>
            </Modal.FooterAside>
            <Button
              color={Button.COLORS.PRIMARY}
              onClick={() => { startCopy(); }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>,
        document.body
      )}
    </>
  );
};

RecentMediaPicker.defaultProps = {
  copyMedia: () => {},
  buttonText: 'Select a Recent File'
};

RecentMediaPicker.propTypes = {
  recentMediaPath: PropTypes.string.isRequired,
  copyMedia: PropTypes.func,
  buttonText: PropTypes.string,
};

export default RecentMediaPicker;
