import React, { useCallback, useContext, useMemo } from 'react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { Dropdown, SageTokens } from '@kajabi/sage-react';

import { SketchPicker } from 'react-color';

import ThemeContext from '../../context/ThemeContext';

import LabelTooltip from './LabelTooltip';

export const ColorPicker = ({ element, name, theme, value, onChange }) => {
  const { colorPalette } = useContext(ThemeContext);

  const disableAlpha = theme.themeable === 'EmailBroadcast' && !name.includes('overlay');

  const handleChange = useCallback(
    (color) => {
      let colorStr = color.hex;
      if (color.rgb.a !== 1) {
        colorStr = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
      }
      onChange(colorStr);
    },
    [onChange]
  );

  const buttonContent = useMemo(
    () => (
      <div className="sp-preview" style={{ marginBottom: -8, marginRight: 8 }}>
        <div className="sp-replacer sp-light">
          <div
            className={`sp-preview-inner ${value ? '' : 'sp-clear-display'}`}
            style={{ background: value }}
          />
        </div>
      </div>
    ),
    [value]
  );

  return (
    <div className="theme-input color-picker">
      <Dropdown
        label="Color"
        panelSize={Dropdown.PANEL_SIZES.SMALL}
        customTrigger={buttonContent}
      >
        <>
          <SketchPicker
            disableAlpha={disableAlpha}
            onChange={handleChange}
            presetColors={colorPalette}
            color={value}
          />
          <Dropdown.ItemList
            items={[{
              id: 'remove-color',
              label: 'Remove color',
              icon: SageTokens.ICONS.REMOVE_CIRCLE,
              onClick: () => onChange(null)
            }]}
          />
        </>
      </Dropdown>
      <LabelTooltip element={element} name={name} />
    </div>
  );
};

ColorPicker.defaultProps = {
  onChange: noop,
  value: undefined,
};

ColorPicker.propTypes = {
  element: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  theme: PropTypes.shape({}).isRequired,
  value: PropTypes.string,
};
