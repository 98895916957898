import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { TabContext } from './Tabs';

export const TabItem = ({ label }) => {
  const { activeTab, onTabChange } = useContext(TabContext);

  const buttonClick = value => onTabChange(value);

  return (
    <>
      <li
        className={`settings-tab ${activeTab === label ? 'active' : ''}`}
        onClick={() => buttonClick(label)}
        onKeyUp={() => buttonClick(label)}
        role="tab"
      >
        <p className="settings-tab-text">{label}</p>
      </li>
      <li className="divider" />
    </>
  );
};

TabItem.propTypes = {
  label: PropTypes.string.isRequired,
};
