import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectFile } from '../redux/file/selectors';

export default function useContentKey() {
  const { attributes: { content } = {} } = useSelector(
    state => selectFile(state),
    shallowEqual
  );

  const contentKey = useMemo(() => {
    const regex = content.match(/{%-?\s*dynamic_sections_for\s*"(.*)"\s*-?%}/);
    return regex ? `content_for_${regex[1]}` : 'content_for_index';
  }, [content]);

  return contentKey;
}
