// This component depends on JQuery event listeners
// and global App.FilepickerInput, App.RecentImages

import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Button, SageTokens } from '@kajabi/sage-react';

import RecommendedDimensions from './components/RecommendedDimensions';
import ImagePreview from './components/ImagePreview';
import UploadControls from './components/UploadControls';
import RecentImagesButton from './components/RecentImagesButton';
import { urlFor } from './utils';

const FilePicker = ({ config, value, inputName, onChange }) => {
  const [previewUrl, setPreviewUrl] = useState(urlFor(config, value));

  useEffect(() => {
    setPreviewUrl(urlFor(config, value));
  }, [value, config]);

  const handleUploadSuccess = (response) => {
    setPreviewUrl(urlFor(config, response.key));
    onChange(response.key);
  };

  const handleRemove = () => {
    setPreviewUrl(null);
    onChange('');
  };

  return (
    <>
      <ImagePreview url={previewUrl} />
      <RecommendedDimensions convertOptions={config.convertOptions} />
      <Button.Group gap={SageTokens.GRID_GAP_OPTIONS.SM}>
        <UploadControls
          selectText="Select Image"
          config={config}
          value={value}
          inputName={inputName}
          onUploadSuccess={handleUploadSuccess}
          onCopySuccess={handleUploadSuccess}
          RecentFilesButton={RecentImagesButton}
        />
        { previewUrl && (
          <Button color={Button.COLORS.DANGER} subtle={true} onClick={handleRemove}>
            Remove
          </Button>
        )}
      </Button.Group>
    </>
  );
};

FilePicker.defaultProps = {
  value: null,
  onChange: () => {},
  config: {},
};

FilePicker.propTypes = {
  value: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  config: PropTypes.shape({}),
  onChange: PropTypes.func
};

export default memo(FilePicker);
