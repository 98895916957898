import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { themeIcon } from '../../helpers/themeIcon';

import useQuery from '../../../../helpers/useQuery';

export const StaticSectionItem = (section) => {
  const { pathname: url } = useLocation();

  const keyName = section.id || section.block_order[0].substr(0, section.block_order[0].indexOf('_'));

  const queryParams = useQuery();
  const basePath = `${url}/sections/${keyName}`;
  const linkPath = queryParams ? `${basePath}${queryParams}` : basePath;

  return (
    <div className="list-group">
      <div data-section-id={keyName} data-section-name={`${section.name}`} className="list-group-item media">
        <div className="media-left media-middle">
          <i className={`mi md-24 text-light si-${themeIcon(section.name)}`} />
        </div>
        <div className="media-body media-middle">
          <Link to={linkPath} className="title">{section.name}</Link>
        </div>
      </div>
    </div>
  );
};
