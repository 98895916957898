import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { RemoteSelect } from '../../RemoteSelect';

export const ActionRemoteSelect = ({ actionType, isExternal, siteId, onChange, value }) => {
  const valueAttribute = isExternal ? 'url' : 'path';

  const url = useMemo(
    () =>
      `/admin/sites/${siteId}/select_options/${actionType.replace(/s$/, '')}`,
    [actionType, siteId]
  );

  function handleChange(selectedOption) {
    onChange((selectedOption || {})[valueAttribute]);
  }

  const extractedValue = useMemo(() => {
    const found = value && value.match(/[^/]+$/g);
    return found ? found[0] : value;
  }, [value]);

  return (
    <RemoteSelect
      itemSelectAttr={valueAttribute}
      onChange={handleChange}
      remoteUrl={url}
      value={extractedValue}
    />
  );
};

ActionRemoteSelect.defaultProps = {
  value: undefined,
};

ActionRemoteSelect.propTypes = {
  actionType: PropTypes.string.isRequired,
  isExternal: PropTypes.bool.isRequired,
  siteId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};
