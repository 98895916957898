export const ERROR_INVALID_EVENT_TYPE = 'Invalid event type';
export const ERROR_INVALID_EVENT_PROPERTIES = 'Invalid event properties';

// eslint-disable-next-line max-len
export const trackProductAnalytics = async (eventType, eventProperties = {}, options = { debug: false }) => {
  const TRACK_EVENT_ENDPOINT = '/admin/track_events/track';

  const { debug } = options;

  if (!eventType) {
    return { success: false, message: ERROR_INVALID_EVENT_TYPE };
  }

  if (!eventProperties) {
    return { success: false, message: ERROR_INVALID_EVENT_PROPERTIES };
  }

  const data = await fetch(TRACK_EVENT_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify({
      event: {
        event_type: eventType,
        event_properties: eventProperties
      }
    }),
    headers: { 'Content-Type': 'application/json' }
  });

  if (debug) {
    // eslint-disable-next-line no-console
    console.log('DEBUG INFO: Data from track event', data);
  }

  return data;
};
