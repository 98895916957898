import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { themeIcon } from '../../helpers/themeIcon';

export const PresetButton = ({
  handleAdd,
  handleClick,
  isEncore,
  preset,
  selected,
}) => {
  const { name } = preset;

  const iconClass = useMemo(
    () => `si-${themeIcon(name)} ${isEncore ? '' : 'mi'}`,
    [isEncore, name]
  );

  return (
    <div
      className={`presets-item ${selected ? 'presets-selected' : ''}`}
      onClick={() => handleClick(preset)}
      onKeyUp={() => handleClick(preset)}
      role="button"
      tabIndex={0}
    >
      <i className={`presets-icon ${iconClass}`} />
      <div className="presets-name">{name}</div>
      {selected && (
        <div className="presets-block">
          <button
            className="presets-btn btn btn-primary btn-sm"
            onClick={handleAdd}
            type="button"
          >
            Add
          </button>
          <div className="presets-name">{name}</div>
        </div>
      )}
    </div>
  );
};

PresetButton.propTypes = {
  handleAdd: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  isEncore: PropTypes.bool.isRequired,
  preset: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
};
