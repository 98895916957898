import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { SageTokens, Icon } from '@kajabi/sage-react';
import Recommendation from './components/Recommendation';

const UploadCard = ({
  buttonTxt,
  className,
  errorMessage,
  handleFileUpload,
  handleRemove,
  isUploading,
  renderFilePreview,
  renderUploadControls,
  recommendationText,
  previewUrl,
  value,
  previewOnly
}) => {
  const classNames = classnames(
    'sage-upload-card',
    className,
    {
      'sage-upload-card--selected': !!value,
      'sage-upload-card--error': errorMessage,
    }
  );

  const uploadError = errorMessage && (
    <div className="sage-upload-card__errors">
      <p>{errorMessage}</p>
    </div>
  );

  // Preview state
  if (value) {
    return (
      <div className={classNames}>
        <div className="sage-upload-card__dropzone">
          {typeof renderFilePreview === 'function'
            && renderFilePreview({
              value,
              previewUrl,
              isUploading,
              buttonTxt,
              handleFileUpload,
              handleRemove,
              recommendationText
            })}
        </div>
        {uploadError}
      </div>
    );
  }

  // Sometimes the upload trigger can be a button outside of the UploadCard
  // and we need only a preview state in this case
  if (previewOnly) {
    return null;
  }

  // Initial empty state
  return (
    <div className={classNames}>
      <div className="sage-upload-card__dropzone">
        <div className="sage-upload-card__body">
          <Icon
            className="sage-upload-card__icon"
            icon={Icon.ICONS.FILE}
            size={Icon.SIZES['4XL']}
            color={SageTokens.COLOR_SLIDERS.GREY_500}
          />
          {typeof renderUploadControls === 'function'
            && renderUploadControls({ isUploading, buttonTxt, handleFileUpload })}
          <Recommendation>{recommendationText}</Recommendation>
        </div>
      </div>
      {uploadError}
    </div>
  );
};

UploadCard.propTypes = {
  buttonTxt: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  handleFileUpload: PropTypes.func,
  handleRemove: PropTypes.func,
  isUploading: PropTypes.bool,
  recommendationText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  renderFilePreview: PropTypes.func,
  renderUploadControls: PropTypes.func,
  previewUrl: PropTypes.string,
  value: PropTypes.string,
  previewOnly: PropTypes.bool
};

UploadCard.defaultProps = {
  buttonTxt: 'Select a file',
  className: null,
  errorMessage: null,
  handleRemove: () => {},
  handleFileUpload: () => {},
  isUploading: false,
  recommendationText: null,
  renderFilePreview: null,
  renderUploadControls: null,
  previewUrl: null,
  value: null,
  previewOnly: false
};

export default UploadCard;
