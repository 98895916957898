import React from 'react';
import PropTypes from 'prop-types';

export function HelpHover({ link }) {
  return (
    <div className="help-hover">
      <a href={link} rel="noopener noreferrer" target="_blank">
        <i className="mi help-hover-icon">help_outline</i>
      </a>
    </div>
  );
}

HelpHover.propTypes = {
  link: PropTypes.string.isRequired,
};
