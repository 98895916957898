import { API_REQUEST } from 'common/lib/redux/constants';

const createAction = (type, args) => payload => ({
  type,
  ...args,
  ...payload
});

/**
 * Gets a resource link
 * Resource examples:
 *
 * { id: 1, type: 'video', links: { self: '/videos/1' } }
 * or
 * { id: 1, type: 'video', url: '/videos/1' }
 */
const getSelfLink = resource => ((resource.links && resource.links.self) || resource.url);

/**
 * Creates an async action
 * Ex:
 *
 * export const fetchDownloads = createAsyncAction(
 *   consts.FETCH_DOWNLOADS,
 *   ({ id, otherData }) => ({
 *      url: downloadsPath(id),
 *      id,
 *      otherData
 *    })
 * );
 *
 * dispatch(fetchDownloads({ id, otherData }))
 *
 * @param {Object} action - An object that responds to .REQUEST, .SUCCESS, .ERROR
 * @param {Function} prepareFn - A function that returns an object
 */
export const createAsyncAction = (action, prepareFn) => (payload) => {
  const args = prepareFn(payload);

  return {
    type: API_REQUEST,
    payload: {
      onStart: createAction(action.REQUEST, args),
      onSuccess: createAction(action.SUCCESS, args),
      onFailure: createAction(action.ERROR, args),
      ...args
    }
  };
};

/**
 * Creates an action to fetch a resource
 * Ex:
 *
 * export const fetchDownload = createFetchResourceAction(
 *   consts.FETCH_DOWNLOAD
 * );
 * dispatch(fetchDownload({ resource }))
 *
 * @param {Object} action - An object that responds to .REQUEST, .SUCCESS, .ERROR
 * @param {Object} options - Additional data for an action payload
 */
export const createFetchResourceAction = (action, options = {}) => createAsyncAction(
  action,
  ({ resource }) => ({
    url: getSelfLink(resource),
    id: resource.id,
    ...options
  })
);

/**
 * Creates an action to update a resource
 * Ex:
 *
 * export const updateDownload = createUpdateResourceAction(
 *   consts.UPDATE_DOWNLOAD,
 *   { successMessage: consts.MESSAGES.UPDATED }
 * );
 * dispatch(updateDownload({ resource, data }))
 *
 * @param {Object} action - An object that responds to .REQUEST, .SUCCESS, .ERROR
 * @param {Object} options - Additional data for an action payload
 */
export const createUpdateResourceAction = (action, options = {}) => createAsyncAction(
  action,
  ({ resource, data }) => ({
    url: getSelfLink(resource),
    id: resource.id,
    data: { [resource.type]: data },
    method: 'PUT',
    ...options
  })
);

/**
 * Creates an action to delete a resource
 * Ex:
 *
 * export const deleteDownload = createUpdateResourceAction(
 *   consts.DELETE_DOWNLOAD,
 *   { successMessage: consts.MESSAGES.DELETED }
 * );
 * dispatch(deleteDownload({ resource }))
 *
 * @param {Object} action - An object that responds to .REQUEST, .SUCCESS, .ERROR
 * @param {Object} options - Additional data for an action payload
 */
export const createDeleteResourceAction = (action, options = {}) => createAsyncAction(
  action,
  ({ resource }) => ({
    url: getSelfLink(resource),
    id: resource.id,
    method: 'DELETE',
    ...options
  })
);
