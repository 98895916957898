import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@kajabi/sage-react';

import UploadButton from '../UploadButton';

const UploadControls = (props) => {
  const {
    config,
    value,
    inputName,
    selectText,
    onUploadSuccess,
    onCopySuccess,
    RecentFilesButton,
  } = props;

  const buttonConfig = useMemo(() => (
    { ...config, buttonClass: 'sage-btn sage-btn--raised  ' }
  ), [config]);
  const linkConfig = useMemo(() => (
    { ...config, isLink: true, buttonClass: 'sage-dropdown__item-control' }
  ), [config]);

  // Don't show upload controls when upload config is empty
  if (!config || !Object.keys(config).length) {
    return null;
  }

  if (config.withRecentFiles && RecentFilesButton) {
    return (
      <div className="sage-dropdown u-sage-filepicker-dropdown" data-js-dropdown style={{ marginRight: '16px' }}>
        <div aria-hidden="true" className="sage-dropdown__screen" />
        <div aria-controls="options-menu" className="sage-dropdown__trigger">
          <Button
            color={Button.COLORS.SECONDARY}
            raised={false}
          >
            {selectText}
          </Button>
        </div>
        <nav className="sage-dropdown__panel">
          <ul className="sage-dropdown__menu" role="menu" aria-label="options-menu">
            <li className="sage-dropdown__item" role="none">
              <UploadButton
                config={linkConfig}
                buttonColor={Button.COLORS.SECONDARY}
                value={value}
                inputName={inputName}
                onUploadSuccess={onUploadSuccess}
              />
            </li>
            <li className="sage-dropdown__item" role="none">
              <RecentFilesButton
                config={config}
                inputName={inputName}
                onCopySuccess={onCopySuccess}
              />
            </li>
          </ul>
        </nav>
      </div>
    );
  }

  return (
    <UploadButton
      config={buttonConfig}
      value={value}
      inputName={inputName}
      onUploadSuccess={onUploadSuccess}
    />
  );
};

UploadControls.defaultProps = {
  value: null,
  config: {},
  selectText: 'Select a file',
  onUploadSuccess: () => {},
  onCopySuccess: () => {},
  RecentFilesButton: null,
  inputName: '',
};

UploadControls.propTypes = {
  inputName: PropTypes.string,
  value: PropTypes.string,
  config: PropTypes.shape({}),
  selectText: PropTypes.string,
  onUploadSuccess: PropTypes.func,
  onCopySuccess: PropTypes.func,
  RecentFilesButton: PropTypes.func
};

export default UploadControls;
