import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';

import { AsyncPaginate as Select } from 'react-select-async-paginate';

import axios from 'axios';

function SingleValue({ data, getStyles, ...props }) {
  return <div style={getStyles('singleValue', props)}>{data.label}</div>;
}
SingleValue.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  getStyles: PropTypes.func.isRequired,
};

export function RemoteSelect({
  classNamePrefix,
  debounceTimeout,
  itemSelectAttr,
  onChange,
  remoteUrl,
  value,
}) {
  const [items, setItems] = useState([]);
  const [valueFetched, setValueFetched] = useState(!value);

  const formatOptionLabel = useCallback(({ label, subtitle }) => {
    if (subtitle) {
      return (
        <React.Fragment>
          <div>{label}</div>
          <div>
            <small className="text-light">{subtitle}</small>
          </div>
        </React.Fragment>
      );
    }

    return label;
  }, []);

  useEffect(() => {
    if (isEmpty(value)) {
      setValueFetched(true);
    } else if (isEmpty(items)) {
      axios
        .get(remoteUrl, {
          params: {
            search: value
          },
        }
        )
        .then(({ data: { results } }) => {
          if (results.length) {
            setItems([
              {
                value: results[0].id,
                label: results[0].text || results[0].internal_title || results[0].title,
                [itemSelectAttr]: value
              },
            ]);
          }
          setValueFetched(true);
        });
    }
  }, [items, value, itemSelectAttr, remoteUrl]);


  const loadOptions = async (search, loadedOptions, { page }) => {
    const {
      data: { results, totalCount },
    } = await axios.get(remoteUrl, { params: { search, page } });

    const options = results.map(({ id, text, ...rest }) => ({
      value: id,
      label: text,
      ...rest,
    }));

    const allOptions = uniqBy(options.concat(loadedOptions), 'value');
    setItems(allOptions);

    return {
      options,
      hasMore: totalCount > allOptions.length,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(() => {
    setValueFetched(false);
    setItems([]);
  }, [remoteUrl]);

  if (!valueFetched) {
    return null;
  }

  return (
    <Select
      additional={{ page: 1, }}
      classNamePrefix={classNamePrefix}
      components={{
        SingleValue,
      }}
      debounceTimeout={debounceTimeout}
      formatOptionLabel={formatOptionLabel}
      isClearable
      loadOptions={loadOptions}
      noOptionsMessage={() => 'No results found'}
      onChange={onChange}
      value={items.find(
        (item) => {
          const token = String(item[itemSelectAttr]).match(/[^/]+$/g);
          const id = token ? token[0] : item[itemSelectAttr];
          return String(id) === String(value);
        }
      ) || null}
    />
  );
}

RemoteSelect.defaultProps = {
  classNamePrefix: undefined,
  debounceTimeout: 500,
  itemSelectAttr: 'value',
  value: undefined,
};

RemoteSelect.propTypes = {
  classNamePrefix: PropTypes.string,
  debounceTimeout: PropTypes.number,
  itemSelectAttr: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  remoteUrl: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
