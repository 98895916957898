import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@kajabi/sage-react';
import {
  useSettingsContext,
} from 'common/components/ContentEditor/contexts';
import DropdownContent from './DropdownContent';

const ToolColor = ({ editor }) => {
  const { colors } = useSettingsContext();
  return (
    <Dropdown
      label="Color"
      panelSize={Dropdown.PANEL_SIZES.SMALL}
      disabled={editor.view.state.selection.empty}
    >
      <DropdownContent
        editor={editor}
        colors={colors}
      />
    </Dropdown>
  );
};

ToolColor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolColor;
