import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { urlFor } from 'common/components/FilePicker/utils';
import { useFilepicker } from 'common/components/FilePicker';
import UploadCard from './UploadCard';
import { defaultUploadControls, defaultFilePreview } from './dataHelper';

const UploadCardContainer = ({
  className,
  config,
  errorMessage,
  onChange,
  renderFilePreview,
  renderUploadControls,
  recommendationText,
  replaceText,
  uploadingText,
  value,
  previewOnly
}) => {
  const { isUploading, handleFileUpload } = useFilepicker({ config, onUploadSuccess: onChange });
  const buttonText = value ? replaceText : (config.linkText || config.buttonText);

  const handleRemove = useCallback(() => {
    onChange({ key: '' });
  }, [onChange]);

  return (
    <UploadCard
      buttonTxt={isUploading ? uploadingText : buttonText}
      className={className}
      errorMessage={errorMessage}
      handleRemove={handleRemove}
      handleFileUpload={handleFileUpload}
      isUploading={isUploading}
      recommendationText={recommendationText}
      renderFilePreview={renderFilePreview}
      renderUploadControls={renderUploadControls}
      previewUrl={urlFor(config, value)}
      value={value}
      previewOnly={previewOnly}
    />
  );
};

UploadCardContainer.propTypes = {
  className: PropTypes.string,
  config: PropTypes.shape({}),
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  recommendationText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  renderFilePreview: PropTypes.func,
  renderUploadControls: PropTypes.func,
  replaceText: PropTypes.string,
  uploadingText: PropTypes.string,
  value: PropTypes.string,
  previewOnly: PropTypes.bool
};

UploadCardContainer.defaultProps = {
  config: {},
  className: null,
  errorMessage: null,
  onChange: () => {},
  recommendationText: null,
  renderFilePreview: defaultFilePreview,
  renderUploadControls: defaultUploadControls,
  replaceText: 'Replace file',
  uploadingText: 'Uploading...',
  value: null,
  previewOnly: false
};

export default UploadCardContainer;
