import React from 'react';
import PropTypes from 'prop-types';

export function Info({ element }) {
  return (
    <div
      className="theme-input"
      dangerouslySetInnerHTML={{ __html: element.info_html }}
    />
  );
}

Info.propTypes = {
  element: PropTypes.shape({
    info_html: PropTypes.string,
  }).isRequired,
};
