import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash/noop';

import LabelTooltip from './LabelTooltip';

const Text = ({ element, name, value, onChange }) => (
  <div className="theme-input">
    <LabelTooltip element={element} name={name} />
    <input
      name={name}
      type="text"
      className="form-control"
      onChange={e => onChange(e.target.value)}
      placeholder={element.placeholder}
      value={value}
    />
  </div>
);

Text.defaultProps = {
  value: '',
  onChange: noop,
};

Text.propTypes = {
  element: PropTypes.exact({
    label: PropTypes.string.isRequired,
    default: PropTypes.string,
    info: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default memo(Text);
