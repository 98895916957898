import React from 'react';
import { useDispatch } from 'react-redux';
import mapKeys from 'lodash/mapKeys';

import {
  addSection,
  deleteBlock,
  deleteSection,
  updateBlock,
  updateSection,
} from '../redux/theme/actions';

import useAddBlock from './useAddBlock';
import useContentKey from './useContentKey';

function withUpdateSectionOrBlock(WrappedComponent) {
  return (props) => {
    const addBlock = useAddBlock(
      // eslint-disable-next-line react/prop-types
      props.itemType === 'section' ? props.itemId : props.item.sectionId
    );
    const dispatch = useDispatch();
    const contentKey = useContentKey();

    const handleDelete = ({ id, itemType, sectionId }) => {
      if (itemType === 'block') {
        dispatch(deleteBlock({ id, sectionId }));
      } else {
        dispatch(deleteSection({ id, contentKey }));
      }
    };

    const handleDuplicateSectionOrBlock = ({ item, itemType }) => {
      if (itemType === 'block') {
        addBlock(item);
      } else {
        const newId = String(Date.now());
        const mappedBlockKeys = {};
        item.block_order.forEach((id, i) => {
          mappedBlockKeys[id] = `${newId}_${i}`;
        });
        const newBlocks = mapKeys(
          item.blocks,
          (value, key) => mappedBlockKeys[key] || key
        );
        Object.values(mappedBlockKeys).forEach((id) => {
          newBlocks[id] = { ...newBlocks[id], id };
        });
        const duplicatedItem = {
          ...item,
          id: newId,
          block_order: Object.values(mappedBlockKeys),
          blocks: newBlocks,
        };
        dispatch(addSection({ contentKey, id: newId, item: duplicatedItem }));
      }
    };

    const handleUpdate = ({ attribute, id, itemType, sectionId, value }) => {
      if (itemType === 'block') {
        dispatch(updateBlock({ id, attribute, sectionId, value }));
      } else {
        dispatch(updateSection({ id, attribute, value }));
      }
    };

    return (
      <WrappedComponent
        deleteSectionOrBlock={handleDelete}
        duplicateSectionOrBlock={handleDuplicateSectionOrBlock}
        updateSectionOrBlock={handleUpdate}
        {...props}
      />
    );
  };
}

export default withUpdateSectionOrBlock;
