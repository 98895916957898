import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';

import { useSelector } from 'react-redux';
import { useHistory, Switch } from 'react-router-dom';

import { RumRoute as Route } from '@datadog/rum-react-integration';
import { AddBlockPanel } from '../AddBlockPanel';
import { AddSectionPanel } from '../AddSectionPanel';
import { BlockSettingsPanel } from '../BlockSettingsPanel';
import { GlobalSettingsPanel } from '../GlobalSettingsPanel';
import { SectionsPanel } from '../SectionsPanel';
import { SectionSettingsPanel } from '../SectionSettingsPanel';
import { selectFile } from '../../../../redux/file/selectors';
import useQuery from '../../../../helpers/useQuery';
import useDynamicSections from '../../../../helpers/useDynamicSections';

export const Body = ({ compactHeader }) => {
  const history = useHistory();
  const queryParams = useQuery();

  const { id: fileId } = useSelector(state => selectFile(state));

  const dynamicSections = useDynamicSections();

  const previousDynamicSections = useRef(dynamicSections);
  const previousFileId = useRef(fileId);

  useEffect(() => {
    if (
      previousFileId.current === fileId
      && previousDynamicSections.current
      && dynamicSections.length - previousDynamicSections.current.length === 1
    ) {
      const { id: addedSectionId } = difference(
        dynamicSections,
        previousDynamicSections.current
      )[0];
      const path = `sections/${addedSectionId}`;
      const { pathname: currentPath } = history.location;
      if (currentPath.match(/add_section/)) {
        const url = currentPath.replace('add_section', path);
        history.replace(`${url}${queryParams}`);
      } else {
        history.push(`${currentPath}/${path}${queryParams}`);
      }
    }
    previousDynamicSections.current = dynamicSections;
    previousFileId.current = fileId;
  }, [
    dynamicSections,
    fileId,
    history,
    previousDynamicSections,
    previousFileId,
    queryParams,
  ]);

  return (
    <div className={`body ${compactHeader ? 'compact' : ''}`}>
      <LocationSwitch />
    </div>
  );
};

Body.propTypes = {
  compactHeader: PropTypes.bool.isRequired,
};

const LocationSwitch = () => (
  <Switch>
    <Route
      exact
      path="/admin/themes/:themeId/settings/edit"
      component={SectionsPanel}
    />
    <Route
      path="/admin/themes/:themeId/settings/edit/add_section"
      component={AddSectionPanel}
    />
    <Route
      path="/admin/themes/:themeId/settings/edit/sections/:sectionId/add_block"
      component={AddBlockPanel}
    />
    <Route
      path="/admin/themes/:themeId/settings/edit/sections/:sectionId/blocks/:blockId"
      component={BlockSettingsPanel}
    />
    <Route
      path="/admin/themes/:themeId/settings/edit/sections/:sectionId"
      component={SectionSettingsPanel}
    />
    <Route
      path="/admin/themes/:themeId/settings/edit/global/:settingId+"
      component={GlobalSettingsPanel}
    />
  </Switch>
);
