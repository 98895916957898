import React from 'react';

import RecentVideosButton from '../RecentVideosButton';
import UploadControls from '../UploadControls';

const VideoUploadControls = props => (
  <UploadControls
    selectText="Select a file"
    RecentFilesButton={RecentVideosButton}
    {...props}
  />
);

export default VideoUploadControls;
