import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SageClassnames } from '@kajabi/sage-react';

import { useSave } from '../../../../helpers/useSave';
import { useWindowSize } from '../../../../helpers/useWindowSize';

import WindowResizeIcon from './images/window-maximize.svg';

const MIN_WIDTH = 950;

export const SaveThemeContext = createContext();

export function EditorWatcher({ children }) {
  const [show, setShow] = useState(false);

  const { width } = useWindowSize();
  const saveThemeContextValue = useSave();

  useEffect(() => {
    setShow(width < MIN_WIDTH);
  }, [width]);

  return (
    <SaveThemeContext.Provider value={saveThemeContextValue}>
      {children}
      <div
        className={['resize-watcher', show || 'hidden']
          .filter(Boolean)
          .join(' ')}
      >
        <img alt="" src={WindowResizeIcon} />
        <h4
          className={[
            SageClassnames.SPACERS.MD_BOTTOM,
            SageClassnames.SPACERS.MD_TOP,
            SageClassnames.TYPE_COLORS.WHITE_500,
            SageClassnames.TYPE.HEADING_4,
          ].join(' ')}
        >
          Resize your browser window for the best experience
        </h4>
        <p>
          To continue editing, make sure your browser window is at least 950px
          wide
        </p>
      </div>
    </SaveThemeContext.Provider>
  );
}

EditorWatcher.propTypes = {
  children: PropTypes.node.isRequired,
};
