import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  useTiptapEditorContext,
} from '../contexts';
import { TIPTAP_EDITOR_CONTEXT_DEBOUNCE_CONFIG } from '../constants';

const withDebouncedTiptapEditorContext = Component => (props) => {
  const { editor } = useTiptapEditorContext();
  const renderComponent = useDebouncedCallback(
    _editor => <Component {...props} editor={_editor} />,
    ...TIPTAP_EDITOR_CONTEXT_DEBOUNCE_CONFIG
  );
  return renderComponent(editor);
};

export default withDebouncedTiptapEditorContext;
