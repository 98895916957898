// React flash messages

import { Toast, Icon } from '@kajabi/sage-react';
import uuid from 'react-uuid';
import store from '../store';
import * as consts from '../constants';

const messageTimeouts = new Map();

const setMessageTimeouts = (id, timeout) => {
  if (timeout === null) { // when a message is without auto dismiss
    return;
  }

  if (!messageTimeouts.has(id)) {
    const timeoutId = setTimeout(() => {
      flashDismiss(id);
    }, timeout || consts.DEFAULT_TIMEOUT);

    messageTimeouts.set(id, timeoutId);
  }
};

const clearMessageTimeoutFor = (id) => {
  if (messageTimeouts.has(id)) {
    clearTimeout(messageTimeouts.get(id));
    messageTimeouts.delete(id);
  }
};

const clearAllTimeouts = () => {
  messageTimeouts.keys(id => clearMessageTimeoutFor(id));
};

const flash = ({ type, text, icon, timeout }) => {
  const id = uuid();

  setMessageTimeouts(id, timeout);
  store.dispatch({
    type: consts.ADD_MESSAGE,
    message: {
      id,
      props: {
        type,
        title: text,
        timeout: null, // it uses setMessageTimeouts instead of timeouts in Sage implementation
        icon
      }
    }
  });

  return id;
};

export const flashSuccess = props => flash({
  ...props,
  icon: Icon.ICONS.CHECK,
  type: Toast.TYPES.DEFAULT
});

export const flashNotice = props => flash({
  ...props,
  icon: Icon.ICONS.INFO_CIRCLE,
  type: Toast.TYPES.DEFAULT
});

export const flashError = props => flash({
  ...props,
  icon: Icon.ICONS.DANGER,
  type: Toast.TYPES.DANGER
});

export const flashLoading = props => flash({ ...props, timeout: null, type: Toast.TYPES.LOADING });


export const flashDismiss = (id) => {
  clearMessageTimeoutFor(id);

  store.dispatch({
    type: consts.REMOVE_MESSAGE,
    id
  });
};

export const flashReset = () => {
  clearAllTimeouts();
  store.dispatch({ type: consts.REMOVE_ALL_MESSAGES });
};
