import React from 'react';
import PropTypes from 'prop-types';

export const HelpBlock = ({ href, text }) => (
  <p className="help-block">
    <a
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {text}
    </a>
  </p>
);

HelpBlock.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};


export default HelpBlock;
