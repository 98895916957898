import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFilepicker } from './hooks/useFilepicker';

export default function FilepickerButton({
  additionalClassNames,
  config,
  handleSelect,
  isLink,
  text,
}) {
  const [overridenButtonText, overrideButtonText] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleReset = () => {
    setDisabled(false);
    overrideButtonText(null);
  };

  const { selectFile } = useFilepicker({
    config,
    handleComplete: (file) => {
      handleSelect(file);
      handleReset();
    },
    handleReset,
  });

  const handleClick = () => {
    if (!isLink) {
      setDisabled(true);
      overrideButtonText('Updating...');
    }
    selectFile();
  };

  return isLink ? (
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    <a
      as="button"
      className={`filepicker-btn ${additionalClassNames}`}
      disabled={disabled}
      onClick={handleClick}
      onKeyUp={handleClick}
      role="button"
    >
      {overridenButtonText || text}
    </a>
  ) : (
    <button
      className={`btn filepicker-btn ${additionalClassNames}`}
      disabled={disabled}
      onClick={handleClick}
      type="button"
    >
      {overridenButtonText || text}
    </button>
  );
}

FilepickerButton.defaultProps = {
  additionalClassNames: undefined,
  isLink: false,
  text: 'Pick File',
};

FilepickerButton.propTypes = {
  additionalClassNames: PropTypes.string,
  config: PropTypes.shape({}).isRequired,
  handleSelect: PropTypes.func.isRequired,
  isLink: PropTypes.bool,
  text: PropTypes.string,
};
