import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  SageTokens,
} from '@kajabi/sage-react';

const FONT_SIZES = [
  '8px', '10px', '12px', '14px', '16px', '18px', '21px', '24px',
  '28px', '32px', '36px', '42px', '48px', '56px', '64px', '72px',
  '80px', '88px', '96px', '104px', '120px', '144px'
];

const ToolFontSize = ({ editor }) => {
  const activeValue = editor.getAttributes('textStyle').fontSize;

  return (
    <Dropdown
      panelSize={Dropdown.PANEL_SIZES.SMALL}
      customTrigger={(
        <Button
          color={Button.COLORS.SECONDARY}
          style={{ // Custom width & alignment to prevent resize when value changes
            width: '80px',
            justifyContent: 'center'
          }}
        >
          {activeValue || 'Size'}
        </Button>
      )}
    >
      <Dropdown.ItemList
        items={[
          {
            id: 'remove-font-size',
            label: 'Remove font size',
            icon: SageTokens.ICONS.REMOVE_CIRCLE,
            borderAfter: true,
            disabled: !activeValue,
            onClick: () => (
              editor
                .chain()
                .focus()
                .unsetFontSize()
                .run()
            ),
          },
          ...FONT_SIZES.map(fontSizeValue => ({
            id: fontSizeValue,
            label: fontSizeValue,
            isActive: fontSizeValue === activeValue,
            onClick: () => (
              editor
                .chain()
                .focus()
                .setFontSize(fontSizeValue)
                .run()
            ),
          }))
        ]}
      />
    </Dropdown>
  );
};

ToolFontSize.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolFontSize;
