import React, { useCallback } from 'react';
import {
  Toolbar,
  SageClassnames,
} from '@kajabi/sage-react';

import { useSettingsContext } from 'common/components/ContentEditor/contexts';
import { EXTENSION_NAMES } from 'common/components/ContentEditor/extensions';

import ToolFormats from './components/ToolFormats';
import ToolFontSize from './components/ToolFontSize';
import ToolBold from './components/ToolBold';
import ToolItalic from './components/ToolItalic';
import ToolUnderline from './components/ToolUnderline';
import ToolColor from './components/ToolColor';
import ToolLink from './components/ToolLink';
import ToolIndent from './components/ToolIndent';
import ToolHtmlEditor from './components/ToolHtmlEditor';
import ToolBulletList from './components/ToolBulletList';
import ToolOrderedList from './components/ToolOrderedList';
import ToolTextAlign from './components/ToolTextAlign';
import ToolImage from './components/ToolImage';
import ToolBlockquote from './components/ToolBlockquote';
import ToolSuperscript from './components/ToolSuperscript';
import ToolSubscript from './components/ToolSubscript';
import ToolStrike from './components/ToolStrike';
import ToolCode from './components/ToolCode';
import ToolHorizontalRule from './components/ToolHorizontalRule';
import ToolLiquidTag from './components/ToolLiquidTag';

const toolbarGroupStyle = (isActive = true) => ({
  flexGrow: 'unset',
  margin: 0,
  ...(isActive ? {} : { display: 'none' })
});

const ToolMenu = () => {
  const { extensionNames } = useSettingsContext();
  const isActive = useCallback(name => extensionNames.includes(name), [extensionNames]);

  return (
    <Toolbar className={SageClassnames.SPACERS.SM_BOTTOM}>
      <ToolFormats />
      {isActive(EXTENSION_NAMES.FONT_SIZE) && <ToolFontSize />}
      <Toolbar.Group style={toolbarGroupStyle(
        isActive(EXTENSION_NAMES.BOLD)
        || isActive(EXTENSION_NAMES.ITALIC)
        || isActive(EXTENSION_NAMES.UNDERLINE)
        || isActive(EXTENSION_NAMES.COLOR)
      )}
      >
        {isActive(EXTENSION_NAMES.BOLD) && <ToolBold />}
        {isActive(EXTENSION_NAMES.ITALIC) && <ToolItalic />}
        {isActive(EXTENSION_NAMES.UNDERLINE) && <ToolUnderline />}
        {isActive(EXTENSION_NAMES.COLOR) && <ToolColor />}
      </Toolbar.Group>
      {isActive(EXTENSION_NAMES.LINK) && <ToolLink />}
      {isActive(EXTENSION_NAMES.TEXT_ALIGN) && <ToolTextAlign />}
      {isActive(EXTENSION_NAMES.HTML_EDITOR) && <ToolHtmlEditor />}
      <Toolbar.Group style={toolbarGroupStyle(
        isActive(EXTENSION_NAMES.BULLET_LIST)
        || isActive(EXTENSION_NAMES.ORDERED_LIST)
        || isActive(EXTENSION_NAMES.INDENT)
      )}
      >
        {isActive(EXTENSION_NAMES.BULLET_LIST) && <ToolBulletList />}
        {isActive(EXTENSION_NAMES.ORDERED_LIST) && <ToolOrderedList />}
        {isActive(EXTENSION_NAMES.INDENT) && <ToolIndent />}
      </Toolbar.Group>
      {isActive(EXTENSION_NAMES.IMAGE) && <ToolImage />}
      {isActive(EXTENSION_NAMES.BLOCKQUOTE) && <ToolBlockquote />}
      <Toolbar.Group style={toolbarGroupStyle(
        isActive(EXTENSION_NAMES.SUPERSCRIPT)
        || isActive(EXTENSION_NAMES.SUBSCRIPT)
      )}
      >
        {isActive(EXTENSION_NAMES.SUPERSCRIPT) && <ToolSuperscript />}
        {isActive(EXTENSION_NAMES.SUBSCRIPT) && <ToolSubscript />}
      </Toolbar.Group>
      {isActive(EXTENSION_NAMES.STRIKE) && <ToolStrike />}
      {isActive(EXTENSION_NAMES.CODE) && <ToolCode />}
      {isActive(EXTENSION_NAMES.HORIZONTAL_RULE) && <ToolHorizontalRule />}
      {isActive(EXTENSION_NAMES.LIQUID_TAG) && <ToolLiquidTag />}
    </Toolbar>
  );
};

export default ToolMenu;
