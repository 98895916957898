import React, { memo, useCallback, useEffect, useMemo } from 'react';
import noop from 'lodash/noop';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import querystring from 'query-string';

import useQuery from '../../helpers/useQuery';
import { useNotifications } from '../../helpers/useNotifications';
import { selectTheme } from '../../redux/theme/selectors';

import { setDisableIframePointerEvents } from '../../redux/ui/actions';

import { Header } from './components/Header';
import { Body } from './components/Body';
import { PresetHeading } from './components/PresetHeading';
import { Presets } from './components/Presets';
import { DetectedChangesModal } from './components/DetectedChangesModal';
import PreviewFrame from './components/PreviewFrame';
import ThemeContext from './context/ThemeContext';
import { TopBar } from './components/TopBar';
import { LoaderContainer } from './components/Loader';
import { EditorWatcher } from './components/EditorWatcher';

const ThemeEditorContainer = () => {
  useNotifications();
  const queryParams = useQuery();
  const theme = useSelector(state => selectTheme(state), shallowEqual);
  const { attributes: { sectionSchemas, settingsSchema } = {} } = theme || {};
  const colorPalette = useSelector(state => state.colorPalette, shallowEqual);
  const dispatch = useDispatch();

  // TODO: Figure out what and where these values come from
  const selected = 'presets';
  const themeHasPresets = false;

  const history = useHistory();
  const { pathname } = useLocation();

  const compactHeader = !!pathname.match(/edit\/(.+)/);

  const baseUrl = useMemo(
    () => (theme ? `/admin/themes/${theme.id}/settings/edit` : null),
    [theme]
  );

  const buildQuery = useCallback(
    element =>
      querystring.stringify({
        ...querystring.parse(queryParams),
        element,
      }),
    [queryParams]
  );

  const handler = useCallback(
    ({ data: { setting, type } }) => {
      // TODO: find a better way to parse the messaging we get in from editSetting function
      // in theme_settings.js
      if (type === 'editSetting') {
        let elementId = setting;
        const settingsArray = setting.split('_');
        let settingPath;

        if (isEmpty(sectionSchemas)) {
          const id = [settingsArray[0], settingsArray[1]]
            .filter(Boolean)
            .join('_');
          const section = find(settingsSchema.groups, group =>
            find(
              group.elements,
              element => !!(element.id || '').match(new RegExp(`^${id}`))
            )
          );
          if (section) {
            settingPath = `global/${section.name.replace(/\s/g, '')}`;
          }
        } else {
          const sectionIndex = findIndex(settingsArray, s => s === 'sections');
          const settingsIndex = findIndex(settingsArray, s => s === 'settings');
          const pathArray = settingsArray.slice(sectionIndex, settingsIndex);
          elementId = settingsArray.slice(settingsIndex + 1).join('_');
          settingPath = pathArray
            .join('_')
            .replace(/sections_/, 'sections/')
            .replace('_blocks_', '/blocks/');
        }
        if (settingPath) {
          history.push(`${baseUrl}/${settingPath}?${buildQuery(elementId)}`);
        }
      }
    },
    [baseUrl, buildQuery, history, sectionSchemas, settingsSchema]
  );

  useEffect(() => {
    const onMouseDown = () => {
      dispatch(setDisableIframePointerEvents(true));
    };
    const onMouseUp = () => {
      dispatch(setDisableIframePointerEvents(false));
    };
    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mouseup', onMouseUp);

    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [dispatch]);

  useEffect(() => {
    if (!theme) return noop;

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  }, [theme, handler]);

  if (!theme) {
    return (
      <LoaderContainer>
        <div className="animated-logo">
          <div className="triangle top order1" />
          <div className="triangle top order2" />
          <div className="triangle top order3" />
          <div className="triangle top order4" />
          <div className="triangle top order5" />
          <div className="triangle top order6" />
          <div className="triangle bottom order1" />
          <div className="triangle bottom order2" />
          <div className="triangle bottom order3" />
          <div className="triangle bottom order4" />
          <div className="triangle bottom order5" />
          <div className="triangle bottom order6" />
        </div>
      </LoaderContainer>
    );
  }

  return (
    <EditorWatcher>
      <ThemeContext.Provider value={{ colorPalette }}>
        <TopBar {...theme.attributes} />
        <div id="sidenav">
          {selected === 'presets' && (
            <>
              <div
                className={compactHeader ? 'sidenav-inner' : ''}
                id="settings-primary"
              >
                <Header compactHeader={compactHeader} {...theme.attributes} />
                <Body compactHeader={compactHeader} />
              </div>
              {themeHasPresets && (
                <div id="settings-presets">
                  <PresetHeading {...theme.attributes} />
                  <Presets {...theme.attributes} />
                </div>
              )}
            </>
          )}
        </div>

        <PreviewFrame />
        <DetectedChangesModal />
      </ThemeContext.Provider>
    </EditorWatcher>
  );
};

export default memo(ThemeEditorContainer);
