/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-autofocus */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import pick from 'lodash/pick';

import withUpdateSectionOrBlock from '../../../../helpers/withUpdateSectionOrBlock';
import useQuery from '../../../../helpers/useQuery';
import { themeIcon } from '../../helpers/themeIcon';

import ActionMenu from './ActionMenu';

const BLOCK = 'block';
const SECTION = 'section';
const ENTER_KEY = 13;
const ESCAPE_KEY = 27;

export function DraggableRow(props) {
  const {
    deleteSectionOrBlock,
    duplicateSectionOrBlock,
    itemType,
    itemId,
    item,
    setSortDisabled,
    updateSectionOrBlock,
    ...rest
  } = props;
  const { hidden, name, schema_name: schemaName, sectionId, type } = item;

  const sectionHidden = useMemo(() => hidden === 'true', [hidden]);

  const { pathname } = useLocation();
  const queryParams = useQuery();

  const [actionMenuVisible, setActionMenuVisible] = useState(false);
  const [renameInputVisible, setRenameInputVisible] = useState(false);
  const [updatedName, setUpdatedName] = useState(name);

  // TODO: we should be able to get rid of this once we
  // get draggable working within react
  const draggableProps = useMemo(() => {
    const keys = itemType === BLOCK
      ? ['data-block-id']
      : ['data-anchor-section', 'data-section-id', 'data-section-name'];
    return pick(rest, keys);
  }, [itemType, rest]);

  const cancelRenameInput = () => {
    setUpdatedName(name);
    setRenameInputVisible(false);
  };

  const onDelete = () => {
    deleteSectionOrBlock({ id: itemId, item, itemType, sectionId });
  };

  const onDuplicate = () => {
    duplicateSectionOrBlock({ id: itemId, item, itemType, sectionId });
  };

  const showRename = () => {
    setRenameInputVisible(true);
  };

  const handleUpdate = (attribute, value) => {
    updateSectionOrBlock({
      attribute,
      id: itemId,
      item,
      itemType,
      sectionId,
      value,
    });
  };

  const toggleVisibility = () => {
    handleUpdate('hidden', String(hidden === 'false'));
  };

  const updateName = () => {
    handleUpdate('name', updatedName);
    setRenameInputVisible(false);
  };

  const handleKeyDown = ({ which }) => {
    switch (which) {
      case ESCAPE_KEY:
        cancelRenameInput();
        break;
      case ENTER_KEY:
        updateName();
        break;
      default:
    }
  };

  useEffect(() => {
    setSortDisabled(renameInputVisible);
  }, [renameInputVisible, setSortDisabled]);

  return (
    <div
      className="list-group-item draggable-section"
      onMouseEnter={() => setActionMenuVisible(true)}
      onMouseLeave={() => setActionMenuVisible(false)}
      {...draggableProps}
    >
      <div className="pull-left item-left section-icon">
        <i className={`mi md-24 si-${themeIcon(type)}`} />
      </div>
      {renameInputVisible ? (
        <>
          <div className="pull-right item-right">
            <a
              className="input-controls"
              onClick={updateName}
              onKeyUp={updateName}
              role="button"
              tabIndex={0}
            >
              <i className="mi md-20 text-light">done</i>
            </a>
            &nbsp;
            <a
              className="input-controls"
              onClick={cancelRenameInput}
              onKeyUp={cancelRenameInput}
              role="button"
              tabIndex={0}
            >
              <i className="mi md-20 text-light">close</i>
            </a>
          </div>
          <div className="ellipsis">
            <input
              autoFocus
              className="form-control"
              name="section-name"
              onChange={e => setUpdatedName(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={schemaName}
              type="text"
              value={updatedName}
            />
          </div>
        </>
      ) : (
        <>
          <div className="pull-right item-right">
            <i className="mi md-24 mi-drag-handle">drag_handle</i>
          </div>
          {sectionHidden && (
            <div className="pull-right item-right">
              <i className="mi md-20">visibility_off</i>
            </div>
          )}
          {actionMenuVisible && (
            <ActionMenu
              handleDelete={onDelete}
              handleDuplicate={onDuplicate}
              handleRename={showRename}
              handleVisibility={toggleVisibility}
              {...item}
            />
          )}
          {itemType === BLOCK ? (
            <div className="ellipsis">
              <Link
                to={`${pathname}/blocks/${itemId}${queryParams}`}
                className="title"
                data-kjb-element={name}
              >
                <span className="title" data-kjb-element={name}>
                  {name || schemaName}
                </span>
              </Link>
            </div>
          ) : (
            <Link
              to={`${pathname}/sections/${itemId}${queryParams}`}
              className="title"
              data-kjb-element={name}
            >
              <div className="ellipsis">{name || schemaName}</div>
            </Link>
          )}
        </>
      )}
    </div>
  );
}

DraggableRow.propTypes = {
  deleteSectionOrBlock: PropTypes.func.isRequired,
  duplicateSectionOrBlock: PropTypes.func.isRequired,
  updateSectionOrBlock: PropTypes.func.isRequired,
  itemType: PropTypes.oneOf([BLOCK, SECTION]).isRequired,
  itemId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    hidden: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sectionId: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
  setSortDisabled: PropTypes.func.isRequired,
};

export default withUpdateSectionOrBlock(DraggableRow);
