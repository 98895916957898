import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash/noop';

import LabelTooltip from './LabelTooltip';

const TextArea = ({ element, name, value, onChange }) => {
  const handleChange = (e) => {
    onChange(e.currentTarget.value);
  };

  return (
    <div className="theme-input">
      <LabelTooltip element={element} name={name} />
      <textarea
        name={name}
        type="text"
        className="form-control"
        rows="5"
        onChange={handleChange}
        placeholder={element.placeholder}
        value={value}
      >
        {value}
      </textarea>
    </div>
  );
};

TextArea.defaultProps = {
  value: '',
  onChange: noop,
};

TextArea.propTypes = {
  element: PropTypes.shape({
    default: PropTypes.string,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['textarea']),
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default memo(TextArea);
