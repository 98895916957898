import React from 'react';
import PropTypes from 'prop-types';

export const ActionInput = ({ onChange, value }) => (
  <input
    className="form-control"
    onChange={e => onChange(e.currentTarget.value)}
    type="text"
    value={value}
  />
);


ActionInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
