import React from 'react';
import PropTypes from 'prop-types';
import { DynamicSelect } from './DynamicSelect';

export const SalesPage = ({
  element,
  name,
  onChange,
  theme: {
    attributes: { siteId },
  },
  value,
}) => (
  <DynamicSelect
    element={element}
    helpUrl={`/admin/sites/${siteId}/sales_pages`}
    name={name}
    onChange={onChange}
    resourceName="sales_page"
    siteId={siteId}
    value={value}
  />
);

SalesPage.defaultProps = {
  value: '',
};

SalesPage.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    attributes: PropTypes.shape({
      siteId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
