import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NodeViewWrapper } from '@tiptap/react';

const IframeView = ({
  node: { attrs: { iframeAttributes: {
    src
  } } },
  selected,
}) => (
  <NodeViewWrapper
    as="div"
    className={
      classnames(
        'kjb-content-editor-node-iframe',
        { 'kjb-content-editor-node-iframe--selected': selected },
      )}
  >
    iFrame
    <br />
    {src}
  </NodeViewWrapper>
);

IframeView.propTypes = {
  node: PropTypes.shape({
    attrs: PropTypes.shape({
      iframeAttributes: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }).isRequired
    }).isRequired
  }).isRequired,
  selected: PropTypes.bool.isRequired
};

export default IframeView;
