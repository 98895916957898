import React from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  SageTokens,
} from '@kajabi/sage-react';

const ToolTextAlign = ({ editor }) => (
  <Dropdown
    label="Alignment"
    panelSize={Dropdown.PANEL_SIZES.SMALL}
    disabled={!editor.can().setTextAlign('right')}
  >
    <Dropdown.ItemList items={[
      {
        id: 'left',
        label: 'Left',
        icon: SageTokens.ICONS.ALIGN_LEFT,
        disabled: !editor.can().setTextAlign('left'),
        onClick: () => (
          editor
            .chain()
            .focus()
            .setTextAlign('left')
            .run()
        )
      },
      {
        id: 'right',
        label: 'Right',
        icon: SageTokens.ICONS.ALIGN_RIGHT,
        disabled: !editor.can().setTextAlign('right'),
        onClick: () => (
          editor
            .chain()
            .focus()
            .setTextAlign('right')
            .run()
        )
      },
      {
        id: 'center',
        label: 'Center',
        icon: SageTokens.ICONS.ALIGN_CENTER,
        disabled: !editor.can().setTextAlign('center'),
        onClick: () => (
          editor
            .chain()
            .focus()
            .setTextAlign('center')
            .run()
        )
      },
      {
        id: 'justify',
        label: 'Justify',
        icon: SageTokens.ICONS.ALIGN_JUSTIFY,
        disabled: !editor.can().setTextAlign('justify'),
        onClick: () => (
          editor
            .chain()
            .focus()
            .setTextAlign('justify')
            .run()
        )
      },
      {
        id: 'default',
        label: 'Default',
        icon: SageTokens.ICONS.REMOVE_CIRCLE,
        disabled: !editor.can().unsetTextAlign(),
        onClick: () => (
          editor
            .chain()
            .focus()
            .unsetTextAlign()
            .run()
        )
      },
    ]}
    />
  </Dropdown>
);

ToolTextAlign.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolTextAlign;
