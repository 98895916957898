import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import LabelTooltip from './LabelTooltip';

const CheckBox = ({ element, name, value, onChange }) => {
  const checked = useMemo(() => ['true', true].includes(value), [value]);

  return (
    <div className="checkbox">
      <LabelTooltip element={element} name={name}>
        <input
          type="checkbox"
          name={name}
          value={checked}
          checked={checked}
          onChange={e => onChange(e.target.checked)}
        />
      </LabelTooltip>
    </div>
  );
};

CheckBox.defaultProps = {
  value: 'false',
  onChange: noop,
};

CheckBox.propTypes = {
  element: PropTypes.exact({
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['checkbox']).isRequired,
    default: PropTypes.oneOf(['true', 'false', true, false, '']),
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOf(['true', 'false', true, false, '']),
};

function propsAreEqual(prevProps, nextProps) {
  return (
    prevProps.name === nextProps.name
    && prevProps.value === nextProps.value
    && prevProps.element === nextProps.element
  );
}

export default memo(CheckBox, propsAreEqual);
