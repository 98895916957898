import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { SketchPicker } from 'react-color';
import { Dropdown, SageTokens } from '@kajabi/sage-react';

const DropdownContent = ({
  editor,
  colors,
}) => {
  const [color, setColor] = useState('#000000');
  useEffect(() => {
    const activeColor = editor.getAttributes('textStyle').color;
    if (activeColor) setColor(activeColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateEditor = debounce(({ hex }) => {
    editor.commands.setColor(hex);
  }, 250);

  return (
    <>
      <SketchPicker
        onChange={(event) => {
          setColor(event);
          updateEditor(event);
        }}
        presetColors={colors}
        disableAlpha={true}
        color={color}
      />
      <Dropdown.ItemList items={[{
        id: 'remove-color',
        label: 'Remove color',
        icon: SageTokens.ICONS.REMOVE_CIRCLE,
        onClick: () => (
          editor
            .chain()
            .focus()
            .unsetColor()
            .run()
        )
      }]}
      />
    </>
  );
};

DropdownContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DropdownContent;
