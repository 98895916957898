import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isBoolean from 'lodash/isBoolean';

import { selectFile } from '../../../../redux/file/selectors';
import { selectThemeAttributes } from '../../../../redux/theme/selectors';
import { setThirdPartyCookiesEnabled } from '../../../../redux/ui/actions';

const UNPROTECTED_PAGES = [
  'Site:store.liquid',
  'Site:page.liquid',
  'Site:404.liquid',
  'Site:blog.liquid',
  'Site:blog_post.liquid',
  'Site:blog_search.liquid',
  'Site:about.liquid',
  'Site:contact.liquid',
  'LandingPage:index.liquid',
];

export function ThirdPartyCookieAlert() {
  const { attributes: { name } = {} } = useSelector(state => selectFile(state));
  const { themeableType } = useSelector(state => selectThemeAttributes(state));
  const {
    linkPaths: { thirdPartyCookiesUrl },
    ui: { thirdPartyCookiesEnabled },
  } = useSelector(state => state);

  const dispatch = useDispatch();

  const listener = useCallback(
    (event) => {
      if (event.data === 'kjb-3pc-unsupported') {
        dispatch(setThirdPartyCookiesEnabled(true));
      } else if (event.data === 'kjb-3pc-supported') {
        dispatch(setThirdPartyCookiesEnabled(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (UNPROTECTED_PAGES.includes(`${themeableType}:${name}`)) {
      dispatch(setThirdPartyCookiesEnabled(false));
    } else {
      window.addEventListener('message', listener, false);
    }
    return () => window.removeEventListener('message', listener);
  }, [dispatch, listener, name, themeableType]);

  return (
    <>
      {!isBoolean(thirdPartyCookiesEnabled) && (
        <iframe
          className="hidden"
          src={thirdPartyCookiesUrl}
          title="third-party-cookie-check"
        />
      )}
      {thirdPartyCookiesEnabled && (
        <div id="3pc-warning" className="alert alert-warning alert-index">
          <p>
            <strong>Only seeing the login page?</strong> You may need to allow
            third-party cookies for your browser.
            <br />
            <a
              href="https://help.kajabi.com/hc/en-us/articles/360045799134"
              rel="noopener noreferrer"
              target="_blank"
            >
              Click here for more info
            </a>
          </p>
        </div>
      )}
    </>
  );
}
