import React from 'react';
import PropTypes from 'prop-types';
import { BubbleMenu } from '@tiptap/react';

import {
  Button,
  SageTokens,
} from '@kajabi/sage-react';

import { DIALOG_CALL_TO_ACTION } from 'common/components/ContentEditor/components/Dialog';

const BubbleCallToAction = ({
  editor,
  setDialog
}) => (
  <BubbleMenu
    className="kjb-content-editor-menu-edit"
    editor={editor}
    pluginKey="BubbleCallToAction"
    shouldShow={() => editor.isActive('callToAction')}
    tippyOptions={{ zIndex: SageTokens.Z_INDEXES.OVERLAY }}
  >
    <Button.Group>
      <Button
        color={Button.COLORS.PRIMARY}
        icon={SageTokens.ICONS.MEGAPHONE}
        onClick={() => setDialog(DIALOG_CALL_TO_ACTION)}
      >
          Edit call to action
      </Button>
    </Button.Group>
  </BubbleMenu>
);

BubbleCallToAction.propTypes = {
  // Object built by useEditor hook
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired,
  setDialog: PropTypes.func.isRequired
};

export default BubbleCallToAction;
