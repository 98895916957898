import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import LabelTooltip from './LabelTooltip';

import { NumberInput } from './NumberInput';

export const Spacer = ({ element, name, value, onChange, }) => {
  const handleChange = useCallback((direction, input) => {
    const newPadding = {
      ...value,
      [direction]: input.value
    };

    onChange(newPadding);
  }, [onChange, value]);

  const spacerCell = (direction) => {
    const inputName = `${name}[${direction}]`;
    return (
      <td className={`spacer-cell spacer-${direction}`}>
        <NumberInput
          name={inputName}
          onChange={e => handleChange(direction, e.currentTarget)}
          placeholder={
            isEmpty(element.placeholder)
              ? undefined
              : element.placeholder[direction]
          }
          value={isEmpty(value) ? '' : value[direction]}
        />
      </td>
    );
  };

  return (
    <div className="theme-input spacer">
      <LabelTooltip element={element} name={name} />
      <div>
        <table>
          <tbody>
            <tr>
              <td className="spacer-cell">&nbsp;</td>
              {spacerCell('top')}
            </tr>
            <tr>
              {spacerCell('left')}
              <td className="spacer-cell">&nbsp;</td>
              {spacerCell('right')}
            </tr>
            <tr>
              <td className="spacer-cell">&nbsp;</td>
              {spacerCell('bottom')}
              <td className="spacer-cell">&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

Spacer.defaultProps = {
  value: {
    top: '',
    bottom: '',
    left: '',
    right: '',
  },
};

Spacer.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.shape({
      bottom: PropTypes.string.isRequired,
      left: PropTypes.string.isRequired,
      right: PropTypes.string.isRequired,
      top: PropTypes.string.isRequired,
    }),
    type: PropTypes.oneOf(['spacer']).isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    top: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
  }),
};
