import { combineReducers } from 'redux';
import undoable, { excludeAction } from 'redux-undo';

import theme, { initialState as themeState } from '../theme/reducer';
import file, { initialState as fileState } from '../file/reducer';
import files, { initialState as filesState } from '../files/reducer';
import ui, { initialState as uiState } from '../ui/reducer';
import user, { initialState as userState } from '../user/reducer';
import linkPaths, {
  initialState as linkPathsState,
} from '../linkPaths/reducer';
import colorPalette, {
  initialState as colorPaletteState,
} from '../colorPalette/reducer';

import { FETCH_THEME, UPDATE_THEME } from '../api/constants';

export const initialState = {
  file: fileState,
  files: filesState,
  theme: themeState,
  ui: uiState,
  user: userState,
  linkPaths: linkPathsState,
  colorPalette: colorPaletteState,
};

const editorReducer = combineReducers({
  file,
  files,
  theme: undoable(theme, { filter: excludeAction(UPDATE_THEME.SUCCESS) }),
  ui,
  user,
  linkPaths,
  colorPalette,
});

export default function createReducer() {
  return function themeEditorReducer(state, action) {
    if (FETCH_THEME.SUCCESS === action.type) {
      const { data } = action;
      const newTheme = { ...state.theme, present: data.theme };
      return editorReducer({ ...data, theme: newTheme }, action);
    }

    return editorReducer(state, action);
  };
}
