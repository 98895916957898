export const selectTheme = state => state.theme.present.data;
export const selectThemeAttributes = state =>
  state.theme.present.data.attributes;
export const selectThemeable = state =>
  state.theme.present.data.attributes.themeable;
export const selectSettingsSchema = state =>
  state.theme.present.data.attributes.settingsSchema;
export const selectThemeDynamicSections = (state, contentKey) =>
  (state.theme.present.data.attributes.settings[contentKey] || []).filter(
    s => s
  );
export const selectSectionSchema = (state, type) =>
  state.theme.present.data.attributes.sectionSchemas[type];
export const selectBlockSchema = (state, sectionType, blockType) => {
  const sectionSchema = state.theme.present.data.attributes.sectionSchemas[sectionType] || {};
  const blockSchemas = sectionSchema.blocks || [];
  return blockSchemas.find(b => b.type === blockType);
};
export const selectSections = state =>
  state.theme.present.data.attributes.settings.sections;
export const selectSection = (state, id) => selectSections(state)[id];
