
/**
 * Creates action constants for an async action
 * Ex:
 * export const FETCH_DOWNLOADS = createConstants('DOWNLOADS/FETCH');
 *
 * FETCH_DOWNLOADS.REQUEST    => 'DOWNLOADS/FETCH_REQUEST'
 * FETCH_DOWNLOADS.SUCCESS    => 'DOWNLOADS/FETCH_SUCCESS'
 * FETCH_DOWNLOADS.ERROR      => 'DOWNLOADS/FETCH_ERROR'
 * FETCH_DOWNLOADS.toString() => 'DOWNLOADS/FETCH'
 *
 * @param {String} action
 */
export const createConstants = action => ({
  REQUEST: `${action}_REQUEST`,
  SUCCESS: `${action}_SUCCESS`,
  ERROR: `${action}_ERROR`,
  toString: () => action,
});
