import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { themeIcon } from '../../helpers/themeIcon';
import useEncore from '../../../../helpers/useEncore';

export function AddBlockButton({ block, handleClick }) {
  const isEncore = useEncore();
  const iconClass = useMemo(
    () => `si-${themeIcon(block.name)} ${isEncore ? '' : 'mi'}`,
    [isEncore, block]
  );

  return (
    <div
      className="column"
      onClick={handleClick}
      onKeyUp={handleClick}
      role="button"
      tabIndex={0}
    >
      <i className={`blocks-menu-icon ${iconClass}`} />
      <span className="blocks-menu-name">{block.name}</span>
    </div>
  );
}

AddBlockButton.propTypes = {
  block: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
};
