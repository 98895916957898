import React from 'react';
import { Toast } from '@kajabi/sage-react';

import { FlashMessageProvider } from './FlashMessagesContext';
import { useMessages } from './hooks/useMessages';
import { flashDismiss } from './utils';

import './style.scss';

const FlashMessages = () => {
  const messages = useMessages();

  return (
    <div id="SageReactToastContainer" className="react-flash-messages sage-toast-container">
      {messages.map(message => (
        <Toast
          {...message.props}
          isActive
          onDismiss={() => flashDismiss(message.id)}
          key={message.id}
        />
      ))}
    </div>
  );
};

const FlashMessagesContainer = () => (
  <FlashMessageProvider>
    <FlashMessages />
  </FlashMessageProvider>
);

export default FlashMessagesContainer;
