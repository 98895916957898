import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

import useContentKey from './useContentKey';
import { selectSections as selectSectionsOrder } from '../redux/file/selectors';
import {
  selectSections,
  selectThemeDynamicSections,
} from '../redux/theme/selectors';

export default function useDynamicSections() {
  const sectionOrder = useSelector(state => selectSectionsOrder(state));
  const contentKey = useContentKey();
  const dynamicSectionIds = useSelector(state =>
    (find(sectionOrder, 'isDynamic') ? selectThemeDynamicSections(state, contentKey) : [])
  );
  const allSections = useSelector(state => selectSections(state));

  const dynamicSections = useMemo(
    () => dynamicSectionIds.map(id => allSections[id]).filter(Boolean),
    [dynamicSectionIds, allSections]
  );

  return dynamicSections;
}
