import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

import { Tooltip } from '../Tooltip';

export default function ActionMenu({
  deletable,
  handleDelete,
  handleDuplicate,
  handleRename,
  handleVisibility,
  hidden,
}) {
  const sectionHidden = useMemo(() => hidden === 'true', [hidden]);
  const disabledClass = useMemo(() => (deletable ? '' : 'disabled'), [
    deletable,
  ]);

  return (
    <div className="pull-right item-right">
      <div className="dropdown">
        <Dropdown>
          <Dropdown.Toggle as="a" className="dropdown-toggle">
            <i className="mi md-24">more_horiz</i>
          </Dropdown.Toggle>

          <Dropdown.Menu as="ul" className="dropdown-menu dropdown-menu-right">
            <li>
              <Dropdown.Item onClick={handleRename}>Rename</Dropdown.Item>
            </li>
            <li>
              <Dropdown.Item
                className={disabledClass}
                onClick={handleDuplicate}
              >
                Duplicate
                {!deletable && (
                  <>
                    &nbsp;
                    <Tooltip text="This section is unique">
                      <i className="mi info-hover-icon">info_outlined</i>
                    </Tooltip>
                  </>
                )}
              </Dropdown.Item>
            </li>
            {deletable && (
              <li>
                <Dropdown.Item onClick={handleVisibility}>
                  {sectionHidden ? 'Show' : 'Hide'}
                </Dropdown.Item>
              </li>
            )}
            <li>
              <Dropdown.Item className={disabledClass} onClick={handleDelete}>
                Delete
                {!deletable && (
                  <>
                    &nbsp;
                    <Tooltip text="This section is required">
                      <i className="mi">info_outlined</i>
                    </Tooltip>
                  </>
                )}
              </Dropdown.Item>
            </li>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

ActionMenu.propTypes = {
  deletable: PropTypes.bool,
  handleDelete: PropTypes.func.isRequired,
  handleDuplicate: PropTypes.func.isRequired,
  handleRename: PropTypes.func.isRequired,
  handleVisibility: PropTypes.func.isRequired,
  hidden: PropTypes.oneOf(['true', 'false']).isRequired,
};

ActionMenu.defaultProps = {
  deletable: true,
};
