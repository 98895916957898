import React from 'react';
import PropTypes from 'prop-types';

export function HelpGuide({ url }) {
  return (
    <div className="list-group-item media">
      <div className="media-left media-middle">
        <i className="mi md-24 text-light si-menu" />
      </div>
      <div className="media-body media-middle">
        <span className="title">
          <a href={url} target="_blank" rel="noopener noreferrer">
            Help Guide
          </a>
        </span>
      </div>
    </div>
  );
}

HelpGuide.propTypes = {
  url: PropTypes.string.isRequired,
};
