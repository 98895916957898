import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  SageTokens,
} from '@kajabi/sage-react';
import { EXTENSION_NAMES } from 'common/components/ContentEditor/extensions';

const ToolCode = ({ editor }) => (
  <Button
    iconOnly={true}
    color={Button.COLORS.SECONDARY}
    icon={SageTokens.ICONS.CODE}
    onClick={() => editor
      .chain()
      .focus()
      .extendMarkRange(EXTENSION_NAMES.CODE)
      .toggleCode()
      .run()}
    disabled={!editor.can().toggleCode()}
    className={editor.isActive(EXTENSION_NAMES.CODE) && 'sage-btn--active'}
  >
      Code
  </Button>
);

ToolCode.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolCode;
