import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { flashError, flashNotice, flashLoading, flashDismiss } from 'common/components/FlashMessages';

import RecentMediaPicker from '../RecentMediaPicker';

const RecentImagesButton = ({ config, onCopySuccess }) => {
  const uploaderRef = useRef(new window.App.FilepickerUpload(config)).current;
  const toastId = useRef(null);

  const startCopying = () => {
    toastId.current = flashLoading({ text: 'Copying your image' });
  };

  const copySuccess = () => {
    flashDismiss(toastId.current);
    flashNotice({ text: 'Image successfully copied.' });
  };

  const copyMedia = (media) => {
    const { key } = media.attributes;
    Promise.resolve(startCopying())
      .then(() => uploaderRef.storeUrl(key))
      .then(res => uploaderRef.convert(res))
      .then(res => onCopySuccess(res))
      .then(() => {
        copySuccess();
      })
      .catch((err) => {
        flashDismiss(toastId.current);
        if (err && !err.code) {
          flashError({ text: 'Filepicker Error: Please try again' });
        }

        if (err.code && err.code !== 101) {
          flashError({ text: `Filepicker Error ${err.message}` });
        }
      });
  };

  return (
    <RecentMediaPicker
      config={config}
      recentMediaPath={config.recentImagesPath}
      copyMedia={copyMedia}
      buttonText="Select a Recent Image"
    />
  );
};

RecentImagesButton.defaultProps = {
  onCopySuccess: () => {}
};

RecentImagesButton.propTypes = {
  config: PropTypes.shape({}).isRequired,
  onCopySuccess: PropTypes.func
};

export default RecentImagesButton;
