import { createAsyncAction } from 'common/lib/redux/createActions';

import * as actionTypes from './actionTypes';

export const addAudio = createAsyncAction(
  actionTypes.ADD_AUDIO,
  ({ key, siteId, themeId }) => ({
    url: `/api/admin/sites/${siteId}/audios`,
    data: {
      audio: {
        mediable_id: themeId,
        mediable_type: 'Theme',
        original_media: key,
      },
    },
    method: 'POST',
    normalizeData: false,
  })
);

export function addBlock(payload) {
  return {
    type: actionTypes.ADD_BLOCK,
    payload,
  };
}

export function addSection(payload) {
  return {
    type: actionTypes.ADD_SECTION,
    payload,
  };
}

export function deleteBlock(payload) {
  return {
    type: actionTypes.DELETE_BLOCK,
    payload,
  };
}

export function deleteSection(payload) {
  return {
    type: actionTypes.DELETE_SECTION,
    payload,
  };
}

export function reorderBlocks(payload) {
  return {
    type: actionTypes.REORDER_BLOCKS,
    payload,
  };
}

export function reorderSections(payload) {
  return {
    type: actionTypes.REORDER_SECTIONS,
    payload,
  };
}

export function updateBlock(payload) {
  return {
    type: actionTypes.UPDATE_BLOCK,
    payload,
  };
}

export function updateBlockSettings(payload) {
  return {
    type: actionTypes.UPDATE_BLOCK_SETTINGS,
    payload,
  };
}

export function updateGlobalSettings(payload) {
  return {
    type: actionTypes.UPDATE_GLOBAL_SETTINGS,
    payload,
  };
}

export function updateHasRecentMedias(payload) {
  return {
    type: actionTypes.UPDATE_HAS_RECENT_MEDIAS,
    payload,
  };
}

export function updateSection(payload) {
  return {
    type: actionTypes.UPDATE_SECTION,
    payload,
  };
}

export function updateSectionSettings(payload) {
  return {
    type: actionTypes.UPDATE_SECTION_SETTINGS,
    payload,
  };
}
