import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';

import { Tooltip } from '../Tooltip';

const LabelTooltip = ({ children, element, name }) => {
  if (element) {
    const id = `${kebabCase(name)}-input`;
    const { info, label } = element;
    return (
      <label htmlFor={id}>
        {children}
        {label}
        {info && (
          <Tooltip text={info}>
            <i className="mi info-hover-icon">info_outlined</i>
          </Tooltip>
        )}
      </label>
    );
  }

  return null;
};

LabelTooltip.defaultProps = {
  children: undefined,
  element: undefined,
  name: undefined,
};

LabelTooltip.propTypes = {
  children: PropTypes.node,
  element: PropTypes.shape({
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
  }),
  name: PropTypes.string,
};

export default LabelTooltip;
