import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { set, debounce } from 'lodash';
import axios from 'axios';
/* global App */

import SaveIndicator from 'common/components/SaveIndicator';

/**
 * ContentEditorRails Autosave
 * Autosaves the ContentEditor to a rails controller endpoint.
 *
 * @param {string} value - Stringified HTML
 * @param {string} endpoint - Server location to PUT data
 * @param {string} payloadShape - JSON payload shape as a string (utilizes Lodash `set` function)
 *                                `cool.field` would convert to `{cool: {field: <value-here> }}`
 */

const AUTOSAVE_DEBOUNCE = 1000 * 6; // 6 seconds

const Autosave = ({
  value,
  endpoint,
  payloadShape,
}) => {
  const [updatedAt, setUpdatedAt] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const hasValueChanged = useRef(false);

  useEffect(() => {
    if (hasValueChanged.current) {
      setIsDirty(true);
      debouncedSaveRequestRef.current(value);
    } else {
      hasValueChanged.current = true;
    }
  }, [value]);

  const debouncedSaveRequestRef = useRef(debounce((newValue) => {
    setIsSaving(true);
    const data = set({}, payloadShape, newValue);

    axios({
      data,
      url: endpoint,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: window.validationToken,
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    }).then(
      () => {
        setIsSaving(false);
        setIsDirty(false);
        setUpdatedAt(new Date().getTime()); // Get browser timestamp on success
      }
    ).catch(
      (error) => {
        setIsSaving(false);
        App.flashError(error.message);
      }
    );
  }, AUTOSAVE_DEBOUNCE));

  return (<SaveIndicator updatedAt={updatedAt} isSaving={isSaving} isDirty={isDirty} />);
};

Autosave.defaultProps = {
  value: null,
};

Autosave.propTypes = {
  value: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  payloadShape: PropTypes.string.isRequired,
};

export default Autosave;
