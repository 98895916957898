import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import snakeCase from 'lodash/snakeCase';
import isEmpty from 'lodash/isEmpty';

import { InputType } from '../../helpers/inputGenerator';
import { useEmptySectionOrBlock } from '../../../../helpers/useEmptySectionOrBlock';
import { useHighlightId } from '../../../../helpers/useHighlightId';
import { useIframe } from '../../../../helpers/useIframe';
import { updateBlockSettings } from '../../../../redux/theme/actions';
import {
  selectTheme,
  selectBlockSchema,
  selectSection,
} from '../../../../redux/theme/selectors';
import Accordion from '../Accordion';

export const BlockSettingsPanel = () => {
  const isMounted = useRef();
  const {
    params: { sectionId, blockId },
  } = useRouteMatch();

  const theme = useSelector(state => selectTheme(state), shallowEqual);

  const section = useSelector(
    state => selectSection(state, sectionId),
    shallowEqual
  ) || { blocks: {} };

  const block = section.blocks[blockId] || {};

  const blockSchema = useSelector(
    state => selectBlockSchema(state, section.type, block.type),
    shallowEqual
  ) || {};

  const { settings } = block;

  const { sendScrollToBlockMessage } = useIframe();

  const prefix = `settings[sections][${sectionId}][blocks][${blockId}]`;

  const linkPaths = useSelector(state => state.linkPaths, shallowEqual);

  const dispatch = useDispatch();

  // The value returned from the child component
  // should be the value to be persisted and not
  // an element / component
  const handleChange = useCallback(
    (name, value) => {
      const attribute = name
        .replace(`${prefix}[settings]`, '')
        .match(/\[(.*)\]/)[1];
      dispatch(
        updateBlockSettings({ id: blockId, sectionId, attribute, value })
      );
    },
    [blockId, dispatch, prefix, sectionId]
  );

  const accordionItems = useMemo(
    () =>
      (blockSchema.groups || []).map(group => ({
        id: snakeCase(group.name),
        group,
        title: group.name,
      })),
    [blockSchema.groups]
  );

  const { handleDeleteBlock } = useEmptySectionOrBlock({ sectionId, blockId });

  useHighlightId(`settings_sections_${sectionId}_blocks_${blockId}`);

  useEffect(() => {
    if (!isEmpty(block) && !isMounted.current) {
      sendScrollToBlockMessage(block.id);
      isMounted.current = true;
    }
  }, [block, sendScrollToBlockMessage]);

  if (isEmpty(block)) {
    return null;
  }

  return (
    <div className="form-section">
      <div className="form-group">
        <div className="header-type">
          <h3 className="header">{block.name}</h3>
          {block.name !== blockSchema.name && (
            <p className="help-block">{block.schemaName}</p>
          )}
        </div>
      </div>
      {blockSchema.elements.map((element, idx) => {
        if (element.type === 'header') return null;
        return (
          <InputType
            // eslint-disable-next-line react/no-array-index-key
            key={`${element.type}_${idx}_${blockId}`}
            sectionId={sectionId}
            blockId={blockId}
            type={element.type}
            element={element}
            settings={settings}
            theme={theme}
            inputPrefix={`${prefix}[settings]`}
            onChange={handleChange}
            paths={linkPaths}
          />
        );
      })}
      {/* <% blockSchema.elements.each do |element| %>
        <% next if element.type.downcase == "header" %>
        <%= render_theme_setting_partial
            section.theme,
            element,
            block.settings,
            input_prefix: "#{prefix}[settings]"
          %>
      <% end %> */}

      <Accordion
        blockId={blockId}
        icon="settings"
        id={`${block.id}-accordion`}
        inputPrefix={`${prefix}[settings]`}
        items={accordionItems}
        onChange={handleChange}
        paths={linkPaths}
        sectionId={sectionId}
        settings={block.settings}
        theme={theme}
        title="Block Settings"
      />

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="delete-link" onClick={handleDeleteBlock} href="#">
        Delete This Block
      </a>
    </div>
  );
};
