import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { hideToastMessage, showToastMessage } from './toastMessages';

export function useNotifications() {
  const { flashMessage, toastMessage } = useSelector(state => state.ui);

  useEffect(() => {
    hideToastMessage();
    const { error } = flashMessage;
    if (error) {
      window.App.flashError({ text: error });
    }
  }, [flashMessage]);

  useEffect(() => {
    if (toastMessage) {
      showToastMessage(toastMessage);
    } else {
      hideToastMessage();
    }
  }, [toastMessage]);
}
