/* eslint-disable no-console */

import React from 'react';
import PropTypes from 'prop-types';

const initState = { error: null };

export default class ErrorBoundary extends React.Component {
  constructor() {
    super();
    this.state = initState;
  }

  static getDerivedStateFromError(error = null) {
    return { error };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  render() {
    const { error } = this.state;
    const { fallbackRender } = this.props;

    if (error !== null) {
      return fallbackRender({ error });
    }
    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {
  fallbackRender: () => (null)
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallbackRender: PropTypes.func,
};
