import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NodeViewWrapper } from '@tiptap/react';

const CallToActionView = ({
  node: { attrs: { handle } },
  selected,
}) => (
  <NodeViewWrapper
    as="span"
    className={
      classnames('kjb-content-editor-node-inline-variable-data-field', {
        'kjb-content-editor-node-inline-variable-data-field--selected': selected
      })}
  >
    {`{{ ${handle} }}`}
  </NodeViewWrapper>
);

CallToActionView.propTypes = {
  // Object built by useEditor hook
  // eslint-disable-next-line react/forbid-prop-types
  node: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired
};

export default CallToActionView;
