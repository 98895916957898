import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import last from 'lodash/last';
import { useDispatch } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { saveAs } from 'file-saver';

import { addAudio } from '../../../../redux/theme/actions';

import { WistiaPlayer } from '../WistiaPlayer';

import LabelTooltip from './LabelTooltip';
import FilepickerButton from './FilepickerButton';

export function Audio({
  element,
  name,
  onChange,
  paths: { mediaPlaceholderPath },
  theme: {
    id: themeId,
    attributes: {
      audios: { data: audios },
      filepickerOptions,
      siteId,
    },
  },
  value,
}) {
  const [refreshAudio, setRefreshAudio] = useState(false);

  const previousAudios = useRef(audios);
  const dispatch = useDispatch();

  const selectedAudio = useMemo(
    () => audios.find(v => String(v.id) === String(value)),
    [value, audios]
  );

  const onSelectFile = async ({ key }) => {
    setRefreshAudio(true);
    dispatch(addAudio({ siteId, key, themeId }));
  };

  const downloadAudio = useCallback(() => {
    const {
      attributes: {
        originalAudio,
        urls: { downloadUrl },
      },
    } = selectedAudio;
    saveAs(downloadUrl, originalAudio);
  }, [selectedAudio]);

  useEffect(() => {
    if (audios.length - previousAudios.current.length === 1) {
      setRefreshAudio(false);
      onChange(String(last(audios).id));
      previousAudios.current = audios;
    }
  }, [onChange, audios]);

  return (
    <div className="theme-input">
      <LabelTooltip element={element} name={name} />
      <div className="media-preview audio-preview">
        {selectedAudio ? (
          <WistiaPlayer
            refreshing={refreshAudio}
            wistiaId={selectedAudio.attributes.wistiaId}
          />
        ) : (
          <div className="audio-preview-absent embed-responsive embed-responsive-16by9">
            <img
              className="embed-responsive-item media-preview"
              src={mediaPlaceholderPath}
              alt="Upload audio placeholder"
            />
          </div>
        )}
      </div>
      {selectedAudio ? (
        <div className="select-asset-dropdown dropdown">
          <Dropdown>
            <Dropdown.Toggle
              as="button"
              className="btn btn-primary btn-outline dropdown-toggle"
            >
              <i className="mi icon-caret">arrow_drop_down</i>Audio Actions
            </Dropdown.Toggle>
            <Dropdown.Menu as="ul" className="dropdown-menu">
              <li>
                <Dropdown.Item onClick={downloadAudio}>
                  Download Audio
                </Dropdown.Item>
              </li>
              <li>
                <FilepickerButton
                  additionalClassNames="fp-input"
                  config={filepickerOptions.audio}
                  handleSelect={onSelectFile}
                  isLink
                  text="Upload a New File"
                />
              </li>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        <FilepickerButton
          additionalClassNames="btn-default btn-block"
          config={filepickerOptions.audio}
          handleSelect={onSelectFile}
          text="Add Audio"
        />
      )}
    </div>
  );
}

Audio.defaultProps = {
  value: '',
};

Audio.propTypes = {
  element: PropTypes.exact({
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  paths: PropTypes.shape({
    mediaPlaceholderPath: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    attributes: PropTypes.shape({
      audios: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
          })
        ).isRequired,
      }).isRequired,
      filepickerOptions: PropTypes.shape({
        audio: PropTypes.shape({}).isRequired,
      }).isRequired,
      siteId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
