import axios from 'axios';
import clientInfo from 'common/lib/clientInfo';

const token = clientInfo.get('tokens.csrf');

const instance = axios.create({
  baseURL: `${window.location.origin}`,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': token,
  }
});

export const get = (endpoint, options) => {
  const req = instance.get(endpoint, options);
  return req;
};

export const postWithToken = (path, options) => {
  const req = instance.post(path, options);
  return req;
};

export const postRecentFile = ({ recentImagesPath, siteId }, { key }) => (
  postWithToken(recentImagesPath, {
    site_id: siteId,
    recent_image: {
      image: key
    }
  })
);
