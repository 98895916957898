import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import ScriptTagView from './ScriptTagView';

const EXTENSION_NAME = 'scriptTag';

export default Node.create({
  name: EXTENSION_NAME,
  group: 'block',
  atom: true,

  addAttributes() {
    return ({
      scriptContent: {
        default: '',
        parseHTML: element => element.innerHTML,
      },
      scriptAttributes: {
        default: {},
        parseHTML: element => Object.fromEntries(
          element.getAttributeNames().map(attrKey => [attrKey, element.getAttribute(attrKey)])
        ),
      },
    });
  },

  parseHTML() {
    return [{ tag: 'script' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'script',
      HTMLAttributes.scriptAttributes,
      HTMLAttributes.scriptContent,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ScriptTagView);
  }
});
