import { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import last from 'lodash/last';
import flatMap from 'lodash/flatMap';
import omit from 'lodash/omit';
import { useHistory } from 'react-router-dom';

import useQuery from './useQuery';

import { addBlock } from '../redux/theme/actions';
import { selectSections } from '../redux/theme/selectors';

const reduceElement = (obj, element) => {
  const mappedElement = element.id
    ? { [element.id]: element.placeholder || element.default || '' }
    : {};
  return {
    ...obj,
    ...mappedElement,
  };
};

export default function useAddBlock(sectionId) {
  const previousBlocks = useRef();
  const dispatch = useDispatch();
  const section = useSelector(state => selectSections(state)[sectionId], shallowEqual) || {};
  const history = useHistory();
  const queryParams = useQuery();

  const { block_order: blockOrder = [] } = section;

  useEffect(() => {
    if (
      previousBlocks.current
      && blockOrder.length - previousBlocks.current.length === 1
    ) {
      const addedBlockId = last(blockOrder);
      const { pathname: currentPath } = history.location;
      const path = `/blocks/${addedBlockId}${queryParams}`;
      const url = currentPath.replace('/add_block', '').concat(path);
      history[currentPath.match(/add_block/) ? 'replace' : 'push'](url);
    }
  }, [dispatch, blockOrder, history, section, sectionId, queryParams]);

  const addNewBlock = (block) => {
    const settings = {
      ...(block.elements || []).reduce(reduceElement, {}),
      ...flatMap(block.groups, 'elements').reduce(reduceElement, {}),
      ...block.settings,
    };
    previousBlocks.current = blockOrder;
    dispatch(
      addBlock({
        item: {
          ...omit(block, [
            'elements',
            'groups',
            'id',
            'schemaName',
            'sectionType',
          ]),
          sectionId,
          settings,
        },
        sectionId,
      })
    );
  };

  return addNewBlock;
}
