const cancellablePromise = (originalPromise) => {
  let isCancelled = false;

  const promise = new Promise((resolve, reject) => {
    originalPromise
      .then(result => !isCancelled && resolve(result))
      .catch(error => !isCancelled && reject(error));
  });
  const cancel = () => {
    isCancelled = true;
  };

  return {
    promise,
    cancel
  };
};

export default cancellablePromise;
