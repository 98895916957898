import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Button, Label, SageClassnames, SageTokens } from '@kajabi/sage-react';

import useEncore from '../../../../helpers/useEncore';
import { useNavigate } from '../../../../helpers/useNavigate';

import SearchBar from './SearchBar';

export const Header = (props) => {
  const isEncore = useEncore();
  const history = useHistory();
  const showSearch = isEncore && !!history.location.pathname.match(/add_section/);

  const { navigateTo } = useNavigate();

  const { compactHeader, themeable, themeableType } = props;

  const buttonClasses = useMemo(
    () =>
      [
        SageClassnames.SPACERS.XS_BOTTOM,
        showSearch || SageClassnames.SPACERS.XS_TOP,
        showSearch || SageClassnames.SPACERS.SM_LEFT,
        showSearch || SageClassnames.SPACERS.XS_RIGHT,
      ]
        .filter(Boolean)
        .join(' '),
    [showSearch]
  );

  const divClasses = useMemo(
    () => (!compactHeader || showSearch ? 'sidenav-head' : ''),
    [compactHeader, showSearch]
  );

  return (
    <div className={divClasses}>
      {compactHeader ? (
        <Link
          to={{
            pathname: navigateTo,
            state: { fromBackLink: true },
          }}
        >
          <Button
            className={buttonClasses}
            color={Button.COLORS.SECONDARY}
            icon={SageTokens.ICONS.CARET_LEFT}
            small
            subtle
          >
            Back
          </Button>
        </Link>
      ) : (
        <div className="media">
          <div className="media-body media-middle">
            <h4 className="sidenav-head-title ellipsis">
              {themeable.title || themeable.name}
            </h4>
          </div>
          {themeableType === 'MarketingEmailTemplate' && (
            <div className="media-right media-middle nowrap marketing-email-template-status">
              <Label color="draft" value="Template" />
            </div>
          )}
        </div>
      )}
      <SearchBar showSearch={showSearch} />
    </div>
  );
};

Header.propTypes = {
  compactHeader: PropTypes.bool.isRequired,
  themeable: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  themeableType: PropTypes.string.isRequired,
};
