import React from 'react';
import PropTypes from 'prop-types';
import { BubbleMenu } from '@tiptap/react';

import {
  Button,
  SageTokens,
} from '@kajabi/sage-react';

import { DIALOG_IMAGE } from 'common/components/ContentEditor/components/Dialog';

const isActive = editor => editor.isActive('image');
const hasDefaultSize = (editor) => {
  const { width } = editor.getAttributes('image');
  return width === '100%' || width === undefined;
};

const BubbleImage = ({
  editor,
  setDialog
}) => {
  const editImageButton = (
    <Button
      color={Button.COLORS.PRIMARY}
      icon={SageTokens.ICONS.IMAGE}
      onClick={() => setDialog(DIALOG_IMAGE)}
    >
      Edit image
    </Button>
  );

  return (
    <>
      <BubbleMenu
        className="kjb-content-editor-menu-edit"
        editor={editor}
        pluginKey="BubbleImageDefaultSize"
        shouldShow={({ _editor = editor }) => isActive(_editor) && hasDefaultSize(_editor)}
        tippyOptions={{ zIndex: SageTokens.Z_INDEXES.OVERLAY }}
      >
        <Button.Group>
          {editImageButton}
        </Button.Group>
      </BubbleMenu>
      <BubbleMenu
        className="kjb-content-editor-menu-edit"
        editor={editor}
        pluginKey="BubbleImageCustomSize"
        shouldShow={({ _editor = editor }) => isActive(_editor) && !hasDefaultSize(_editor)}
        tippyOptions={{ zIndex: SageTokens.Z_INDEXES.OVERLAY }}
      >
        <Button.Group>
          <Button
            color={Button.COLORS.PRIMARY}
            icon={SageTokens.ICONS.UNDO}
            onClick={() => editor.commands.resetAttributes('image', ['height', 'width'])}
          >
              Reset size
          </Button>
          {editImageButton}
        </Button.Group>
      </BubbleMenu>
    </>
  );
};

BubbleImage.propTypes = {
  // Object built by useEditor hook
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired,
  setDialog: PropTypes.func.isRequired
};

export default BubbleImage;
