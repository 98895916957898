import React from 'react';
import PropTypes from 'prop-types';

const UiContext = React.createContext({});

const useUiContext = () => {
  const context = React.useContext(UiContext);

  if (context === undefined) {
    throw new Error('useSettingsContext was used outside of its Provider');
  }

  return context;
};

const UiProvider = ({
  children,
}) => {
  const initialState = {
    isFullscreen: false,
    setIsFullscreen: value => setProviderState(
      prevState => ({ ...prevState, isFullscreen: value })
    ),

    dialog: null,
    setDialog: value => setProviderState(
      prevState => ({ ...prevState, dialog: value })
    ),
  };

  const [providerState, setProviderState] = React.useState(initialState);

  return (
    <UiContext.Provider value={providerState}>
      {children}
    </UiContext.Provider>
  );
};

UiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UiProvider, useUiContext };
