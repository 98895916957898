import React, { memo, useCallback, useEffect, useState } from 'react';
import querystring from 'query-string';

import { useSelector } from 'react-redux';

import { selectFile } from '../../../../redux/file/selectors';
import { selectFiles } from '../../../../redux/files/selectors';
import { selectTheme } from '../../../../redux/theme/selectors';

const PageSwitcher = () => {
  const file = useSelector(state => selectFile(state));
  const files = useSelector(state => selectFiles(state));
  const {
    attributes: { themeableType },
  } = useSelector(state => selectTheme(state));
  const [page, setPage] = useState(file.id);

  const handleChange = useCallback((themeFileId) => {
    const searchParams = querystring.stringify({
      ...querystring.parse(window.location.search),
      theme_file_id: themeFileId,
    });
    const newPath = `${window.location.pathname}?${searchParams}`;
    const newUrl = window.location.origin + newPath;
    window.location.replace(newUrl);
  }, []);

  useEffect(() => {
    if (page !== file.id) {
      handleChange(page);
    }
  }, [file.id, handleChange, page]);

  if (
    [
      'LandingPage',
      'EmailBroadcast',
      'EmailSequenceEmail',
      'MarketingEmailTemplate',
    ].includes(themeableType)
  ) {
    return null;
  }

  return (
    <select
      id="page-selector-input"
      className="form-control"
      name="pages"
      onChange={event => setPage(event.target.value)}
      value={page}
    >
      {files.map(f => (
        <option key={f.id} value={f.id}>
          {f.attributes.displayName}
        </option>
      ))}
    </select>
  );
};

export default memo(PageSwitcher);
