import React from 'react';
import PropTypes from 'prop-types';

const RecommendedDimensions = ({ convertOptions }) => {
  const { width, height } = convertOptions;

  if (!width || !height) {
    return null;
  }

  const dimensions = `${width}x${height}`;
  return (
    <p className="help-block">Recommended dimensions of <strong>{dimensions}</strong></p>
  );
};

RecommendedDimensions.defaultProps = {
  convertOptions: {},
};

RecommendedDimensions.propTypes = {
  convertOptions: PropTypes.shape({}),
};

export default RecommendedDimensions;
