import React from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
} from '@kajabi/sage-react';
import {
  useSettingsContext,
} from 'common/components/ContentEditor/contexts';

const ToolLiquidTag = ({ editor }) => {
  const { liquidTags } = useSettingsContext();

  return (
    <Dropdown
      label="Personalization"
      panelSize={Dropdown.PANEL_SIZES.SMALL}
    >
      <Dropdown.ItemList
        items={liquidTags.map(tag => ({
          id: tag.handle,
          label: tag.title,
          onClick: () => (
            editor
              .chain()
              .focus()
              .insertLiquidTag(tag)
              .run()
          ),
        }))
        }
      />
    </Dropdown>
  );
};

ToolLiquidTag.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired
};

export default ToolLiquidTag;
