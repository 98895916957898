import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectThemeAttributes } from '../redux/theme/selectors';

export default function useEncore() {
  const { info: { theme_name: themeName } = {} } = useSelector(
    state => selectThemeAttributes(state),
    shallowEqual
  );
  return useMemo(() => themeName === 'Encore', [themeName]);
}
