import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ContentEditor, { EXTENSION_NAMES, VALUE_TYPES } from 'common/components/ContentEditor';

const EMAIL_EDITOR = 'email';
const ENCORE_EDITOR = 'encore';
const SIMPLE_EDITOR = 'simple';

const extensionNamesForStyle = (styleName) => {
  switch (styleName) {
    case SIMPLE_EDITOR: return ([
      EXTENSION_NAMES.BOLD,
      EXTENSION_NAMES.ITALIC,
      EXTENSION_NAMES.UNDERLINE,
      EXTENSION_NAMES.LINK,
    ]);
    case EMAIL_EDITOR: return ([
      EXTENSION_NAMES.FONT_SIZE,
      EXTENSION_NAMES.HTML_EDITOR,
      EXTENSION_NAMES.BOLD,
      EXTENSION_NAMES.ITALIC,
      EXTENSION_NAMES.STRIKE,
      EXTENSION_NAMES.COLOR,
      EXTENSION_NAMES.TEXT_ALIGN,
      EXTENSION_NAMES.HEADING,
      EXTENSION_NAMES.BULLET_LIST,
      EXTENSION_NAMES.ORDERED_LIST,
      EXTENSION_NAMES.LINK,
      EXTENSION_NAMES.IMAGE,
      EXTENSION_NAMES.LIQUID_TAG,
    ]);
    case ENCORE_EDITOR:
    default: return ([
      EXTENSION_NAMES.FONT_SIZE,
      EXTENSION_NAMES.HEADING,
      EXTENSION_NAMES.BLOCKQUOTE,
      EXTENSION_NAMES.BULLET_LIST,
      EXTENSION_NAMES.ORDERED_LIST,
      EXTENSION_NAMES.HORIZONTAL_RULE,
      EXTENSION_NAMES.BOLD,
      EXTENSION_NAMES.ITALIC,
      EXTENSION_NAMES.LINK,
      EXTENSION_NAMES.SUPERSCRIPT,
      EXTENSION_NAMES.SUBSCRIPT,
      EXTENSION_NAMES.STRIKE,
      EXTENSION_NAMES.TEXT_ALIGN,
      EXTENSION_NAMES.CODE,
      EXTENSION_NAMES.INDENT,
      EXTENSION_NAMES.UNDERLINE,
      EXTENSION_NAMES.COLOR,
      EXTENSION_NAMES.HTML_EDITOR,
      EXTENSION_NAMES.HARD_BREAK,
      EXTENSION_NAMES.SCRIPT_TAG,
      EXTENSION_NAMES.IFRAME,
    ]);
  }
};

const RichTextNext = ({
  value,
  onChange,
  element: {
    label,
    style,
  },
  theme: { attributes: {
    filepickerOptions: { download: uploaderConfig },
    colorPalette,
    fields,
  } }
}) => {
  const extensionNames = useMemo(() => extensionNamesForStyle(style), [style]);
  return (
    <ContentEditor
      label={label}
      onChange={onChange}
      value={value}
      valueType={VALUE_TYPES.HTML}
      extensionNames={extensionNames}
      colors={colorPalette}
      uploaderConfig={uploaderConfig}
      liquidTags={fields}
    />
  );
};

RichTextNext.defaultProps = {
  onChange: () => {},
  value: '',
};

RichTextNext.propTypes = {
  element: PropTypes.exact({
    default: PropTypes.string,
    id: PropTypes.string.isRequired,
    info: PropTypes.string,
    label: PropTypes.string.isRequired,
    style: PropTypes.string,
    type: PropTypes.oneOf(['rich_text']).isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  paths: PropTypes.shape({
    tinymceContentPath: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    attributes: PropTypes.shape({
      colorOptions: PropTypes.shape({}).isRequired,
      fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      filepickerOptions: PropTypes.shape({}).isRequired,
      settings: PropTypes.shape({}).isRequired,
      themeableType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  value: PropTypes.string,
};

export default RichTextNext;
