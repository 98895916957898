import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Input,
  Modal,
  Button,
  SageTokens,
} from '@kajabi/sage-react';

import { useTiptapEditorContext } from 'common/components/ContentEditor/contexts';

const CallToAction = ({
  closeFn
}) => {
  const { editor } = useTiptapEditorContext();
  const { href: initialHrefValue, label: initialLabelValue } = editor.getAttributes('callToAction');
  const [hrefValue, setHrefValue] = useState(initialHrefValue);
  const [labelValue, setLabelValue] = useState(initialLabelValue);

  const deleteFn = () => editor.commands.deleteCallToAction() && closeFn();
  const createFn = () => editor.commands.setCallToAction({
    href: hrefValue, label: labelValue
  }) && closeFn();

  const renderDeleteButton = () => {
    if (!initialHrefValue && !initialLabelValue) return false;

    return (
      <Button
        color={Button.COLORS.DANGER}
        subtle={true}
        onClick={deleteFn}
      >
        Delete call to action
      </Button>
    );
  };

  const renderSaveButton = () => {
    const text = initialHrefValue ? 'Update call to action' : 'Add call to action';
    const isDisabled = !(hrefValue && hrefValue.startsWith('http') && labelValue);

    return (
      <Button
        color={Button.COLORS.PRIMARY}
        raised={false}
        onClick={createFn}
        disabled={isDisabled}
      >
        {text}
      </Button>
    );
  };

  return (
    <Modal active={true} onExit={closeFn}>
      <Modal.Header
        title="Call to action"
        aside={(
          <Button
            color={Button.COLORS.SECONDARY}
            iconOnly={true}
            icon={SageTokens.ICONS.REMOVE}
            subtle={true}
            onClick={closeFn}
          >
            Close call to action block modal
          </Button>
        )}
      />
      <Modal.Body>
        <Input
          label="Button Url"
          value={hrefValue}
          onChange={({ target }) => setHrefValue(target.value)}
          type="url"
        />
        <Input
          label="Button Text"
          value={labelValue}
          onChange={({ target }) => setLabelValue(target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterAside>
          {renderDeleteButton()}
        </Modal.FooterAside>
        <Button
          color={Button.COLORS.SECONDARY}
          raised={false}
          onClick={closeFn}
        >
          Cancel
        </Button>
        {renderSaveButton()}
      </Modal.Footer>
    </Modal>
  );
};

CallToAction.propTypes = {
  closeFn: PropTypes.func.isRequired
};

export default CallToAction;
