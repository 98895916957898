import { createConstants } from 'common/lib/redux/createConstants';

export const ADD_AUDIO = createConstants('ADD_AUDIO');
export const ADD_BLOCK = 'ADD_BLOCK';
export const ADD_SECTION = 'ADD_SECTION';
export const DELETE_BLOCK = 'DELETE_BLOCK';
export const DELETE_SECTION = 'DELETE_SECTION';
export const REORDER_BLOCKS = 'REORDER_BLOCKS';
export const REORDER_SECTIONS = 'REORDER_SECTIONS';
export const UPDATE_BLOCK = 'UPDATE_BLOCK';
export const UPDATE_BLOCK_SETTINGS = 'UPDATE_BLOCK_SETTINGS';
export const UPDATE_GLOBAL_SETTINGS = 'UPDATE_GLOBAL_SETTINGS';
export const UPDATE_HAS_RECENT_MEDIAS = 'UPDATE_HAS_RECENT_MEDIAS';
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const UPDATE_SECTION_SETTINGS = 'UPDATE_SECTION_SETTINGS';
