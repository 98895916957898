import React from 'react';

import UploadControls from '../UploadControls';

const MediaUploadControls = props => (
  <UploadControls
    selectText="Select a file"
    {...props}
  />
);

export default MediaUploadControls;
