import React, { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { Button, SageClassnames } from '@kajabi/sage-react';

import { selectTheme } from '../../../../redux/theme/selectors';

import LogoutIcon from './images/logout.svg';

export default function BackLink() {
  const [showText, setShowText] = useState(false);
  const {
    attributes: { themeableType, themeable },
  } = useSelector(state => selectTheme(state), shallowEqual);
  const { backLinkInfo = {} } = useSelector(
    state => state.linkPaths,
    shallowEqual
  );
  const {
    SPACERS: { XS_LEFT },
    TYPE: { BODY_XSMALL },
  } = SageClassnames;

  const backText = useMemo(() => {
    if (themeableType === 'EmailSequenceEmail') return 'Save and Close';
    const text = themeableType === 'EmailBroadcast'
      ? themeable.title
      : backLinkInfo.text
          || pluralize(themeableType.replace(/([A-Z])/g, ' $1').trim());
    return `Back to ${text}`.replace(/.*\sBack/, 'Back');
  }, [backLinkInfo, themeable, themeableType]);

  const handleFocus = () => setShowText(true);
  const handleBlur = () => setShowText(false);

  return (
    <div className="leave-editor">
      <Button
        color={Button.COLORS.SECONDARY}
        href={backLinkInfo.url}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onMouseEnter={handleFocus}
        onMouseLeave={handleBlur}
        raised={false}
        small
      >
        <img alt="" src={LogoutIcon} style={{ transform: 'scaleX(-1)' }} />
      </Button>
      {showText && (
        <span className={[XS_LEFT, BODY_XSMALL].join(' ')}>{backText}</span>
      )}
    </div>
  );
}
