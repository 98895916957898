import React from 'react';
import ReactDOM from 'react-dom';

import { useUiContext } from 'common/components/ContentEditor/contexts';
import ToolbarOverlay from './components/ToolbarOverlay';
import ToolbarInline from './components/ToolbarInline';

const ToolbarWrapper = (props) => {
  const { isFullscreen } = useUiContext();

  if (isFullscreen) {
    return ReactDOM.createPortal(<ToolbarOverlay {...props} />, document.body);
  }

  return <ToolbarInline {...props} />;
};

export default ToolbarWrapper;
