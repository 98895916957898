import { useEffect, useState } from 'react';

import { useIframe } from '../../../../../helpers/useIframe';

export default function useSelectPreset() {
  const [selectedPreset, setSelectedPreset] = useState();
  const [formattedPreset, setFormattedPreset] = useState();
  const {
    sendAddSectionMessage,
    sendRemoveSectionMessage,
    sendToggleEdit,
  } = useIframe();

  const formatPreset = (preset) => {
    const newId = String(Date.now());
    const { blocks = [], settings = {} } = preset.overrides;
    const formattedBlocks = {};
    const blockOrder = [];
    blocks.forEach((block, index) => {
      const blockId = `${newId}_${index}`;
      blockOrder.push(blockId);
      if (block.blocks) {
        block.block_order = [];
        block.blocks.forEach((b, i) => {
          const id = `${blockId}_${i}`;
          blocks[id] = b;
          block.block_order.push(id);
        });
      }
      formattedBlocks[blockId] = { ...block };
    });
    setFormattedPreset({
      ...preset,
      blocks: formattedBlocks,
      block_order: blockOrder,
      overrides: {},
      settings: { ...preset.settings, ...settings },
      id: newId,
    });
  };

  useEffect(() => {
    if (selectedPreset) {
      sendToggleEdit(false);
      formatPreset(selectedPreset);
    }
  }, [selectedPreset, sendToggleEdit]);

  // once the formatted preset is set, this will add the section to the preview
  // without adding it to the theme store
  useEffect(() => {
    if (formattedPreset) {
      sendAddSectionMessage(formattedPreset);
    }

    // when a user selects a preset, this will remove the previously selected preset from the
    // preview (if present)
    return () => {
      if (formattedPreset) {
        sendRemoveSectionMessage(formattedPreset.id);
      }
    };
  }, [formattedPreset, sendAddSectionMessage, sendRemoveSectionMessage]);

  useEffect(() => () => {
    sendToggleEdit(true);
  }, [sendToggleEdit]);

  return [selectedPreset, formattedPreset, setSelectedPreset];
}
