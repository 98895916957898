import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import axios from 'axios';
import cancellablePromise from 'common/lib/cancellablePromise';
import moment from 'moment';

import { useInterval } from '../../../../helpers/useInterval';
import { selectFile } from '../../../../redux/file/selectors';

export const DetectedChangesModal = () => {
  const {
    present: {
      data: { id: themeId },
    },
  } = useSelector(state => state.theme);
  const { lastUpdatedAt } = useSelector(state => state.ui);
  const { id: themeFileId } = useSelector(state => selectFile(state));

  const [show, setShow] = useState(false);
  const [changesIgnored, setChangesIgnored] = useState(false);

  const hideModal = () => setShow(false);
  const reloadPage = () => window.location.reload(false);

  const handleKeepEditing = () => {
    setChangesIgnored(true);
    hideModal();
  };

  const checkLastUpdate = useCallback(() => {
    if (!show && !changesIgnored) {
      const params = { browser_time: lastUpdatedAt };
      const { promise } = cancellablePromise(
        axios.get(`/admin/themes/${themeId}/settings/last_update`, { params })
      );

      promise.then(({ data }) => {
        setShow(moment(data) > moment(lastUpdatedAt));
      });
    }
  }, [changesIgnored, show, themeId, lastUpdatedAt]);

  useInterval(checkLastUpdate, 10000);

  useEffect(() => {
    setChangesIgnored(false);
  }, [themeFileId]);

  return (
    <Modal
      backdrop="static"
      className="modal-secondary modal-dynamic in"
      onHide={hideModal}
      show={show}
    >
      <div className="modal-content">
        <i className="modal-icon" />
        <div className="modal-body">
          <h3>Changes Detected</h3>
          We detected changes made on this theme from another browser, tab, or
          computer. We recommend you <strong>reload this page</strong> to get
          the latest changes.
        </div>
        <div className="modal-footer">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="btn btn-default btn-outline btn-block-mobile"
            onClick={handleKeepEditing}
          >
            Keep Editing
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href=""
            className="btn btn-primary btn-block-mobile"
            onClick={reloadPage}
          >
            Reload Page
          </a>
        </div>
      </div>
    </Modal>
  );
};
