import ContentEditorRails from './ContentEditorRails';
import ContentEditor from './ContentEditor';
import withErrorBoundary from './containers/withErrorBoundary';

import { EXTENSION_NAMES } from './extensions';
import { VALUE_TYPES } from './constants';

const BoundedContentEditor = withErrorBoundary(ContentEditor);
const BoundedContentEditorRails = withErrorBoundary(ContentEditorRails);

export default BoundedContentEditor;
export {
  BoundedContentEditorRails as ContentEditorRails,
  EXTENSION_NAMES,
  VALUE_TYPES,
};
