import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Label } from '@kajabi/sage-react';

export default function StatusLabel({
  isActive,
  isEmailable,
  isPublished,
  themeableType,
}) {
  const iconProps = useMemo(() => {
    if (themeableType !== 'LandingPage') {
      return isActive
        ? { color: Label.COLORS.INFO, value: 'LIVE' }
        : { color: Label.COLORS.DRAFT, value: 'Hidden' };
    }
    return isPublished
      ? { color: Label.COLORS.PUBLISHED, value: 'Published' }
      : { color: Label.COLORS.DRAFT, value: 'Draft' };
  }, [isActive, isPublished, themeableType]);

  if (isEmailable || themeableType === 'MarketingEmailTemplate') {
    return null;
  }

  return <Label {...iconProps} />;
}

StatusLabel.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isEmailable: PropTypes.bool.isRequired,
  isPublished: PropTypes.bool.isRequired,
  themeableType: PropTypes.oneOf([
    'EmailBroadcast',
    'EmailSequenceEmail',
    'EmailableThemeable',
    'LandingPage',
    'MarketingEmailTemplate',
    'Product',
    'Site',
  ]).isRequired,
};
