import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { flashError, flashNotice, flashLoading, flashDismiss } from 'common/components/FlashMessages';
import { postWithToken } from '../../services';
import RecentMediaPicker from '../RecentMediaPicker';

const RecentVideosButton = ({ config, onCopySuccess }) => {
  const {
    copyMediaPath,
    videoableId,
    videoableType,
  } = config;

  const toastId = useRef(null);

  const copyMedia = (media) => {
    toastId.current = flashLoading({ text: 'Your video is being copied' });
    postWithToken(copyMediaPath, {
      video_id: media.id,
      original_video: media.attributes.original_video,
      videoable_id: videoableId,
      videoable_type: videoableType,
    }).then(({ data: { resource } }) => {
      const { id, type, links } = resource.data;
      onCopySuccess({
        data: { id, type, },
        links
      });
    }).then(() => {
      flashDismiss(toastId.current);
      flashNotice({ text: 'Video successfully copied.' });
    }).catch(() => {
      flashDismiss(toastId.current);
      flashError({ text: 'There was a problem copying your video.' });
    });
  };

  return (
    <RecentMediaPicker
      config={config}
      recentMediaPath={config.recentVideoPath}
      copyMedia={copyMedia}
      buttonText="Select a Recent File"
    />
  );
};

RecentVideosButton.defaultProps = {
  onCopySuccess: () => {}
};

RecentVideosButton.propTypes = {
  config: PropTypes.shape({}).isRequired,
  onCopySuccess: PropTypes.func
};

export default RecentVideosButton;
