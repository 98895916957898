import produce from 'immer';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import isNumber from 'lodash/isNumber';

import * as actionTypes from './actionTypes';

export const initialState = {};

const findSection = (id, newState) =>
  newState.data.attributes.settings.sections[id];

const deleteSection = ({ id, contentKey }, newState) => {
  const { settings } = newState.data.attributes;
  settings[contentKey] = settings[contentKey].filter(s => s !== id);
  delete settings.sections[id];
};

const deleteBlock = ({ id, sectionId }, newState) => {
  const section = findSection(sectionId, newState);
  section.block_order = section.block_order.filter(b => b !== id);
  delete section.blocks[id];
};

const addAudio = ({ data }, newState) => {
  newState.data.attributes.audios.data = (
    newState.data.attributes.audios.data || []
  ).concat(data);
};

const addBlock = ({ item, sectionId }, newState) => {
  const section = findSection(sectionId, newState);
  const keys = map(Object.keys(section.blocks), (key) => {
    const arr = key.split('_');
    if (arr[1]) {
      arr[1] = Number(arr[1]);
    }
    return arr;
  });
  const sortedKeys = sortBy(keys, key => (isNumber(key[1]) ? key[1] : key[0]));
  const [id, index] = sortedKeys.length ? last(sortedKeys) : [sectionId, -1];
  const newId = index
    ? `${id}_${Number(index) + 1}`
    : String(new Date().getTime());
  section.block_order = section.block_order.concat(newId);
  section.blocks[newId] = { ...item };
};

const updateRecentMedia = ({ type, value }, newState) => {
  newState.data.attributes.hasRecentMedias[type] = value;
};

const addSection = ({ contentKey, id, item }, newState) => {
  const { settings } = newState.data.attributes;
  settings.sections[id] = { ...item };
  settings[contentKey] = (settings[contentKey] || []).concat(id);
};

const reorderBlocks = ({ sectionId, ids }, newState) => {
  const section = findSection(sectionId, newState);
  section.block_order = ids;
};

const reorderSections = ({ contentKey, ids }, newState) => {
  newState.data.attributes.settings[contentKey] = ids;
};

const updateBlockSettings = ({ id, attribute, sectionId, value }, newState) => {
  const block = findSection(sectionId, newState).blocks[id];
  block.settings[attribute] = value;
};

const updateBlockValue = ({ id, attribute, sectionId, value }, newState) => {
  const block = findSection(sectionId, newState).blocks[id];
  block[attribute] = value;
};

const updateGlobalSettings = ({ attribute, value }, newState) => {
  newState.data.attributes.settings[attribute] = value;
};

const updateSectionSettings = ({ id, attribute, value }, newState) => {
  const section = findSection(id, newState);
  section.settings[attribute] = value;
};

const updateSectionValue = ({ id, attribute, value }, newState) => {
  const section = findSection(id, newState);
  section[attribute] = value;
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_AUDIO.SUCCESS:
      addAudio(action.data, state);
      return state;
    case actionTypes.ADD_BLOCK:
      addBlock(action.payload, state);
      return state;
    case actionTypes.UPDATE_HAS_RECENT_MEDIAS:
      updateRecentMedia(action.payload, state);
      return state;
    case actionTypes.ADD_SECTION:
      addSection(action.payload, state);
      return state;
    case actionTypes.DELETE_BLOCK:
      deleteBlock(action.payload, state);
      return state;
    case actionTypes.DELETE_SECTION:
      deleteSection(action.payload, state);
      return state;
    case actionTypes.REORDER_BLOCKS:
      reorderBlocks(action.payload, state);
      return state;
    case actionTypes.REORDER_SECTIONS:
      reorderSections(action.payload, state);
      return state;
    case actionTypes.UPDATE_BLOCK_SETTINGS:
      updateBlockSettings(action.payload, state);
      return state;
    case actionTypes.UPDATE_BLOCK:
      updateBlockValue(action.payload, state);
      return state;
    case actionTypes.UPDATE_GLOBAL_SETTINGS:
      updateGlobalSettings(action.payload, state);
      return state;
    case actionTypes.UPDATE_SECTION:
      updateSectionValue(action.payload, state);
      return state;
    case actionTypes.UPDATE_SECTION_SETTINGS:
      updateSectionSettings(action.payload, state);
      return state;
    default:
      return state;
  }
};

export default produce(themeReducer);
