import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import has from 'lodash/has';
import axios from 'axios';

import { ModalLoading } from '../ModalLoading';

export default function RecentMediaModal({
  onHide,
  onSubmit,
  remoteUrl,
  show,
  title,
}) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (show) {
      setLoading(true);
      axios.get(`${remoteUrl}.json`).then(({ data: { data: mediaItems } }) => {
        setItems(mediaItems);
        setLoading(false);
      });
    }
  }, [show, remoteUrl]);

  return (
    <Modal
      className="modal modal-secondary modal-dynamic modal-spinner modal-large fade in"
      onHide={onHide}
      show={show}
    >
      {loading ? (
        <ModalLoading />
      ) : (
        <>
          <div className="modal-header">
            <div className="media">
              <div className="media-body media-middle">
                <h3 className="modal-title">{title}</h3>
              </div>
              <div className="media-right media-middle">
                <button
                  as="a"
                  className={`btn pull-right btn-primary modal-button recent-btn ${
                    selectedItem ? '' : 'disabled'
                  }`}
                  disabled={!selectedItem}
                  onClick={() => onSubmit(selectedItem)}
                  type="button"
                >
                  Select
                </button>
              </div>
            </div>
          </div>
          <div className="modal-body recent-body">
            <div className="row row-inline-block">
              {items.map((item) => {
                const { id, attributes } = item;
                const { thumbnailPath, urls } = attributes;
                return (
                  <div
                    className={`col-xs-6 col-sm-3 recent-item ${
                      selectedItem === item ? 'recent-item-active' : ''
                    }`}
                    key={`recent-image-${id}`}
                    onClick={() => setSelectedItem(item)}
                    onKeyUp={() => setSelectedItem(item)}
                    role="button"
                    tabIndex={0}
                  >
                    <img
                      alt={`${id}`}
                      className="img-responsive recent-item-image recent-item-thumbnail"
                      src={urls ? urls.thumbnailUrl : thumbnailPath}
                    />
                    {has(attributes, 'videoableTitle') && (
                      <>
                        {attributes.videoableTitle ? (
                          <p className="recent-item-title">
                            {attributes.videoableTitle}
                            <em className="recent-item-context">
                              ({attributes.videoableType})
                            </em>
                          </p>
                        ) : (
                          <p className="recent-item-title">Orphaned Video</p>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}

RecentMediaModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  remoteUrl: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
