import { Extension } from '@tiptap/core';
import { clamp } from 'lodash';
/**
 *
 * We disable no-extraneous-dependencies in this special case because
 * 'prosemirror-state' is the internal engine of the Tiptap editor.
 * We need access to the internals for the functionality of this custom plugin.
 * This package will always be available and we prefer to not manually
 * version this internal dep.
 *
 */// eslint-disable-next-line import/no-extraneous-dependencies
import { TextSelection, AllSelection } from 'prosemirror-state';

/**
 *
 * Many thanks to this discussion for serving as the boilerplate to this plugin:
 * https://github.com/ueberdosis/tiptap/issues/1036
 *
 */

const SETTINGS = {
  min: 0,
  max: 210,
  more: 30,
  less: -30
};

const isNodeType = (node, name) => (node.type.name === name);
const isListNode = node => isNodeType(node, 'bulletList') || isNodeType(node, 'orderedList');
const isTextNode = node => isNodeType(node, 'paragraph') || isNodeType(node, 'heading');

const setNodeIndentMarkup = (tr, pos, delta) => {
  if (!tr.doc) return tr;

  const node = tr.doc.nodeAt(pos);
  if (!node) return tr;

  const indent = clamp(
    (node.attrs.indent || 0) + delta,
    SETTINGS.min,
    SETTINGS.max,
  );

  if (indent === node.attrs.indent) return tr;

  const nodeAttrs = {
    ...node.attrs,
    indent,
  };

  return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks);
};

const updateIndentLevel = (tr, delta) => {
  const { doc, selection } = tr;

  if (!doc || !selection) return tr;

  if (!(selection instanceof TextSelection || selection instanceof AllSelection)) {
    return tr;
  }

  const { from, to } = selection;

  doc.nodesBetween(from, to, (node, pos) => {
    if (isTextNode(node)) {
      tr = setNodeIndentMarkup(tr, pos, delta);
      return false;
    }
    if (isListNode(node)) return false;
    return true;
  });

  return tr;
};

export default Extension.create({
  name: 'indent',

  addOptions() {
    return {
      types: ['paragraph', 'heading'],
      indentLevels: [0, 30, 60, 90, 120, 150, 180, 210],
      defaultIndentLevel: 0,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          indent: {
            default: this.options.defaultIndentLevel,
            renderHTML: attributes => (
              (attributes.indent === this.options.defaultIndentLevel)
                ? {}
                : { style: `margin-left: ${attributes.indent}px !important;` }
            ),
            parseHTML: element => (
              parseInt(element.style.marginLeft, 10) || this.options.defaultIndentLevel
            ),
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      indent: () => ({ tr, state, dispatch }) => {
        const { selection } = state;
        tr = tr.setSelection(selection);
        tr = updateIndentLevel(tr, SETTINGS.more);

        if (tr.docChanged) {
          if (dispatch) dispatch(tr);
          return true;
        }

        return false;
      },
      outdent: () => ({ tr, state, dispatch }) => {
        const { selection } = state;
        tr = tr.setSelection(selection);
        tr = updateIndentLevel(tr, SETTINGS.less);

        if (tr.docChanged) {
          if (dispatch) dispatch(tr);
          return true;
        }

        return false;
      },
    };
  },
  addKeyboardShortcuts() {
    return {
      Tab: () => (
        !this.editor.isActive('listItem') && this.editor.commands.indent()
      ),
      'Shift-Tab': () => (
        !this.editor.isActive('listItem') && this.editor.commands.outdent()
      ),
    };
  },
  onUpdate() {
    if (this.editor.isActive('listItem')) {
      const node = this.editor.state.selection.$head.node();
      if (node.attrs.indent) {
        this.editor.commands.updateAttributes(node.type.name, { indent: 0 });
      }
    }
  },
});
